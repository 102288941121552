import { useMutation, useQuery } from "@apollo/client";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import { UPDATE_PRODUCT_PRINT_ORDERS } from "../mutations";
import { PRODUCT_PRINT_ORDER, PRODUCT_PRINT_ORDERS } from "../queries";
import { useEffect, useState } from "react";

const EditProductPrintOrdersComponent: any = Loadable({
  loader: () => import("../../components/ProductPrintOrders/EditProductPrintOrders"),
  loading: DataLoadSpinner,
});

type Props = {
  id: string,
  setPopup: any
};

const EditProductPrintOrders = (props: Props) => {
  const { id, setPopup } = props;

  const [updateProductPrintOrders, { loading }] = useMutation(UPDATE_PRODUCT_PRINT_ORDERS, {
    refetchQueries: [
      {
        query: PRODUCT_PRINT_ORDERS,
      },
    ],
  });

  // PRODUCT_PRINT_ORDER
  const { data: productPrintOrderData, loading: isLoading } = useQuery(PRODUCT_PRINT_ORDER, {
    variables: {
      id: id
    }
  })

  const [productPrintOrdersInitialValues, setProductPrintOrdersInitialValues] = useState({
    name: productPrintOrderData?.productPrintOrder?.name ?? ""
  })

  useEffect(() => {
    setProductPrintOrdersInitialValues({
      name: productPrintOrderData?.productPrintOrder?.name
    })
  }, [productPrintOrderData, id])


  const handleEditProductPrintOrders = async (values: any, props: any) => {
    const response = await updateProductPrintOrders({
      variables: {
        name: values.name,
        productOrder: "5",
        attributeId: productPrintOrderData?.productPrintOrder?.pk
      },
    });
    if (response.data?.updateProductPrintOrder?.success) {
      props.resetForm();
      setPopup(false);
      toast.success("ProductPrintOrder Updated Successfully");
    } else {
      let errors = backendErrorDisplay(response.data?.updateProductPrintOrder?.errors);
      props.setErrors(errors);
    }
  };

  return (
    <>
      {!isLoading && <EditProductPrintOrdersComponent
        handleEditProductPrintOrders={handleEditProductPrintOrders}
        loading={loading}
        productPrintOrdersInitialValues={productPrintOrdersInitialValues}
      />}
    </>
  );
};

export default EditProductPrintOrders;
