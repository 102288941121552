import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import React from "react";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import { searchItemsVar } from "../../components/localstate/Cache";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../customhooks/usePaginationHook";
import { DELETE_TAX } from "../mutations";
import { QUERY_TAX } from "../queries";
import TableLoader from "../../components/reusable/Loader/TableLoader";

const TaxListComponent: any = Loadable({
  loader: () => import("../../components/Tax/TaxList"),
  loading: () => <TableLoader />,
});

const Tax: React.FC<any> = () => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [deleteTax] = useMutation(DELETE_TAX, {
    refetchQueries: [
      {
        query: QUERY_TAX,
        variables: {
          first: pageSize,
          offset: offset,
          title_Icontains: searchItems?.searchInput,
        },
      },
    ],
  });

  const { data: taxData, loading: taxLoading } = useQuery(QUERY_TAX, {
    variables: {
      first: pageSize,
      offset: offset,
      title_Icontains: searchItems?.searchInput,
    },
  });

  const handleDeleteTax = async (id: string) => {
    const response = await deleteTax({
      variables: {
        objectId: id,
      },
    });
    if (response.data?.deleteTax?.success) {
      toast.success(response.data?.deleteTax?.message);
    } else {
      toast.error(response.data?.deleteTax?.errors?.nonFieldErrors[0]?.message);
    }
  };

  return (
    <>
      <TaxListComponent
        data={taxData?.taxes}
        taxLoading={taxLoading}
        handleDeleteTax={handleDeleteTax}
        setPageSize={setPageSize}
        pageSize={pageSize}
        offset={offset}
        setOffset={setOffset}
      />
    </>
  );
};

export default Tax;
