import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loadable from "react-loadable";

import { useNavigate } from "react-router-dom";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import { PRODUCT_PRINT_ORDERS, QUERY_TAX, QUERY_UNIT } from "../queries";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import { POS_SCREEN_CREATE_PRODUCT } from "./mutation";
import {
  POS_SCREEN_PRINTER_LIST,
  POS_SCREEN_PRODUCT,
  POS_SCREEN_PRODUCT_CATEGORY,
  PRODUCT_LIST,
} from "./queries";
import Spinner from "../../components/reusable/Spinner";
import usePaginationState from "../../customhooks/usePaginationHook";
import { searchItemsVar } from "../../components/localstate/Cache";

const AddProductComponent: any = Loadable({
  loader: () => import("../../components/Product/AddProduct"),
  loading: DataLoadSpinner,
});

type Props = {};

const AddProduct = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [categoryDropdown, setCategoryDropdown] = useState([]);
  const [taxTypeDropdown, setTaxTypeDropdown] = useState<any>([]);
  const [printOrderTypeDropdown, setPrintOrderTypeDropdown] = useState([]);
  const [modifierCategoryDropdown, setModifierCategoryDropdown] = useState([]);
  const [printerListsDropdown, setPrinterListsDropdown] = useState([]);
  const navigate = useNavigate();
  //Product Categories
  const {
    data: categoryData,
    loading: catLoading,
    client,
  } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      first: 100,
      level: 0,
      status: "visible",
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    categoryData?.categories?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      })
    );
    setCategoryDropdown(dropdownFormat);
  }, [categoryData]);

  //ModifierCategory
  const { data: modifierCategoryData } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      first: 100,
      level: 0,
      status: "modifier",
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    modifierCategoryData?.categories?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      })
    );
    setModifierCategoryDropdown(dropdownFormat);
  }, [modifierCategoryData]);
  //Printer Lists
  const { data: printerListsData } = useQuery(POS_SCREEN_PRINTER_LIST, {
    variables: {
      first: 10,
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    printerListsData?.printers?.edges.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.department,
      })
    );
    setPrinterListsDropdown(dropdownFormat);
  }, [printerListsData]);
  //Unit Type
  const { data: unitData, loading: unitLoading } = useQuery(QUERY_UNIT, {
    variables: {
      first: 100,
    },
  });
  const [unitDropdown, setUnitDropdown] = useState([
    {
      id: unitData?.units[0]?.node.pk,
      title: unitData?.units[0]?.node.title,
    },
  ]);

  useEffect(() => {
    const dropdownFormat: any = [];
    unitData?.units?.edges.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.title,
      })
    );
    setUnitDropdown(dropdownFormat);
  }, [unitData]);
  //Tax List
  const { data: taxData, loading: taxLoading } = useQuery(QUERY_TAX, {
    variables: {
      first: 100,
      offset: 0,
      title_Icontains: "",
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    if (taxData) {
      taxData?.taxes?.edges?.map((elem: any) =>
        dropdownFormat.push({
          id: elem?.node?.pk,
          title: elem?.node?.title,
          rate: elem?.node?.rate,
          isIncluded: elem?.node?.isIncluded,
        })
      );
      dropdownFormat.push({
        id: 0,
        title: "GST Free",
        rate: 0,
        isIncluded: true,
      });
    }
    setTaxTypeDropdown(dropdownFormat);
  }, [taxData]);

  const [productCreate, { loading: loadingCreateProduct }] = useMutation(
    POS_SCREEN_CREATE_PRODUCT,
    {
      refetchQueries: [
        {
          query: PRODUCT_LIST,
          variables: {
            first: pageSize,
            offset: offset,
            isModifier: false,
            compound_Isnull: true,
            isOpenProduct: false,
            search: searchItems?.searchInput ?? "",
            categoryList: [],
          },
        },
      ],
    }
  );

  // Productprinterlist
  const { data: productPrintOrderData } = useQuery(PRODUCT_PRINT_ORDERS);

  useEffect(() => {
    const dropdownFormat: any = [];
    productPrintOrderData?.productPrintOrders?.edges.map(
      (item: any, index: number) =>
        dropdownFormat.push({
          title: item?.node?.name,
          id: item?.node?.pk,
        })
    );
    setPrintOrderTypeDropdown(dropdownFormat);
  }, [productPrintOrderData]);

  const handleCreateProduct = async (values: any, props: any) => {
    const response = await productCreate({
      variables: {
        category: values?.category,
        title: values?.title,
        unit: values?.unit,
        isActive: values?.isActive,
        isScalable: values?.isScalable,
        tax: values?.taxType ?? taxTypeDropdown[0]?.id,
        isModifier: false,
        isWeightable: values?.isWeightable,
        printOrder: values?.printOrder,
        isModifierCategoryGrid: values?.isModifierCategoryGrid,
        isOpenPriceProduct: values?.isOpenPriceProduct,
        printers: values?.printer,
        modifierCategories: values?.modifierCategory,
        image: values?.image,
        sellingPrice: values?.sellingPrice,
        costPrice: values?.costPrice,
        barcodeContent: values?.barcodeContent,
        plu: values?.plu,
        shortDesc: values?.description,
        displayInWebsite: values?.displayInWebsite,
      },
    });
    if (response?.data?.createProduct?.success) {
      toast.success("Product Created successfully");
      client.resetStore();
      navigate(`/product`);
    } else {
      const errors = backendErrorDisplay(response?.data?.createProduct?.errors);
      props.setErrors(errors);
    }
  };
  return (
    <>
      {taxLoading && unitLoading ? (
        <Spinner />
      ) : (
        <AddProductComponent
          categoryDropdown={categoryDropdown}
          unitData={unitDropdown ?? []}
          handleCreateProduct={handleCreateProduct}
          taxTypeDropdown={taxTypeDropdown ?? []}
          printOrderTypeDropdown={printOrderTypeDropdown}
          loadingCreateProduct={loadingCreateProduct}
          modifierCategoryDropdown={modifierCategoryDropdown ?? []}
          printerListsDropdown={printerListsDropdown}
        />
      )}
    </>
  );
};

export default AddProduct;
