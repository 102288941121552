import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { EDIT_POS_DISCOUNT } from "../../mutations";
import { GET_POS_DISCOUNT, QUERY_POS_DISCOUNT } from "../../queries";

const EditPOSDiscountComponent: any = Loadable({
  loader: () =>
    import("../../../components/Discount/POSDiscount/EditPOSDiscount"),
  loading: DataLoadSpinner,
});

type Props = {};

const EditPOSDiscount = (props: Props) => {
  const navigate = useNavigate();
  const params = useParams();

  const { pageSize, offset } = usePaginationState();

  const [
    selectedPosDiscountInitailValues,
    setSelectedPosDiscountInitailValues,
  ] = useState<any>();

  const { data: selectedPosDiscount, loading: discoungLoading } = useQuery(
    GET_POS_DISCOUNT,
    {
      variables: {
        id: params.id,
      },
    }
  );
  console.log(selectedPosDiscount, "selectedPosDiscount");
  useEffect(() => {
    setSelectedPosDiscountInitailValues(null);
    if (selectedPosDiscount) {
      const newInitialValues = {
        title: selectedPosDiscount?.discount?.title,
        rateAmount: selectedPosDiscount?.discount?.rateAmount,
        discountType: selectedPosDiscount?.discount?.discountType.toLowerCase(),
      };
      setSelectedPosDiscountInitailValues(newInitialValues);
    }
  }, [selectedPosDiscount, params.id]);

  const [editPosDiscount, { loading: editPosDiscountLoading }] = useMutation(
    EDIT_POS_DISCOUNT,
    {
      refetchQueries: [
        {
          query: QUERY_POS_DISCOUNT,
          variables: {
            first: pageSize,
            offset: offset,
          },
        },
      ],
    }
  );

  const handleEditPOSDiscount = async (values: any, props: any) => {
    const response = await editPosDiscount({
      variables: {
        id: selectedPosDiscount?.discount?.pk,
        title: values.title,
        rateAmount: values.rateAmount,
        discountType: values.discountType,
      },
    });
    if (response.data?.updatePosDiscount?.success) {
      props.resetForm();
      navigate("/pos-discount");
      toast.success("Edited POS Discount");
    } else {
      let errors = backendErrorDisplay(
        response.data?.updatePosDiscount?.errors
      );
      props.setErrors(errors);
    }
  };
  return (
    <div>
      <EditPOSDiscountComponent
        handleEditPOSDiscount={handleEditPOSDiscount}
        selectedPosDiscountInitailValues={selectedPosDiscountInitailValues}
        editPosDiscountLoading={editPosDiscountLoading}
        discoungLoading={discoungLoading}
      />
    </div>
  );
};

export default EditPOSDiscount;
