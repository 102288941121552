import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type Props = {};

const CardLoader = (props: Props) => {
  const {} = props;

  return (
    <>
      <div className="w-full flex justify-between mt-10 gap-4">
        <div className="w-full ">
          <Skeleton height={120} />
        </div>
        <div className="w-full ">
          <Skeleton height={120} />
        </div>
        <div className="w-full ">
          <Skeleton height={120} />
        </div>
        <div className="w-full ">
          <Skeleton height={120} />
        </div>
      </div>
    </>
  );
};

export default CardLoader;
