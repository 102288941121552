import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import Loadable from "react-loadable";
import { useMutation } from '@apollo/client';
import { CREATE_COLLECTION } from '../mutation';
import { QUERY_COLLECTIONS } from '../queries';
import { toast } from 'react-toastify';
import { backendErrorDisplay } from '../../../utils/backendErrorDisplay';
import { useNavigate } from 'react-router';

const CreateCollectionComponent: any = Loadable({
  loader: () => import("../../../components/Product/Collection/CreateCollection"),
  loading: DataLoadSpinner,
})

type Props = {}

const CreateCollection = (props: Props) => {

  const navigate = useNavigate()
  const [createCollection, { loading: createCollectionLoading }] = useMutation(CREATE_COLLECTION, {
    refetchQueries: [{ query: QUERY_COLLECTIONS }],
    awaitRefetchQueries: true
  })

  const handleCreateCollection = async (values: any, props: any) => {
    const response = await createCollection({
      variables: {
        name: values.name,
        isVisible: "true"
      }
    })
    if (response?.data?.createCollection?.success) {
      toast.success("Created Collection Successfully")
      navigate("/product/collection")
    } else {
      let errors = backendErrorDisplay(response.data?.createCollection?.errors)
      props.setErrors(errors)
    }
  }
  return (
    <>
      <CreateCollectionComponent
        handleCreateCollection={handleCreateCollection}
        createCollectionLoading={createCollectionLoading}
      />
    </>
  )
}

export default CreateCollection