import { useMutation, useReactiveVar } from '@apollo/client';
import Loadable from "react-loadable";
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import { backendErrorDisplay } from '../../utils/backendErrorDisplay';
import { CREATE_PRINTER } from '../mutations';
import { QUERY_PRINTER } from '../queries';
import { searchItemsVar } from '../../components/localstate/Cache';
import usePaginationState from '../../customhooks/usePaginationHook';
import { useEffect, useState } from 'react';
import isElectron from '../../helpers/checkElectron';

const AddPrinterComponent: any = Loadable({
  loader: () => import('../../components/Printer/AddPrinter'),
  loading: DataLoadSpinner,
});

type Props = {}

const AddPrinter = (props: Props) => {
  const navigate = useNavigate()

  const { pageSize, offset } = usePaginationState()
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [printerLists, setPrinterLists] = useState<any>("[]")
  if (isElectron()) {
    const { ipcRenderer } = window.require("electron");
    ipcRenderer.send("systemPrinters");
    ipcRenderer.on("systemPrinters", (event: any, arg: any) => {
      const printer: any = JSON.stringify(arg)
      setPrinterLists(printer)
    });
  }

  const [printerDropdown, setPrinterDropdown] = useState([])
  useEffect(() => {
    const printerData = JSON.parse(printerLists || "[]")
    let dropdownFormat: any = []
    printerData?.map((item: any) => (
      dropdownFormat.push({
        id: item?.name,
        title: item?.name
      })
    ))
    setPrinterDropdown(dropdownFormat)
  }, [printerLists])

  const [createPrinter, { loading: createPrinterLoading }] = useMutation(CREATE_PRINTER, {
    refetchQueries: [{
      query: QUERY_PRINTER, variables: {
        first: pageSize,
        offset: offset,
        name_Icontains: searchItems?.searchInput
      }
    }]
  })

  const handleAddPrinter = async (values: any, props: any) => {
    const response = await createPrinter({
      variables: {
        name: values.name,
        department: values.department,
        type: values.type,
        location: values.location
      }
    })
    if (response.data?.createPrinter?.success) {
      props.resetForm()
      navigate('/printer')
      toast.success("Added Printer")
    } else {
      let errors = backendErrorDisplay(response.data?.createPrinter?.errors)
      props.setErrors(errors)
    }
  }
  return (
    <>
      <AddPrinterComponent
        handleAddPrinter={handleAddPrinter}
        createPrinterLoading={createPrinterLoading}
        printerDropdown={printerDropdown}
      />
    </>
  )
}

export default AddPrinter