import { gql } from "@apollo/client";

export const UPDATE_KITCHEN_ORDER_ITEMS_BUMPED_STATUS = gql`
mutation($orderItem:String!,$isBumped:Boolean!){
  updateKitchenOrderItemsBumpedStatus(input:{
    orderItem:$orderItem,
    isBumped:$isBumped
  }){
    errors
    success
  }
}

`;
export const UPDATE_KITCHEN_ORDER_BUMPED_STATUS = gql`
mutation($orderId:String!,$isBumped:Boolean!){
  updateKitchenOrderBumpedStatus(input:{
    orderId: $orderId,
    isBumped: $isBumped
  }){
    success
    errors
    order{
      pk
      isBumped
    }
  }
}
`;