import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { UPDATE_TRANSACTION_ACCOUNTS } from "../../mutations";
import { GET_TRANSACTION_ACCOUNT } from "../../queries";

const EditAccountComponent: any = Loadable({
  loader: () =>
    import("../../../components/Account/ManageAccounts/EditAccount"),
  loading: DataLoadSpinner,
});

type Props = {};

const EditAccount = (props: Props) => {
  const navigate = useNavigate();
  const params = useParams();

  const [
    selectedTransactionAccountInitailValues,
    setSelectedTransactionAccountInitailValues,
  ] = useState<any>();

  const {
    data: selectedTransactionAccount,
    loading: selectedTransactionLoading,
  } = useQuery(GET_TRANSACTION_ACCOUNT, {
    variables: {
      id: params.id,
    },
  });

  const [editAccount, { loading: editAccountLoading }] = useMutation(
    UPDATE_TRANSACTION_ACCOUNTS,
    {
      refetchQueries: [
        {
          query: GET_TRANSACTION_ACCOUNT,
          variables: {
            id: params.id,
          },
        },
      ],
    }
  );

  const handleEditAccount = async (values: any, props: any) => {
    const response = await editAccount({
      variables: {
        id: selectedTransactionAccount?.transactionAccount?.pk,
        name: values.name,
        transactionCost: values.transactionCost,
      },
    });

    if (response.data?.updateTransactionAccount?.success) {
      // props.resetForm();
      navigate("/account");
      toast.success("Edited Transaction Account");
    } else {
      let errors = backendErrorDisplay(
        response.data?.updateTransactionAccount?.errors
      );
      props.setErrors(errors);
    }
  };

  useEffect(() => {
    if (selectedTransactionAccount?.transactionAccount) {
      const newInitialValues = {
        name: selectedTransactionAccount?.transactionAccount?.name,
        description:
          selectedTransactionAccount?.transactionAccount?.description,
        transactionCost:
          selectedTransactionAccount?.transactionAccount?.transactionCost,
      };
      setSelectedTransactionAccountInitailValues(newInitialValues);
    }
  }, [selectedTransactionAccount, params.id]);

  return (
    <div>
      <EditAccountComponent
        handleEditAccount={handleEditAccount}
        selectedTransactionAccountInitailValues={
          selectedTransactionAccountInitailValues
        }
        editAccountLoading={editAccountLoading}
        selectedTransactionLoading={selectedTransactionLoading}
      />
    </div>
  );
};

export default EditAccount;
