import React from "react";
import Loadable from "react-loadable";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { POS_SINGLE_KITCHEN_ORDER_LIST } from "../queries";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";

type Props = {};

const HoldOrderDetailsComponent: any = Loadable({
  loader: () => import("../../../components/Orders/HoldCart/HoldOrderDetails"),
  loading: () => DataLoadSpinner,
});

const HoldOrderDetails = (props: Props) => {
  const { id } = useParams();
  const { data, loading } = useQuery(POS_SINGLE_KITCHEN_ORDER_LIST, {
    variables: {
      id: id,
    },
  });
  return (
    <>
      <HoldOrderDetailsComponent holdCartItemData={data?.kitchenOrder} loading={loading} />
    </>
  );
};

export default HoldOrderDetails;
