import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { GET_PRINTER, QUERY_PRINTER } from "../queries";
import { EDIT_PRINTER } from "../mutations";
import { searchItemsVar } from "../../components/localstate/Cache";
import usePaginationState from "../../customhooks/usePaginationHook";
import isElectron from "../../helpers/checkElectron";

const EditPrinterComponent: any = Loadable({
  loader: () => import("../../components/Printer/EditPrinter"),
  loading: DataLoadSpinner,
});

type Props = {};

const EditPrinter = (props: Props) => {
  const { pageSize, offset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const navigate = useNavigate();
  const params = useParams();
  const [selectedPrinterInitialData, setSelectedPrinterInitialData] =
    useState<any>();

  const { data: selectedPrinter, loading: printerLoading } = useQuery(
    GET_PRINTER,
    {
      variables: {
        id: params.id,
      },
    }
  );
  const [printerLists, setPrinterLists] = useState<any>("[]");
  if (isElectron()) {
    const { ipcRenderer } = window.require("electron");
    ipcRenderer.send("systemPrinters");
    ipcRenderer.on("systemPrinters", (event: any, arg: any) => {
      const printer: any = JSON.stringify(arg);
      setPrinterLists(printer);
    });
  }

  const [printerDropdown, setPrinterDropdown] = useState([]);
  useEffect(() => {
    const printerData = JSON.parse(printerLists || "[]");
    let dropdownFormat: any = [];
    printerData?.map((item: any) =>
      dropdownFormat.push({
        id: item?.name,
        title: item?.name,
      })
    );
    setPrinterDropdown(dropdownFormat);
  }, [printerLists]);

  useEffect(() => {
    setSelectedPrinterInitialData(null);
    if (selectedPrinter) {
      const newInitialValues = {
        name: selectedPrinter?.printer?.name,
        department: selectedPrinter?.printer?.department,
        type: selectedPrinter?.printer?.type.toLowerCase(),
        location: selectedPrinter?.printer?.location
          ? selectedPrinter?.printer?.location
          : "",
      };
      setSelectedPrinterInitialData(newInitialValues);
    }
  }, [selectedPrinter, params.id]);

  const [editPrinter, { loading: editPrinterLoading }] = useMutation(
    EDIT_PRINTER,
    {
      refetchQueries: [
        {
          query: QUERY_PRINTER,
          variables: {
            first: pageSize,
            offset: offset,
            name_Icontains: searchItems?.searchInput,
          },
        },
      ],
    }
  );

  const handleEditPrinter = async (values: any, props: any) => {
    const response = await editPrinter({
      variables: {
        id: selectedPrinter?.printer?.pk,
        name: values.name,
        department: values.department,
        type: values.type,
        location: values.location,
      },
    });
    if (response.data?.updatePrinter?.success) {
      props.resetForm();
      navigate("/printer");
      toast.success("Edited Printer");
    } else {
      let errors = backendErrorDisplay(response.data?.updatePrinter?.errors);
      props.setErrors(errors);
    }
  };

  return (
    <>
      <EditPrinterComponent
        handleEditPrinter={handleEditPrinter}
        selectedPrinterInitialData={selectedPrinterInitialData}
        editPrinterLoading={editPrinterLoading}
        printerDropdown={printerDropdown}
        printerLoading={printerLoading}
      />
    </>
  );
};

export default EditPrinter;
