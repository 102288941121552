import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import React from "react";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import { searchItemsVar } from "../../../components/localstate/Cache";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { PRODUCT_LIST } from "../queries";
import { POS_SCREEN_DELETE_PRODUCT } from "../mutation";
import TableLoader from "../../../components/reusable/Loader/TableLoader";

const ModifierListComponent: any = Loadable({
  loader: () => import("../../../components/Product/Modifier/ModifierList"),
  loading: () => <TableLoader showTotal={true} />,
});

const Modifier: React.FC<any> = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const { data: modifiers, loading: modifiersLoading } = useQuery(
    PRODUCT_LIST,
    {
      variables: {
        first: pageSize,
        offset: offset,
        isModifier: true,
        search: searchItems?.searchInput ?? "",
      },
      fetchPolicy: "network-only",
    }
  );

  const [deleteModifier] = useMutation(POS_SCREEN_DELETE_PRODUCT, {
    refetchQueries: [
      {
        query: PRODUCT_LIST,
        variables: {
          first: pageSize,
          offset: offset,
          isModifier: true,
          search: searchItems?.searchInput ?? "",
        },
      },
    ],
    awaitRefetchQueries: true,
  });
  const handleDeleteModifier = async (id: any) => {
    const response = await deleteModifier({
      variables: {
        objectId: id,
      },
    });
    if (response?.data?.deleteProduct?.success) {
      toast.success("Modifier Deleted successfully");
    } else {
      const errors = backendErrorDisplay(response?.data?.deleteProduct?.errors);
      toast.error(errors);
    }
  };

  return (
    <>
      <ModifierListComponent
        data={modifiers?.products ?? []}
        handleDeleteModifier={handleDeleteModifier}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setOffset={setOffset}
        offset={offset}
        modifiersLoading={modifiersLoading}
      />
    </>
  );
};

export default Modifier;
