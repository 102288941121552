import React, { useEffect, useState } from "react";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMutation, useQuery } from "@apollo/client";
import { PRODUCT_PRINT_ORDERS } from "../queries";
import PrimaryButton from "../../components/reusable/Buttons/PrimaryButton";
import {
  DELETE_PRODUCT_PRINT_ORDERS,
  REORDER_PRODUCT_PRINT_ORDERS,
} from "../mutations";
import EditProductPrintOrders from "./EditProductPrintOrders";
import Modal from "../../components/reusable/Modal";
import AddProductPrintOrders from "./AddProductPrintOrders";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";
import { toast } from "react-toastify";

function SortableItem({
  item,
  handleDeleteProductPrintOrder,
  setPopup,
  setId,
}) {
  const { id, name, pk } = item;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: "100%",
    height: 40,
    display: "flex",
    alignItems: "center",
    paddingLeft: 5,
    border: "1px solid gray",
    borderRadius: 5,
    marginBottom: 12,
    // userSelect: "none",
    cursor: "grab",
    boxSizing: "border-box",
    justifyContent: "space-between",
  };
  const itemActionsStyle = {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    gap: 10,
    alignItems: "center",
  };

  return (
    <div style={itemActionsStyle}>
      <li
        ref={setNodeRef}
        style={itemStyle}
        {...attributes}
        {...listeners}
        draggable
      >
        {name}
      </li>
      <span style={itemActionsStyle}>
        <img
          src={deleteIcon}
          className={" cursor-pointer "}
          onClick={() => handleDeleteProductPrintOrder(id)}
        />
        <img
          src={editIcon}
          className={" cursor-pointer"}
          onClick={() => {
            setId(pk);
            setPopup(true);
          }}
        />
      </span>
    </div>
  );
}

export default function ProductPrintOrders() {
  const { data } = useQuery(PRODUCT_PRINT_ORDERS, {
    fetchPolicy: "network-only",
  });

  const [items, setItems] = useState([]);

  useEffect(() => {
    const dropdownFormat = [];
    data?.productPrintOrders?.edges.map((item, index) =>
      dropdownFormat.push({
        id: item?.node?.pk,
        name: item?.node?.name,
        index: index,
        pk: item?.node?.id,
      })
    );
    setItems(dropdownFormat);
  }, [data]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newItemsArray = arrayMove(items, oldIndex, newIndex);
        return newItemsArray.map((item, index) => ({
          ...item,
          index: index,
        }));
      });
    }
  };

  const [deleteProductPrintOrder] = useMutation(DELETE_PRODUCT_PRINT_ORDERS, {
    refetchQueries: [
      {
        query: PRODUCT_PRINT_ORDERS,
      },
    ],
  });

  const handleDeleteProductPrintOrder = async (id) => {
    const response = await deleteProductPrintOrder({
      variables: {
        objectId: id,
      },
    });
    if (response?.data?.deleteProductPrintOrder?.success) {
      toast.success(response?.data?.deleteProductPrintOrder?.message);
    } else {
      toast.error(
        response?.data?.deleteProductPrintOrder?.errors?.nonFieldErrors[0]
          ?.message
      );
    }
  };
  const [popup, setPopup] = useState(false);
  const [id, setId] = useState("");

  const [addPopup, setAddPopup] = useState(false);

  const [reOrderProductPrintOrders] = useMutation(REORDER_PRODUCT_PRINT_ORDERS);

  const handleSave = async () => {
    const newItems = items.map((item) => {
      return {
        id: item.id,
        name: item.name,
        index: item.index,
      };
    });

    const response = await reOrderProductPrintOrders({
      variables: {
        productPrintOrderCategory: newItems,
      },
    });
    if (response.data?.reOrderProductPrintOrder?.success) {
      toast.success("ProductPrintOrder Reordered Successfully");
    } else {
      let errors = backendErrorDisplay(
        response.data?.reOrderProductPrintOrder?.errors
      );
      props.setErrors(errors);
    }
  };

  return (
    <div>
      <div className="flex justify-end my-4 gap-4">
        <PrimaryButton onClick={() => handleSave()} text={"Save"} />
        <PrimaryButton onClick={() => setAddPopup(true)} text={"Add New"} />
      </div>

      <div className="bg-white p-4">
        <div className="mb-5 border-b border-gray-500 pb-2">
          Product Print Order
        </div>
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext items={items}>
            {items?.map((item) => (
              <SortableItem
                key={item.id}
                item={item}
                handleDeleteProductPrintOrder={handleDeleteProductPrintOrder}
                setPopup={setPopup}
                setId={setId}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>

      <Modal title="Edit Product Print Orders" open={popup} setOpen={setPopup}>
        <EditProductPrintOrders id={id} setPopup={setPopup} />
      </Modal>

      <Modal
        title="Add Product Print Orders"
        open={addPopup}
        setOpen={setAddPopup}
      >
        <AddProductPrintOrders setAddPopup={setAddPopup} />
      </Modal>
    </div>
  );
}
