import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import AddSubCategoryComponent from "../../../components/Category/SubCategory/AddSubCategory";
import { ADD_CATEGORY } from "../../mutations";
import { GET_CATEGORY } from "../../queries";

type Props = {};

const AddSubCategory = (props: Props) => {
  const params = useParams();
  let navigate = useNavigate();

  const [addSubCategory, { loading: addSubCategoryLoading }] = useMutation(ADD_CATEGORY, {
    refetchQueries: [
      {
        query: GET_CATEGORY,
        variables: {
          id: params.id,
        },
      },
    ],
  });

  const handleAddSubCategory = async (values: any, props: any) => {
    const response = await addSubCategory({
      variables: {
        name: values.name,
        status: values.status,
        parent: params?.pk,
      },
    });
    if (response?.data?.createCategory?.success) {
      navigate(`/category/edit/${params.id}`);
      props.resetForm();
      toast.success("Added Sub Category");
    } else {
      toast.error("failed");
    }
  };
  return (
    <>
      <AddSubCategoryComponent handleAddSubCategory={handleAddSubCategory} addCategoryLoading={addSubCategoryLoading} />
    </>
  );
};

export default AddSubCategory;
