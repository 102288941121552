import { gql } from "@apollo/client"

export const LIST_UNITS = gql`
query units($first:Int,$after:String,$before:String,$offset:Int,$title_Icontains:String){
  units(first:$first,after: $after, before: $before,offset:$offset,title_Icontains:$title_Icontains){
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  edges{
    node{
    	id
      pk
      title
    	shortCode
 		}
	}
 }
}
`
export const CREATE_UNIT = gql`
mutation($title:String!, $shortCode:String){
    createMeasurementUnit(input:{title:$title,shortCode:$shortCode }){
    success
      errors
      measurementUnit{
        id
    title
      shortCode
      pk
        
    
    }
      clientMutationId
    
    
    }
    
    }`
export const DELETE_UNIT = gql`
    mutation($objectId:String!,$clintMutational:String){
      deleteMeasurementUnit(input:{objectId:$objectId,clientMutationId:$clintMutational}){
      
      success
        errors
        message
        clientMutationId
      
      
      }
      
      }

`
export const GET_UNIT = gql`
query unit($id:ID!){
  unit(id:$id){
    id
    title
        shortCode
        pk
        
    
    
  }
}
`
export const UPDATE_UNIT = gql`
mutation($unitId:String!,$title:String,$shortCode:String){

  updateMeasurementUnit(input:{unitId:$unitId,title:$title,shortCode:$shortCode}){
    unit{
    title
      id
      pk
}
    success
    errors
  
  }
  
   }
 
 
 
 `

