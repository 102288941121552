import { useMutation } from "@apollo/client";
import Loadable from "react-loadable";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationHook from "../../customhooks/usePaginationHook";
// import { nextPage, previousPage } from '../../utils/pagination';
import { ADD_CATEGORY } from "../mutations";
import { QUERY_CATEGORY } from "../queries";

const AddCategoryComponent: any = Loadable({
  loader: () => import("../../components/Category/AddCategory"),
  loading: DataLoadSpinner,
});

type Props = {};

const AddCategory = (props: Props) => {
  let navigate = useNavigate();
  const { offset, pageSize } = usePaginationHook();

  const [addCategory, { loading: addCategoryLoading }] = useMutation(ADD_CATEGORY, {
    refetchQueries: [
      {
        query: QUERY_CATEGORY,
        variables: {
          first: pageSize,
          level: 0,
          offset: offset,
          name_Icontains:""
        },
      },
    ],
  });

  const handleAddCategory = async (values: any, props: any) => {
    console.log(values)
    const response = await addCategory({
      variables: {
        name: values.name,
        image: values.image,
        status: values.status ? "modifier" : "visible",
      },
    });
    if (response?.data?.createCategory?.success) {
      navigate("/category");
      props.resetForm();
      toast.success("Added Category Successfully");
    } else {
      toast.error("failed");
    }
  };
  return (
    <>
      <AddCategoryComponent handleAddCategory={handleAddCategory} addCategoryLoading={addCategoryLoading} />
    </>
  );
};

export default AddCategory;
