import { cache, cartItemsVar, customerCache, CustomerItemsVar, kitchenOrderItemId, kitchenOrdreCache, modifiedKitchenOrderCache, modifiedKitchenOrderItemVar, noteCache, noteVar, tableCache, tableItemsVar, totalCalculationCache, totalCalculationVar } from "../components/localstate/Cache";
import { GET_CART_ITEMS, GET_CUSTOMER_ITEMS, GET_KITCHEN_CARD_ID, GET_MODIFIED_KITCHEN_CARD_ID, GET_NOTE_ITEMS, GET_TABLE_ITEMS, GET_TOTAL_CALCULATION } from "../components/localstate/Cart";

const usePosScreenHandleClear = () => {
  const handleClearPosScreen = () => {
    customerCache.writeQuery({
      query: GET_CUSTOMER_ITEMS,
      data: {
        customerItems: CustomerItemsVar({}),
      },
    });
    tableCache.writeQuery({
      query: GET_TABLE_ITEMS,
      data: {
        tableItems: tableItemsVar({}),
      },
    });
    kitchenOrdreCache.writeQuery({
      query: GET_KITCHEN_CARD_ID,
      data: {
        kitchenOrderItemId: kitchenOrderItemId({}),
      },
    });
    cache.writeQuery({
      query: GET_CART_ITEMS,
      data: {
        cartItems: cartItemsVar([]),
      },
    });
    totalCalculationCache.writeQuery({
      query: GET_TOTAL_CALCULATION,
      data: {
        totalCalculationItems: totalCalculationVar({}),
      },
    });
    modifiedKitchenOrderCache.writeQuery({
      query: GET_MODIFIED_KITCHEN_CARD_ID,
      data: {
        modifiedKitchenOrderId:
          modifiedKitchenOrderItemVar({}),
      },
    });
    noteCache.writeQuery({
      query: GET_NOTE_ITEMS,
      data: {
        noteItems: noteVar(""),
      },
    });
  }
  return { handleClearPosScreen }
}



export default usePosScreenHandleClear