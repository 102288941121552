import { useMutation, useReactiveVar } from "@apollo/client";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { CREATE_CUSTOMER, CUSTOMER_LIST } from "./grapql";
import { useNavigate } from "react-router-dom";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import { searchItemsVar } from "../../../components/localstate/Cache";

const AddCustomerComponent: any = Loadable({
  loader: () => import("../../../components/Account/Customer/CustomerAddForm"),
  loading: DataLoadSpinner,
});

const AddCustomer = () => {
  const navigate = useNavigate();

  const { offset, pageSize } = usePaginationHook();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [createCustomer, { loading: createCustomerLoading }] = useMutation(CREATE_CUSTOMER, {
    refetchQueries: [
      {
        query: CUSTOMER_LIST,
        variables: {
          first: pageSize,
          offset: offset,
          search: searchItems?.searchInput,
        },
      },
    ],
  });

  const handleCreateCustomer = async (values: any, props: any) => {
    const response = await createCustomer({
      variables: {
        fullName: values.fullName,
        email: values.email ?? null,
        mobileNumber: values.mobileNumber,
        fullAddress: values.fullAddress,
        trackingNumber: values?.trackingNumber
      },
    });
    if (response?.data?.createCustomer?.success) {

      toast.success(response?.data?.createCustomer?.message ?? "Cutomer added sucessfully");
      props.resetForm();
      navigate("/customer");
    } else {
      let errors = backendErrorDisplay(response?.data?.createCustomer?.errors);
      props.setErrors(errors);
    }
  };

  return (
    <div>
      <AddCustomerComponent
        handleCreateCustomer={handleCreateCustomer}
        createCustomerLoading={createCustomerLoading}
      />
    </div>
  );
};

export default AddCustomer;
