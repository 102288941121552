import Loadable from "react-loadable";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";

import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { QUERY_TAX } from "../queries";

import { useNavigate, useParams } from "react-router";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import { POS_SCREEN_COMPOUND_PRODUCT, POS_SCREEN_SINGLE_PRODUCT } from "../Product/queries";
import { UPDATE_PROMOTION } from "../mutations";

const EditPromotionComponent: any = Loadable({
  loader: () =>
    import("../../components/Promotion/EditPromotion"),
  loading: DataLoadSpinner,
});

type Props = {};

const EditPromotion = (props: Props) => {
  let params: any = useParams();

  let navigate = useNavigate();
  const [selectedProductItems, setSelectedProductItems] = useState<any>([])
  const [taxTypeDropdown, setTaxTypeDropdown] = useState<any>([])


  const { data: singleProductData } = useQuery(POS_SCREEN_SINGLE_PRODUCT, {
    variables: {
      id: params?.id,
    },
  });

  useEffect(() => {
    let selectedProductItem: any = []
    singleProductData?.product?.compound?.map((item: any) => (
      selectedProductItem.push({
        id: item?.id, pk: item?.pk, name: item?.title, price: item?.finalPrice
      })
    ));
    setSelectedProductItems(selectedProductItem)
  }, [singleProductData]);
  //Tax List
  const { data: taxData } = useQuery(QUERY_TAX, {
    variables: {
      first: 100,
      offset: 0,
      title_Icontains: "",
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    if (taxData) {
      taxData?.taxes?.edges?.map((elem: any) =>
        dropdownFormat.push({
          id: elem?.node?.pk,
          title: elem?.node?.title,
          rate: elem?.node?.rate,
          isIncluded: elem?.node?.isIncluded,
        }),
      );
      dropdownFormat.push({
        id: 0,
        title: "GST Free",
        rate: 0,
        isIncluded: true,
      });
    }
    setTaxTypeDropdown(dropdownFormat);
  }, [taxData]);

  const [editPromotion, { loading: editPromotionLoading }] = useMutation(UPDATE_PROMOTION, {
    refetchQueries: [
      {
        query: POS_SCREEN_COMPOUND_PRODUCT,
        variables: {
          first: 10,
          offset: 0,
          search: "",
          compound_Isnull: true
        },
      },
    ],
  });

  const handleEditPromotion = async (values: any, props: any) => {
    let filteredPeople: any;
    if (selectedProductItems?.length > 0) {
      filteredPeople = selectedProductItems?.map((item: any) => (
        item.pk
      ))
      console.log((filteredPeople).toString(), "filteredPeople")
      const response = await editPromotion({
        variables: {
          id: singleProductData?.product?.pk,
          title: values?.title,
          sellingPrice: values?.sellingPrice,
          compound: (filteredPeople).toString(),
          tax: values?.taxType.length ? values?.taxType : null,
        }
      })
      if (response.data?.updateProduct?.success) {
        navigate('/promotions')
        toast.success("Updated  Successfully")
      } else {
        let errors = backendErrorDisplay(response.data?.createUpdatePromotion?.errors)
        props.setErrors(errors)
      }

    } else {
      toast.info("Select Products")
    }
  }

  return (
    <>
      {singleProductData?.product ?
        <EditPromotionComponent
          handleEdit={handleEditPromotion}
          loading={editPromotionLoading}
          selectedProductItems={selectedProductItems}
          setSelectedProductItems={setSelectedProductItems}
          taxTypeDropdown={taxTypeDropdown}
          singleProductData={singleProductData}
        />
        : null}
    </>
  );
};

export default EditPromotion;
