import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import Loadable from "react-loadable";

const EditCollectionComponent: any = Loadable({
  loader: () => import("../../../components/Product/Collection/EditCollection"),
  loading: DataLoadSpinner,
})

type Props = {}

const EditCollection = (props: Props) => {
  return (
    <>
      <EditCollectionComponent />
    </>
  )
}

export default EditCollection