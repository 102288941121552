import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/reusable/Buttons/PrimaryButton";
import TabPanel from "../../components/reusable/Forms/TabPanel/TabPanel";

import BarcodeComponent from "./BarcodeComponent";
import {
  BARCODE_SETTING,
  DOWNLOAD_BARCODE,
  UPDATAE_CREATE_BARCODE_CONTENT,
} from "./graphql";
import { Form, Formik } from "formik";
import { useParams } from "react-router";
import BarCodePrintPreviewUI from "./BarCodePrintPreviewUI";
import isElectron from "../../helpers/checkElectron";
import useHandleElectronPrintReceipt from "../../customhooks/useHandleElectronPrintReceipt";

const BarcodeSetting = () => {
  const params = useParams();
  const componentRef = useRef<any>(null);

  const { data } = useQuery(BARCODE_SETTING);

  let [initialState, setInitialState] = useState<any>({
    unit: "mm",
    sheetWidth: 150,
    sheetHeight: 300,
    verticalSpace: 0,
    horizontalSpace: 0,
    sheetMarginTop: 0,
    sheetMarginRight: 0,
    sheetMarginBottom: 0,
    sheetMarginLeft: 0,
    labelPaddingTop: 0,
    labelPaddingRight: 0,
    labelPaddingBottom: 0,
    labelPaddingLeft: 1,
    barcodeImageWidth: 0.4,
    barcodeImageHeight: 10,
    numberOfLabel: 1,
    headerContent: "",
    footerContent: "",
  });

  const unitTypeList = [
    {
      id: "mm",
      name: "Millimeter",
    },
    {
      id: "in",
      name: "Inch",
    },
  ];
  const previewRef: any = useRef();
  //Update BarCode
  const [saveBarCode] = useMutation(UPDATAE_CREATE_BARCODE_CONTENT);
  const onBarCodeSave = async (values: any) => {
    values["htmlContent"] = previewRef?.current?.outerHTML;
    const response = await saveBarCode({
      variables: {
        content: JSON.stringify(values),
      },
    });
    if (response.data?.createUpdateBarcodeContent?.success) {
      toast.success("Barcode Updated successfully");
    } else {
      toast.error("Failed..");
    }
  };

  useEffect(() => {
    if (data) {
      const barcodeSettings = JSON.parse(data?.barcodeSetting?.content || "{}");
      barcodeSettings["id"] = true;
      setInitialState(barcodeSettings);
    }
  }, [data]);

  //Print BarCode

  const [downloadProduct, { data: downloadBarCodeContent }] =
    useMutation(DOWNLOAD_BARCODE);
  const handlePrintBarcode = async () => {
    await downloadProduct({
      variables: {
        productId: params?.pk,
      },
    });
  };

  const { handleElectronPrintReceipt } = useHandleElectronPrintReceipt();

  useEffect(() => {
    if (downloadBarCodeContent) {

      isElectron() && handleElectronPrintReceipt(`<code128 data="{APQR123X1122331807110011223344" />`, true);
    }
  }, [downloadBarCodeContent]); 

  return (
    <>
      <div>
        <TabPanel
          tabTitle={["General Section"]}
          tabPanels={[
            <div className="gap-x-2">
              <div className="p-2 border bg-white">
                {initialState?.id && (
                  <Formik
                    initialValues={initialState}
                    // validationSchema={}
                    onSubmit={onBarCodeSave}
                  >
                    {({ touched, values, setFieldValue, errors }) => {
                      return (
                        <Form>
                          <div className="grid grid-cols-2 gap-2">
                            <div>
                              <h3 className="text-xl text-center font-medium pb-2 ">
                                Bar Code Composer
                              </h3>
                              <div className=" py-2 p-1 h-14 border grid grid-cols-6 gap-x-2 items-center">
                                <span className="text-[12px] col-span-2 row-start-auto ">
                                  Unit:
                                </span>
                                <select
                                  className="pl-1 border border-black rounded bg-slate-100 h-full col-span-2 row-start-auto"
                                  name={"unit"}
                                  value={values["unit"]}
                                  onChange={(e) => {
                                    setFieldValue("unit", e.target.value);
                                  }}
                                >
                                  {unitTypeList?.map(
                                    (item: any, index: number) => (
                                      <React.Fragment key={index}>
                                        <option value={item?.id}>
                                          {item?.name}
                                        </option>
                                      </React.Fragment>
                                    ),
                                  )}
                                </select>
                              </div>
                              <div className="py-2 p-1 h-14 border grid grid-cols-6 gap-x-2 items-center">
                                <span className="text-[12px] col-span-2 row-start-auto ">
                                  Sheet(Width x Height):
                                </span>
                                <input
                                  id="foo"
                                  type="number"
                                  step="any"
                                  name={"sheetWidth"}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "sheetWidth",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full"
                                  placeholder="width"
                                  min={0}
                                  value={values["sheetWidth"]}
                                />
                                <input
                                  type="number"
                                  step="any"
                                  name={"sheetHeight"}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "sheetHeight",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full"
                                  placeholder="height"
                                  min={0}
                                  value={values["sheetHeight"]}
                                />
                              </div>
                              <div className="py-2 p-1 h-14 border grid grid-cols-6 gap-x-2 items-center">
                                <span className="text-[12px] col-span-2 row-start-auto ">
                                  Space(verticalSpace x horizontal):
                                </span>
                                <input
                                  type="number"
                                  step="any"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "verticalSpace",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full"
                                  min={0}
                                  name={"verticalSpace"}
                                  value={values["verticalSpace"]}
                                />
                                <input
                                  type="number"
                                  step="any"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "horizontalSpace",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full"
                                  min={0}
                                  name={"horizontalSpace"}
                                  value={values["horizontalSpace"]}
                                />
                              </div>
                              <div className=" py-2 p-1 h-14 border grid grid-cols-6 gap-x-2 items-center">
                                <span className="text-[12px] col-span-2 row-start-auto ">
                                  Sheet Margin(top x right x bottom x left):
                                </span>
                                <input
                                  type="number"
                                  step="any"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "sheetMarginTop",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full text-sm text-center"
                                  placeholder="top"
                                  min={0}
                                  name={"sheetMarginTop"}
                                  value={values["sheetMarginTop"]}
                                />
                                <input
                                  type="number"
                                  step="any"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "sheetMarginRight",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full text-sm text-center"
                                  placeholder="right"
                                  min={0}
                                  name={"sheetMarginRight"}
                                  value={values["sheetMarginRight"]}
                                />
                                <input
                                  type="number"
                                  step="any"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "sheetMarginBottom",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full text-sm text-center"
                                  placeholder="bottom"
                                  min={0}
                                  name={"sheetMarginBottom"}
                                  value={values["sheetMarginBottom"]}
                                />
                                <input
                                  type="number"
                                  step="any"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "sheetMarginLeft",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full text-sm text-center"
                                  placeholder="left"
                                  min={0}
                                  name={"sheetMarginLeft"}
                                  value={values["sheetMarginLeft"]}
                                />
                              </div>
                              <div className=" py-2 p-1 h-14 border grid grid-cols-6 gap-x-2 items-center">
                                <span className="text-[12px] col-span-2 row-start-auto ">
                                  Label Padding:
                                </span>
                                <input
                                  type="number"
                                  step="any"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "labelPaddingTop",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full text-sm text-center"
                                  placeholder="top"
                                  min={0}
                                  name={"labelPaddingTop"}
                                  value={values["labelPaddingTop"]}
                                />
                                <input
                                  type="number"
                                  step="any"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "labelPaddingRight",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full text-sm text-center"
                                  placeholder="right"
                                  min={0}
                                  name={"labelPaddingRight"}
                                  value={values["labelPaddingRight"]}
                                />
                                <input
                                  type="number"
                                  step="any"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "labelPaddingBottom",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full text-sm text-center"
                                  placeholder="bottom"
                                  min={0}
                                  name={"labelPaddingBottom"}
                                  value={values["labelPaddingBottom"]}
                                />
                                <input
                                  type="number"
                                  step="any"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "labelPaddingLeft",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full text-sm text-center"
                                  placeholder="left"
                                  min={0}
                                  name={"labelPaddingLeft"}
                                  value={values["labelPaddingLeft"]}
                                />
                              </div>
                              <div className=" py-2 p-1 h-14 border grid grid-cols-6 gap-x-2 items-center">
                                <span className="text-[12px] col-span-2 row-start-auto ">
                                  Barcode Image(w x h):
                                </span>
                                <input
                                  type="number"
                                  step="any"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "barcodeImageWidth",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full text-sm text-center"
                                  min={0}
                                  name={"barcodeImageWidth"}
                                  value={values["barcodeImageWidth"]}
                                />
                                <input
                                  type="number"
                                  step="any"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "barcodeImageHeight",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full text-sm text-center"
                                  min={0}
                                  name={"barcodeImageHeight"}
                                  value={values["barcodeImageHeight"]}
                                />
                              </div>
                              <div className=" py-2 p-1 h-14 border grid grid-cols-6 gap-x-2 items-center">
                                <span className="text-[12px] col-span-2 row-start-auto ">
                                  Number Of Label:
                                </span>
                                <input
                                  name={"numberOfLabel"}
                                  type="number"
                                  step="any"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "numberOfLabel",
                                      parseFloat(e.target.value),
                                    );
                                  }}
                                  className="pl-1 border border-black rounded bg-slate-100 h-full text-sm text-center"
                                  min={0}
                                  value={values["numberOfLabel"]}
                                />
                              </div>
                              <div>
                                <div className=" py-2 p-1 h-28 border grid grid-cols-1 gap-x-2 items-center">
                                  <span className="text-[12px] col-span-2 row-start-auto ">
                                    Barcode Header:
                                  </span>
                                  <textarea
                                    id="headerContent"
                                    name={"headerContent"}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "headerContent",
                                        e.target.value,
                                      );
                                    }}
                                    className="pl-1 border border-black rounded bg-slate-100 h-full"
                                    placeholder="width"
                                    value={values["headerContent"]}
                                  />
                                </div>
                                <br />
                                <div className=" py-2 p-1 h-28 border grid grid-cols-1 gap-x-2 items-center">
                                  <span className="text-[12px] col-span-2 row-start-auto ">
                                    Barcode Footer:
                                  </span>
                                  <textarea
                                    id="footerContent"
                                    name={"footerContent"}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "footerContent",
                                        e.target.value,
                                      );
                                    }}
                                    className="pl-1 border border-black rounded bg-slate-100 h-full"
                                    placeholder="width"
                                    value={values["footerContent"]}
                                  />
                                </div>
                              </div>
                              <div className="w-fit mx-auto mt-1">
                                <PrimaryButton
                                  type={"submit"}
                                  text={"Save"}
                                  buttonSize={"small"}
                                />

                                <>
                                  <PrimaryButton
                                    type={"button"}
                                    text={"Print"}
                                    buttonSize={"small"}
                                    onClick={() => handlePrintBarcode()}
                                  />

                                  <div style={{ display: "none" }}>
                                    <BarCodePrintPreviewUI
                                      ref={componentRef}
                                      barcodeHtmlString={
                                        downloadBarCodeContent?.downloadBarcode
                                          ?.barcodeHtmlString
                                      }
                                    />
                                  </div>
                                </>
                              </div>
                              ,
                            </div>
                            <div
                              className={
                                "w-full h-[660px] rounded bg-white overflow-auto p-0 mt-0"
                              }
                            >
                              <h3 className="text-xl text-center font-medium pb-2 ">
                                PREVIEW SECTION
                              </h3>
                              <div
                                ref={previewRef}
                                className={
                                  "bg-yellow-200 flex flex-wrap content-start"
                                }
                                style={{
                                  width: `${values?.sheetWidth}${values?.unit}`,
                                  height: `${values?.sheetHeight}${values?.unit}`,
                                  paddingTop: `${values?.sheetMarginTop}${values?.unit}`,
                                  paddingRight: `${values?.sheetMarginRight}${values?.unit}`,
                                  paddingBottom: `${values?.sheetMarginBottom}${values?.unit}`,
                                  paddingLeft: `${values?.sheetMarginLeft}${values?.unit}`,
                                  rowGap: `${values?.verticalSpace}${values?.unit}`,
                                  columnGap: `${values?.horizontalSpace}${values?.unit}`,
                                }}
                              >
                                {values?.numberOfLabel > 0 &&
                                  [...Array(values?.numberOfLabel)].map(
                                    (elementInArray, index) => (
                                      <BarcodeComponent
                                        barcodeObject={values}
                                        selectValue={values?.unit}
                                        value={params?.barcodecontent ?? ""}
                                      />
                                    ),
                                  )}
                              </div>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </div>
            </div>,
          ]}
        />
      </div>
    </>
  );
};

export default BarcodeSetting;
