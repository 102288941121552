import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router";
import Loadable from "react-loadable";

import { POS_SINGLE_KITCHEN_ORDER_LIST } from "../queries";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";

const KitchenOrderDetailListComponent: any = Loadable({
  loader: () =>
    import("../../../components/Orders/KitchenOrdersList/KitchenOrderDetail"),
  loading: DataLoadSpinner,
});

const KitchenOrderDetail: React.FC<any> = (props) => {
  const params = useParams();
  const { data } = useQuery(POS_SINGLE_KITCHEN_ORDER_LIST, {
    variables: {
      id: params?.id,
    },
  });



  return <KitchenOrderDetailListComponent data={data?.kitchenOrder} />;
};

export default KitchenOrderDetail;
