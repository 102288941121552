import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { toast } from 'react-toastify';
import DataLoadSpinner from '../../components/reusable/Spinner/DataLoadSpinner';
import { NO_SALES_REPORT, QUERY_USERS } from '../queries';

const NoSaleComponent: any = Loadable({
  loader: () => import('../../components/Report/NoSale'),
  loading: DataLoadSpinner,
});

type Props = {}

const NoSale = (props: Props) => {

  const [usersDropDown, setusersDropDown] = useState<any>([]);

  const [reportOpenState, setReportOpenState] = useState<boolean>(false)
  const [reportData, setReportData] = useState<any>()


  // USERS DROPDOWN LIST
  const { data: userData } = useQuery(QUERY_USERS)
  useEffect(() => {
    const dropdownFormat: any = [];
    userData?.users?.edges?.map((elem: any) => (
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.username,
      })
    ));
    setusersDropDown(dropdownFormat);
  }, [userData]);


  // DETAIL SALES REPORT QUERY
  const [generateNoSalesReport, { loading: noSalesReportLoading }] = useLazyQuery(NO_SALES_REPORT)

  const handleGenerateNoSalesReport = async (values: any) => {
    const response = await generateNoSalesReport({
      variables: {
        user: values.user,
        fromDateTime: values.fromDate,
        toDateTime: values.toDate,
        terminal:[],
        item:[]
      }
    })
    if (response?.data?.noSaleReport?.createdOn) {
      setReportData(response?.data?.noSaleReport)
      setReportOpenState(true)
    } else {
      toast.error("Report data available")
    }
  }

  const handleReset = (setFieldValue: any) => {
    setFieldValue('user', [])
    setFieldValue('fromDate', "")
    setFieldValue('toDate', "")
  }

  return (
    <NoSaleComponent
      usersDropDown={usersDropDown}
      noSalesReportLoading={noSalesReportLoading}
      handleGenerateNoSalesReport={handleGenerateNoSalesReport}
      reportOpenState={reportOpenState}
      setReportOpenState={setReportOpenState}
      reportData={reportData}
      handleReset={handleReset}
    />
  )
}

export default NoSale