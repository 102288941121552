import { useState } from "react";
import apolloClient from "../apolloClient";
import apolloClientLocal from "../apolloClinetLocal";
import classNames from "../utils/classNames";
import Refresh from "../assets/toolbars/refresh.svg";


const RefreshButton = () => {
  const [active, setActive] = useState<boolean>(false);

  const handleRefresh = async () => {
    setActive(true);
    const { ipcRenderer } = window.require("electron");
    ipcRenderer.send("getSetupType");
    ipcRenderer.on("getSetupType", async (event: any, arg: any) => {
      if (arg?.setupType === "localServer") {
        await apolloClientLocal.refetchQueries({
          include: "active",
        });
        setActive(false);
      } else {
        await apolloClient.refetchQueries({
          include: "active",
        });
        setActive(false);
      }
    });
  }

  return (
    <button onClick={handleRefresh} className={
      classNames(
        active ? ' animate-spin' : "",
        "cursor-pointer"
      )}>
      <img className="w-7 h-7 m-1 p-1 hover:bg-indigo-800" src={Refresh} alt="" />
    </button>
  )
}

export default RefreshButton