//@ts-nocheck
import { useQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import { QUERY_UNIT, QUERY_TAX, PRODUCT_PRINT_ORDERS } from "../queries";
import {
  POS_SCREEN_SINGLE_PRODUCT,
  POS_SCREEN_PRODUCT_CATEGORY,
  POS_SCREEN_PRINTER_LIST,
} from "./queries";
import AccessControl from "../../helpers/accessControl";
import UserPermissionRequired from "../Error/UserPermisssionRequired";
import Spinner from "../../components/reusable/Spinner";
import { POS_SCREEN_UPDATE_PRODUCT } from "./mutation";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";

const EditProductComponent: any = Loadable({
  loader: () => import("../../components/Product/EditProduct"),
  loading: DataLoadSpinner,
});
type Props = {};

const EditProduct = (props: Props) => {
  let params: any = useParams();
  const navigate = useNavigate();

  const [unitDropdown, setUnitDropdown] = useState([]);
  const [printerListsDropdown, setPrinterListsDropdown] = useState([]);
  const [categoryDropdown, setCategoryDropdown] = useState([]);
  const [modifierCategoryDropdown, setModifierCategoryDropdown] = useState([]);
  const [taxTypeDropdown, setTaxTypeDropdown] = useState([]);
  const [printOrderTypeDropdown, setPrintOrderTypeDropdown] = useState([]);

  //Single Product
  const { data: singleProductData, loading: singleProductLoading } = useQuery(
    POS_SCREEN_SINGLE_PRODUCT,
    {
      variables: {
        id: params?.id,
      },
      fetchPolicy: "network-only",
    }
  );
  //Product Categories
  const { data: categoryData } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      first: 100,
      level: 0,
      status: "visible",
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const dropdownFormat: any = [];
    categoryData?.categories?.edges?.map((elem: any) => {
      return dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      });
    });
    setCategoryDropdown(dropdownFormat);
  }, [categoryData]);

  const { data: modifierCategoryData } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      first: 100,
      level: 0,
      status: "modifier",
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    modifierCategoryData?.categories?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      })
    );
    setModifierCategoryDropdown(dropdownFormat);
  }, [modifierCategoryData]);

  //Unit Type
  const { data: unitData, loading: unitDataLoading } = useQuery(QUERY_UNIT, {
    variables: {
      first: 100,
    },
  });

  useEffect(() => {
    const dropdownFormat: any = [];
    unitData?.units?.edges.map((elem: any) => {
      return dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.title,
      });
    });
    setUnitDropdown(dropdownFormat);
  }, [unitData]);

  //Product Types

  //Tax List
  const { data: taxData, loading: taxDataLoading } = useQuery(QUERY_TAX, {
    variables: {
      first: 100,
      offset: 0,
      title_Icontains: "",
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    if (taxData) {
      taxData?.taxes?.edges?.map((elem: any) =>
        dropdownFormat.push({
          id: elem?.node?.pk,
          title: elem?.node?.title,
          rate: elem?.node?.rate,
          isIncluded: elem?.node?.isIncluded,
        })
      );
      dropdownFormat.push({
        id: 0,
        title: "GST Free",
        rate: 0,
        isIncluded: true,
      });
    }
    setTaxTypeDropdown(dropdownFormat);
  }, [taxData]);

  //Printer Lists
  const { data: printerListsData } = useQuery(POS_SCREEN_PRINTER_LIST, {
    variables: {
      first: 10,
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    printerListsData?.printers?.edges.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.department,
      })
    );
    setPrinterListsDropdown(dropdownFormat);
  }, [printerListsData]);

  // Productprinterlist
  const { data: productPrintOrderData } = useQuery(PRODUCT_PRINT_ORDERS);

  useEffect(() => {
    const dropdownFormat: any = [];
    productPrintOrderData?.productPrintOrders?.edges.map(
      (item: any, index: number) =>
        dropdownFormat.push({
          title: item?.node?.name,
          id: item?.node?.pk,
        })
    );
    setPrintOrderTypeDropdown(dropdownFormat);
  }, [productPrintOrderData]);

  const [updateProduct, { loading: productUpdateLoading }] = useMutation(
    POS_SCREEN_UPDATE_PRODUCT
  );
  const handleUpdateProduct = async (values: any, props: any) => {
    const response = await updateProduct({
      variables: {
        id: values?.id,
        category: values?.category,
        title: values?.title,
        unit: values?.unit,
        isActive: values?.isActive,
        tax: values?.taxType,
        isModifier: false,
        isWeightable: values?.isWeightable,
        isScalable: values?.isScalable,
        printOrder: values?.printOrder,
        isModifierCategoryGrid: values?.isModifierCategoryGrid,
        isOpenPriceProduct: values?.isOpenPriceProduct,
        printers: values?.printer,
        modifierCategories: values?.modifierCategory,
        image: typeof values?.image === "string" ? null : values?.image,
        sellingPrice: values?.sellingPrice,
        costPrice: values?.costPrice,
        barcodeContent: values?.barcodeContent,
        plu: values?.plu,
        shortDesc: values?.shortDesc,
        displayInWebsite: values?.displayInWebsite,
      },
    });

    if (response?.data?.updateProduct?.success) {
      toast.success("Product Updated successfully");
      navigate(`/product`);
    } else {
      const errors = backendErrorDisplay(response?.data?.updateProduct?.errors);
      props.setErrors(errors);
    }
  };

  return (
    <AccessControl
      allowedPermissions={["can_edit_product"]}
      renderNoAccess={<UserPermissionRequired />}
      children={
        <>
          {singleProductLoading ? (
            <DataLoadSpinner />
          ) : (
            <EditProductComponent
              categoryDropdown={categoryDropdown}
              unitData={unitDropdown}
              taxTypeDropdown={taxTypeDropdown}
              printOrderTypeDropdown={printOrderTypeDropdown}
              modifierCategoryDropdown={modifierCategoryDropdown}
              printerListsDropdown={printerListsDropdown}
              singleProductData={singleProductData?.product}
              handleUpdateProduct={handleUpdateProduct}
              productUpdateLoading={productUpdateLoading}
            />
          )}
        </>
      }
    />
  );
};

export default EditProduct;
