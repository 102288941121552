import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import Loadable from "react-loadable";
import usePaginationState from "../../customhooks/usePaginationHook";
import { useQuery } from "@apollo/client";
import { TABLEWISE_REPORT } from "../queries";
import { useState } from "react";
import moment from "moment";
import AccessControl from "../../helpers/accessControl";
import UserPermissionRequired from "../Error/UserPermisssionRequired";
import TableLoader from "../../components/reusable/Loader/TableLoader";

const ReportComponent: any = Loadable({
  loader: () => import("../../components/Reports/TableWiseReportList"),
  loading: () => <TableLoader showLeftBar={false} />,
});
type Props = {};

const TableWiseReport = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();

  const [startDate, setStartDate] = useState(
    moment(new Date()).startOf("day").format("yyyy-MM-DDTHH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).endOf("day").format("yyyy-MM-DDTHH:mm:ss")
  );

  const { data, loading } = useQuery(TABLEWISE_REPORT, {
    variables: {
      fromDateTime: startDate,
      toDateTime: endDate,
      terminal: [],
    },
  });

  return (
    <AccessControl
      allowedPermissions={["can_view_report"]}
      renderNoAccess={<UserPermissionRequired />}
      children={
        <ReportComponent
          data={data?.detailTableWiseReport ?? []}
          loading={loading}
          pageSize={pageSize}
          setPageSize={setPageSize}
          offset={offset}
          setOffset={setOffset}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      }
    />
  );
};

export default TableWiseReport;
