import { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import userIcon from "../assets/user.svg";
import { removeLocalKey } from "../helpers/sessionKey";

import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  DesktopComputerIcon,
  MenuAlt2Icon,
  XIcon,
} from "@heroicons/react/outline";

import classNames from "../utils/classNames";
import { useQuery } from "@apollo/client";

import { QUERY_SETTINGS } from "../pages/Settings/graphql";
import SideBar from "../components/Sidebar";
import isElectron from "../helpers/checkElectron";
import CustomHeaderLayout from "./CustomHeaderLayout";
import GET_RECEIPT from "../pages/ReceiptSetting/queries";
import useWebSocketHook from "../customhooks/useWebSocketHook";
import { toast } from "react-toastify";
import qrCodeNetWorkPrinterLayout from "../printLayouts/QRCodeOrder/QRCodeNetWorkPrinterLayout";
import websiteOrderNetworkPrinter from "../printLayouts/WebsiterOrder/WebsiteOrderNetworkPrinter";
import useHandleElectronPrintReceipt from "../customhooks/useHandleElectronPrintReceipt";
import QRCodeOrderProductWisePrinterLayout from "../printLayouts/QRCodeOrder/QRCodeOrderProductWisePrinterLayout";

const DashboardLayout = () => {
  const { qrCodeOrderMessageData, websiteOrderMessageData } =
    useWebSocketHook();

  //Receipt
  const { data: receiptData } = useQuery(GET_RECEIPT);
  //Websocket
  //For Toast Message
  useEffect(() => {
    if (qrCodeOrderMessageData?.data) {
      toast.success(qrCodeOrderMessageData?.data?.message);
    }
  }, [qrCodeOrderMessageData]);

  useEffect(() => {
    if (websiteOrderMessageData?.data) {
      toast.success(websiteOrderMessageData?.data?.message);
    }
  }, [websiteOrderMessageData]);

  const { handleElectronPrintReceipt } = useHandleElectronPrintReceipt();

  useEffect(() => {
    if (websiteOrderMessageData?.data?.print_status && isElectron()) {
      handleElectronPrintReceipt(
        websiteOrderNetworkPrinter(websiteOrderMessageData, receiptData)
      );
    } else {
      console.log("Not Impemented in Electron");
    }
  }, [websiteOrderMessageData?.data?.print_status]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  //Basic Configuration
  const { data: basicSetting } = useQuery(QUERY_SETTINGS);

  const handleLogout = () => {
    removeLocalKey("token");
  };

  return (
    <>
      <CustomHeaderLayout layoutType={"dashboard"} />

      <div
        className={`${
          isElectron() ? "h-[89vh]" : "h-screen"
        } flex overflow-hidden bg-gray-100`}
      >
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 flex z-40 md:hidden"
            open={sidebarOpen}
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-indigo-700">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src={basicSetting?.basicWebsiteConfigurations?.logo}
                    alt="Arpan Gold Logo"
                  />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto no-scrollbar">
                  <SideBar visibleModulesData={[]} />
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden bg-primary md:flex md:flex-shrink-0">
          <div className="flex flex-col w-64">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto no-scrollbar">
              <div className="flex justify-center flex-shrink-0 px-4">
                <img
                  className="w-fit h-[4rem]"
                  src={
                    basicSetting?.basicWebsiteConfigurations?.logo ??
                    "/logo192.svg"
                  }
                  alt="main_logo"
                />
              </div>
              <div className="mt-5 flex-1 flex flex-col">
                <SideBar visibleModulesData={[]} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-0 flex-1 overflow-hidden ">
          <div className="relative z-10 flex-shrink-0 flex h-14 bg-primary shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            <div className="flex-1 px-4 flex justify-end">
              <div className="ml-4 flex items-center md:ml-6">
                {/* <button
                      className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Kitchen Display</span>
                      <DesktopComputerIcon onClick={() => navigate("/kitchenDisplay")} className="h-6 w-6"
                        aria-hidden="true" />
                    </button> */}

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="max-w-xs  flex items-center text-sm rounded-full  focus:ring-indigo-500">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={userIcon}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <Menu.Item>
                            <a
                              href={"/"}
                              className={classNames(
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Back to Dashboard
                            </a>
                          </Menu.Item>

                          {/* <Menu.Item>
                                <Link
                                  to={"/profile"}
                                  className={classNames(
                                    "block px-4 py-2 text-sm text-gray-700",
                                  )}
                                >
                                  Sync
                                </Link>
                              </Menu.Item> */}
                          {/* @ts-ignore */}

                          <Menu.Item onClick={handleLogout}>
                            <a
                              href={"/auth-login"}
                              className={classNames(
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              <>Logout</>
                            </a>
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>
          {/* <BreadCrumb pages={getPagesLink()}/> */}
          <main className="flex-1 relative overflow-y-auto focus:outline-none">
            <div className="pt-6 ">
              <div className=" mx-auto px-4 sm:px-6 md:px-8">
                <Outlet />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
