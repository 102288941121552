import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import { searchItemsVar } from "../../components/localstate/Cache";
import usePaginationState from "../../customhooks/usePaginationHook";
import { DELETE_TERMINAL } from "../mutations";
import { QUERY_TERMINAL } from "../queries";
import TableLoader from "../../components/reusable/Loader/TableLoader";

const TerminalListComponent: any = Loadable({
  loader: () => import("../../components/Terminal/TerminalList"),
  loading: () => <TableLoader />,
});

type Props = {};

const Terminal = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const { data: terminalData, loading: terminalLoading } = useQuery(
    QUERY_TERMINAL,
    {
      variables: {
        first: pageSize,
        offset: offset,
        name_Icontains: searchItems?.searchInput,
      },
    }
  );

  const [deleteTerminal] = useMutation(DELETE_TERMINAL, {
    refetchQueries: [
      {
        query: QUERY_TERMINAL,
        variables: {
          first: pageSize,
          offset: offset,
          name_Icontains: searchItems?.searchInput,
        },
      },
    ],
  });

  const handleDeleteTerminal = async (id: string) => {
    const response = await deleteTerminal({
      variables: {
        objectId: id,
      },
    });
    if (response.data?.deleteTerminal?.success) {
      toast.success(response.data?.deleteTerminal?.message);
    } else {
      toast.error(
        response.data?.deleteTerminal?.errors?.nonFieldErrors[0]?.message
      );
    }
  };

  return (
    <TerminalListComponent
      data={terminalData?.terminals}
      terminalLoading={terminalLoading}
      handleDeleteTerminal={handleDeleteTerminal}
      pageSize={pageSize}
      setPageSize={setPageSize}
      setOffset={setOffset}
      offset={offset}
    />
  );
};

export default Terminal;
