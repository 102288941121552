import { useMutation, useReactiveVar } from "@apollo/client";
import React from "react";
import Loadable from "react-loadable";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { searchItemsVar } from "../../components/localstate/Cache";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../customhooks/usePaginationHook";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import { CREATE_TAX } from "../mutations";
import { QUERY_TAX } from "../queries";

const AddTaxComponent: any = Loadable({
  loader: () => import("../../components/Tax/AddTax"),
  loading: DataLoadSpinner,
});

type Props = {};

const AddTax = (props: Props) => {
  const navigate = useNavigate();

  const { pageSize, offset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [createTax, { loading: createTaxLoading }] = useMutation(CREATE_TAX, {
    refetchQueries: [
      {
        query: QUERY_TAX,
        variables: {
          first: pageSize,
          offset: offset,
          title_Icontains: searchItems?.searchInput,
        },
      },
    ],
  });

  const handleAddTax = async (values: any, props: any) => {
    const response = await createTax({
      variables: {
        title: values.title,
        rate: values.rate,
        isIncluded: values.isIncluded,
      },
    });
    if (response.data?.createTax?.success) {
      props.resetForm();
      navigate("/tax");
      toast.success("GST Created SuccessFully");
    } else {
      let errors = backendErrorDisplay(response.data?.createTax?.errors);
      props.setErrors(errors);
    }
  };

  return (
    <>
      <AddTaxComponent handleAddTax={handleAddTax}
        createTaxLoading={createTaxLoading}
      />
    </>
  );
};

export default AddTax;
