import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { POS_SCREEN_ORDERED_ITEMS } from "../../../pages/queries";
import randomString from "../../../utils/randomString";
import Card from "../../reusable/Card";
import React from "react";
import classNames from "../../../utils/classNames";
import DataLoadSpinner from "../../reusable/Spinner/DataLoadSpinner";

const CustomerOrderDetails = () => {
  const params = useParams();
  const { data: orderItemsData, loading } = useQuery(POS_SCREEN_ORDERED_ITEMS, {
    variables: {
      id: params?.orderId ?? params?.id,
    },
  });
  return (
    <>
      {loading ? (
        <>
          <DataLoadSpinner />
        </>
      ) : (
        <>
          <div>
            <Card title="Orders Detail">
              <dl className="grid grid-cols-2 xl:grid-cols-3 gap-3">
                <div className=" bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium ">Customer</dt>
                  <dd className="text-sm font-medium">
                    : {orderItemsData?.order?.customer?.user?.fullName ?? "N/A"}
                  </dd>
                </div>
                <div className=" bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium ">Created By</dt>
                  <dd className="text-sm font-medium">
                    : {orderItemsData?.order?.createdBy?.fullName || "N/A"}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium ">Table</dt>
                  <dd className="text-sm font-medium">
                    : {orderItemsData?.order?.table?.title || "N/A"}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium ">Total price</dt>
                  <dd className="text-sm font-medium">
                    : {orderItemsData?.order?.totalPrice || "N/A"}
                  </dd>
                </div>
                <div className=" bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium ">Discount Amount</dt>
                  <dd className="text-sm font-medium">
                    : {orderItemsData?.order?.discount || "N/A"}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium ">GST</dt>
                  <dd className="text-sm font-medium">
                    : {orderItemsData?.order?.tax || "N/A"}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium ">Service Charge</dt>
                  <dd className="text-sm font-medium">
                    : {orderItemsData?.order?.serviceCharge || "N/A"}
                  </dd>
                </div>

                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium ">Gross Amount</dt>
                  <dd className="text-sm font-medium">
                    : {orderItemsData?.order?.grossAmount || "N/A"}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium ">Payment Status</dt>
                  <dd className="text-sm font-medium">
                    : {orderItemsData?.order?.paymentStatus || "N/A"}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium ">Order Source</dt>
                  <dd className="text-sm font-medium">
                    : {orderItemsData?.order?.orderSource || "N/A"}
                  </dd>
                </div>
              </dl>
            </Card>
            <Card title="Ordered Products">
              <div className="relative border rounded">
                <table style={{ width: "100%", textAlign: "center" }}>
                  <thead className="bg-primary text-white">
                    <tr className="">
                      <th className="py-2 font-medium">S.N</th>
                      <th className="py-2 font-medium">Title</th>
                      <th className="py-2 font-medium">Qty</th>
                      <th className="py-2 font-medium">Rate</th>
                      <th className="py-2 font-medium">Amount</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontWeight: "500" }}>
                    {orderItemsData?.order?.orderItems?.edges?.map(
                      (item: any, index: number) => (
                        <React.Fragment key={index}>
                          <tr
                            key={randomString(20)}
                            className={classNames(
                              index % 2 != 0 ? "bg-white" : "bg-[#F8F9FA]"
                            )}
                          >
                            <td className="py-3">{index + 1}</td>
                            <td className="py-3">
                              <span>{item?.node?.product?.title}</span>
                              <br />
                            </td>
                            <td className="py-3">{item?.node?.quantity}</td>
                            <td className="py-3">
                              {item?.node?.product?.finalPrice}
                            </td>
                            <td className="py-3">{item?.node?.totalPrice}</td>
                          </tr>
                          {item?.node?.modifier?.length
                            ? item?.node?.modifier?.map((modItem: any) => (
                                <tr
                                  key={randomString(20)}
                                  className={classNames(
                                    index % 2 != 0 ? "bg-white" : "bg-[#F8F9FA]"
                                  )}
                                >
                                  <td></td>
                                  <td>--{modItem?.title}</td>
                                  <td>{modItem?.quantity}</td>
                                  <td>{modItem?.product?.finalPrice}</td>
                                  <td>{modItem?.totalPrice}</td>
                                </tr>
                              ))
                            : null}
                          {item?.node?.discount !== "0.00" && (
                            <tr
                              key={randomString(20)}
                              className={classNames(
                                index % 2 != 0 ? "bg-white" : "bg-[#F8F9FA]"
                              )}
                            >
                              <td></td>
                              <td>--Item Discount</td>
                              <td></td>
                              <td></td>
                              <td>-{item?.node?.discount}</td>
                            </tr>
                          )}
                        </React.Fragment>
                      )
                    )}
                    <tr>
                      {orderItemsData?.order?.note
                        ? `Over All note*[${orderItemsData?.note}]`
                        : ""}
                    </tr>
                  </tbody>
                </table>
                <div
                  style={{
                    marginTop: 15,
                    justifyContent: "flex-end",
                    display: "flex",
                    width: "97%",
                    fontWeight: "500",
                  }}
                >
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "100%", textAlign: "left" }}>
                          Sub Total
                        </td>
                        <td style={{ textAlign: "center" }}>:</td>
                        <td style={{ textAlign: "right" }}>
                          {orderItemsData?.order?.totalPrice}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "100%", textAlign: "left" }}>
                          Discount
                        </td>
                        <td style={{ textAlign: "center" }}>:</td>
                        <td style={{ textAlign: "right" }}>
                          {orderItemsData?.order?.discount}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "100%", textAlign: "left" }}>
                          GST
                        </td>
                        <td style={{ textAlign: "center" }}>:</td>
                        <td style={{ textAlign: "right" }}>
                          {orderItemsData?.order?.tax}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "100%",
                            textAlign: "left",
                            paddingRight: "20px",
                          }}
                        >
                          Service Charge
                        </td>
                        <td style={{ textAlign: "center" }}>:</td>
                        <td style={{ textAlign: "right" }}>
                          {parseFloat(
                            orderItemsData?.order?.serviceCharge
                          ).toFixed(2)}
                        </td>
                      </tr>
                      {orderItemsData?.order?.accountFee !== "0" && (
                        <tr>
                          <td style={{ width: "100%", textAlign: "left" }}>
                            EftPos Charge
                          </td>
                          <td style={{ textAlign: "center" }}>:</td>
                          <td style={{ textAlign: "right" }}>
                            {parseFloat(
                              orderItemsData?.order?.accountFee
                            ).toFixed(2)}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td style={{ width: "100%", textAlign: "left" }}>
                          Tender
                        </td>
                        <td style={{ textAlign: "center" }}>:</td>
                        <td style={{ textAlign: "right" }}>
                          {parseFloat(
                            orderItemsData?.order?.paidAmount
                          ).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "100%", textAlign: "left" }}>
                          Change
                        </td>
                        <td style={{ textAlign: "center" }}>:</td>
                        <td style={{ textAlign: "right" }}>
                          {parseFloat(
                            orderItemsData?.order?.changeAmount
                          ).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          Grand Total
                        </td>
                        <td style={{ textAlign: "center" }}>:</td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {orderItemsData?.order?.grossAmount}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Card>
          </div>
        </>
      )}
    </>
  );
};

export default CustomerOrderDetails;
