import { useLazyQuery, useQuery } from '@apollo/client';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { toast } from 'react-toastify';
import DataLoadSpinner from '../../components/reusable/Spinner/DataLoadSpinner';
import { QUERY_TRANSACTION_ACCOUNTS, QUICK_SALE_REPORT } from '../queries';
import { QUERY_SETTINGS } from '../Settings/graphql';
import AccessControl from '../../helpers/accessControl';
import UserPermissionRequired from '../Error/UserPermisssionRequired';

const QuickSalesReportComponent: any = Loadable({
  loader: () => import('../../components/Report/QuickSalesReport'),
  loading: DataLoadSpinner,
});

type Props = {}

const QuickSalesReport = (props: Props) => {
  const [transactionAccountDropdown, setTransactionAccountDropdown] = useState<any>([])

  const [reportOpenState, setReportOpenState] = useState<boolean>(false)
  const [reportData, setReportData] = useState<any>()

  const { data: settingsData } = useQuery(QUERY_SETTINGS)

  // TRANSACTION ACCOUNT DROPDOWN
  const { data: transactionAccount } = useQuery(QUERY_TRANSACTION_ACCOUNTS, {
    variables: {
      isVisible: true
    }
  })
  useEffect(() => {
    const dropdownFormat: any = [];
    transactionAccount?.transactionAccounts?.edges?.map((elem: any) => (
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      })
    ));
    setTransactionAccountDropdown(dropdownFormat)
  }, [transactionAccount])

  // DETAIL SALES REPORT QUERY
  const [generateDetailSalesReport, { loading: quickSalesReportLoading }] = useLazyQuery(QUICK_SALE_REPORT, {
    fetchPolicy: "no-cache",
  })

  const handleGenerateQuickSalesReport = async (values: any) => {
    const response = await generateDetailSalesReport({
      variables: {
        paymentMethods: values.paymentMethods,
        taxType: values.taxType,
        fromDateTime: moment(values.fromDate).format('yyyy-MM-DDTHH:mm:ss'),
        toDateTime: moment(values.toDate).format('yyyy-MM-DDTHH:mm:ss'),
      }
    })
    if (response?.data?.quickSaleReport?.createdOn) {
      setReportOpenState(true)
      setReportData(response?.data?.quickSaleReport)
    } else {
      toast.error("Report data available")
    }
  }

  const handleReset = (setFieldValue: any) => {
    setFieldValue('modeOfPayment', [])
    setFieldValue('isTaxable', [])
    setFieldValue('fromDate', "")
    setFieldValue('toDate', "")
  }

  const quickSaleReportInitialValues = {
    paymentMethods: transactionAccount?.transactionAccounts?.edges?.map((elem: any) => elem?.node?.pk) ?? [],
    taxType: [],
    fromDate: moment(new Date()).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    toDate: moment(new Date()).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
  }

  return (
    <AccessControl
      allowedPermissions={['can_view_report']}
      renderNoAccess={<UserPermissionRequired />}
      children={
        <QuickSalesReportComponent
          transactionAccountDropdown={transactionAccountDropdown}
          quickSalesReportLoading={quickSalesReportLoading}
          handleGenerateQuickSalesReport={handleGenerateQuickSalesReport}
          reportOpenState={reportOpenState}
          setReportOpenState={setReportOpenState}
          reportData={reportData}
          currency={settingsData?.basicWebsiteConfigurations?.currency}
          handleReset={handleReset}
          settingsData={settingsData}
          quickSaleReportInitialValues={quickSaleReportInitialValues}
        />
      }
    />
  )
}

export default QuickSalesReport