import { useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import { LIST_UNITS } from "./Graphql";
import { DELETE_UNIT, GET_UNIT } from "./Graphql";
import { toast } from "react-toastify";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../customhooks/usePaginationHook";
import { searchItemsVar } from "../../components/localstate/Cache";
import AccessControl from "../../helpers/accessControl";
import { slugifyFunction } from "../../utils/slugifyFunction";
import TableLoader from "../../components/reusable/Loader/TableLoader";

const EditUnit: any = Loadable({
  loader: () => import("./EditUnit"),
  loading: DataLoadSpinner,
});
const UnitForm: any = Loadable({
  loader: () => import("../Units/UnitForm"),
  loading: DataLoadSpinner,
});
const UnitList: any = Loadable({
  loader: () => import("../../components/Units/UnitList"),
  loading: () => <TableLoader />,
});

const Unit = () => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [edit, setEdit] = useState(true);
  const [getData, { data: selectedUnitData }] = useLazyQuery(GET_UNIT);
  const [deleteUnit] = useMutation(DELETE_UNIT, {
    refetchQueries: [
      {
        query: LIST_UNITS,
        variables: {
          first: pageSize,
          offset: offset,
          title_Icontains: searchItems?.searchInput,
        },
      },
    ],
  });

  const handleDeleteUnit = async (id: string) => {
    const response = await deleteUnit({
      variables: {
        objectId: id,
      },
    });

    if (response?.data?.deleteMeasurementUnit?.success) {
      toast.success(response?.data?.deleteMeasurementUnit?.message);
    } else {
      toast.error(
        response?.data?.deleteMeasurementUnit?.errors?.nonFieldErrors[0]
          ?.message
      );
    }
  };
  const handleEditUnit = async (id: any) => {
    setEdit(true);
    await getData({
      variables: {
        id: id,
      },
    });
  };

  const { data, loading: unitLoading } = useQuery(LIST_UNITS, {
    variables: {
      first: pageSize,
      offset: offset,
      title_Icontains: searchItems?.searchInput,
    },
  });

  const handleCreateSlug = (text: string, setFieldValue: any) => {
    setFieldValue("shortCode", slugifyFunction(text));
  };

  return (
    <>
      <div className="flex justify-around mt-9">
        <div className="w-[35rem]">
          <UnitList
            data={data?.units}
            handleEditUnit={handleEditUnit}
            handleDeleteUnit={handleDeleteUnit}
            pageSize={pageSize}
            setPageSize={setPageSize}
            offset={offset}
            setOffset={setOffset}
            unitLoading={unitLoading}
          />
        </div>
      </div>
    </>
  );
};

export default Unit;
