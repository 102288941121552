import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import usePaginationHook from "../../customhooks/usePaginationHook";
import { searchItemsVar } from "../../components/localstate/Cache";
import { POS_SCREEN_COMPOUND_PRODUCT } from "../Product/queries";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import {
  POS_PRODUCT_SOLD_OUT,
  POS_SCREEN_DELETE_PRODUCT,
} from "../Product/mutation";
import TableLoader from "../../components/reusable/Loader/TableLoader";

const PromotionComponent: any = Loadable({
  loader: () => import("../../components/Promotion/PromotionList"),
  loading: () => <TableLoader showLeftBar={false} />,
});
const Promotion = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();

  // SEARCH FOR PRODUCT
  const searchItems: any = useReactiveVar(searchItemsVar);

  const { data: posScreenCompoundProducts, loading: compoundProductsLoading } =
    useQuery(POS_SCREEN_COMPOUND_PRODUCT, {
      variables: {
        first: pageSize,
        offset: offset,
        compound_Isnull: false,
        isModifier: false,
        title: searchItems?.searchInput ?? "",
      },
      fetchPolicy: "network-only",
    });
  // DELETE FOR POS
  const [deleteProduct, { loading: loadingDeleteProduct }] = useMutation(
    POS_SCREEN_DELETE_PRODUCT,
    {
      refetchQueries: [
        {
          query: POS_SCREEN_COMPOUND_PRODUCT,
          variables: {
            first: pageSize,
            offset: offset,
            compound_Isnull: false,
            isModifier: false,
            title: searchItems?.searchInput ?? "",
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const handleDeleteProduct = async (id: any) => {
    const response = await deleteProduct({
      variables: {
        objectId: id,
      },
    });
    if (response?.data?.deleteProduct?.success) {
      toast.success("Product Deleted successfully");
    } else {
      const errors = backendErrorDisplay(response?.data?.deleteProduct?.errors);
      toast.error(errors);
    }
  };
  const [updatePromotion] = useMutation(POS_PRODUCT_SOLD_OUT, {
    refetchQueries: [
      {
        query: POS_SCREEN_COMPOUND_PRODUCT,
        variables: {
          first: pageSize,
          offset: offset,
          compound_Isnull: false,
          isModifier: false,
          title: searchItems?.searchInput ?? "",
        },
      },
    ],
  });
  return (
    <div>
      <PromotionComponent
        data={posScreenCompoundProducts?.products?.edges ?? []}
        pageSize={pageSize}
        setPageSize={setPageSize}
        offset={offset}
        setOffset={setOffset}
        handleDelete={handleDeleteProduct}
        compoundProductsLoading={compoundProductsLoading}
        updatePromotion={updatePromotion}
      />
    </div>
  );
};

export default Promotion;
