import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import React from "react";
import { QUERY_CATEGORY } from "../queries";
import { DELETE_CATEGORY } from "../mutations";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import Loadable from "react-loadable";
import usePaginationState from "../../customhooks/usePaginationHook";
import { searchItemsVar } from "../../components/localstate/Cache";
import TableLoader from "../../components/reusable/Loader/TableLoader";

const CategoryListComponent: any = Loadable({
  loader: () => import("../../components/Category/CategoryList"),
  loading: () => <TableLoader showTotal={true} />,
});

const CategoryList: React.FC<any> = (props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    refetchQueries: [
      {
        query: QUERY_CATEGORY,
        variables: {
          first: pageSize,
          level: 0,
          offset: offset,
          name_Icontains: searchItems?.searchInput,
        },
      },
    ],
  });

  const { data: categoryData, loading: categoryLoading } = useQuery(
    QUERY_CATEGORY,
    {
      variables: {
        first: pageSize,
        level: 0,
        offset: offset,
        name_Icontains: searchItems?.searchInput,
      },
    }
  );

  const handleDeleteCategory = async (id: string) => {
    const response = await deleteCategory({
      variables: {
        objectId: id,
      },
    });
    if (response?.data?.deleteCategory?.success) {
      toast.success(response?.data?.deleteCategory?.message);
    } else {
      toast.error(
        response?.data?.deleteCategory?.errors?.nonFieldErrors[0]?.message
      );
    }
  };

  return (
    <>
      <CategoryListComponent
        data={categoryData?.categories}
        categoryLoading={categoryLoading}
        handleDeleteCategory={handleDeleteCategory}
        setPageSize={setPageSize}
        pageSize={pageSize}
        setOffset={setOffset}
        offset={offset}
      />
    </>
  );
};

export default CategoryList;
