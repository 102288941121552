import { useMutation, useQuery } from "@apollo/client";
import Loadable from "react-loadable";
import usePaginationHook from "../../../customhooks/usePaginationHook";

import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import { QUERY_TRANSACTION_ACCOUNTS } from "../../queries";
import { toast } from "react-toastify";
import {
  DELETE_TRANSACTION_ACCOUNTS,
  HANDLE_TOGGLE_TENDER,
} from "../../mutations";
import TableLoader from "../../../components/reusable/Loader/TableLoader";
const AccountComponent: any = Loadable({
  loader: () =>
    import("../../../components/Account/ManageAccounts/AccountList"),
  loading: () => <TableLoader />,
});
const Account = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();

  const { data, loading: ledgerLoading } = useQuery(
    QUERY_TRANSACTION_ACCOUNTS,
    {
      variables: {
        first: pageSize,
        offset: offset,
        isVisible: true,
      },
      fetchPolicy: "network-only",
    }
  );

  const [deleteAccount] = useMutation(DELETE_TRANSACTION_ACCOUNTS, {
    refetchQueries: [
      {
        query: QUERY_TRANSACTION_ACCOUNTS,
        variables: {
          first: pageSize,
          offset: offset,
          isVisible: true,
        },
      },
    ],
  });
  const [handleToggleTender] = useMutation(HANDLE_TOGGLE_TENDER, {
    refetchQueries: [
      {
        query: QUERY_TRANSACTION_ACCOUNTS,
        variables: {
          first: pageSize,
          offset: offset,
          isVisible: true,
        },
      },
    ],
  });
  const handleDeleteAccount = async (id: string) => {
    const response = await deleteAccount({
      variables: {
        objectId: id,
      },
    });
    if (response.data?.deleteTransactionAccount?.success) {
      toast.success(response.data?.deleteTransactionAccount?.message);
    } else {
      toast.error(
        response.data?.deleteTransactionAccount?.errors?.nonFieldErrors[0]
          ?.message
      );
    }
  };

  const handleToggleTenderChange = async (value: any) => {
    const response = await handleToggleTender({
      variables: {
        objectId: value,
      },
    });
    if (response?.data?.toggleRequiredTender?.success) {
      toast.success(response?.data?.toggleRequiredTender?.message);
    } else {
      toast.error(response?.data?.toggleRequiredTender?.message);
    }
  };

  return (
    <div>
      <AccountComponent
        data={data?.transactionAccounts?.edges ?? []}
        pageSize={pageSize}
        setPageSize={setPageSize}
        offset={offset}
        setOffset={setOffset}
        handleDelete={handleDeleteAccount}
        ledgerLoading={ledgerLoading}
        handleToggleTenderChange={handleToggleTenderChange}
      />
    </div>
  );
};

export default Account;
