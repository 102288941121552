import React, { useEffect, useRef, useState } from "react";
import randomString from "../../../utils/randomString";
import { useDropzone } from "react-dropzone";
import ImageIcon from "../../../assets/imageUpload.svg";
interface IImageUploadField {
  values: any;
  setFieldValue: any;
  label: any;
}

const SingleImageUpload: React.FC<IImageUploadField> = (props) => {
  const { setFieldValue, values, label } = props;
  const [imageUrl, setImageUrl] = useState<any>("");

  const handleImageUpload = (file: any) => {
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
    }
    setFieldValue("image", file);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((file: any, index: number) => {
        handleImageUpload(file);
      });
    },
  });
  return (
    <div className="mb-3 w-full">
      <label className="font-medium">{label}</label>
      <div
        {...getRootProps({ className: "dropzone" })}
        className="border-dashed border-2 border-gray-200 border-current p-4 flex flex-col items-center mt-2 "
      >
        {imageUrl ? (
          <>
            <img src={imageUrl} alt="" className="w-20 h-20 cursor-pointer " />
          </>
        ) : (
          <>
            {values?.image ? (
              <img
                src={values?.image}
                alt=""
                className="w-20 h-20 cursor-pointer "
              />
            ) : (
              <img
                src={ImageIcon}
                alt=""
                className="w-20 h-20 cursor-pointer "
              />
            )}
          </>
        )}
        <input {...getInputProps()} />
        <p className="text-center">
          Drag 'n' drop some files here, or click to select files
        </p>
      </div>
    </div>
  );
};

export default SingleImageUpload;
