import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { toast } from 'react-toastify';
import DataLoadSpinner from '../../components/reusable/Spinner/DataLoadSpinner';
import { POS_SCREEN_PRODUCT, POS_SCREEN_PRODUCT_CATEGORY } from '../Product/queries';
import { STOCK_REPORT } from '../queries';
import { QUERY_SETTINGS } from '../Settings/graphql';

const StockComponent: any = Loadable({
  loader: () => import('../../components/Report/Stock'),
  loading: DataLoadSpinner,
});

type Props = {}

const Stock = (props: Props) => {

  const [categoryDropdown, setCategoryDropdown] = useState<any>([]);
  const [itemsDropDown, setItemsDropDown] = useState<any>([]);

  const [reportOpenState, setReportOpenState] = useState<boolean>(false)
  const [reportData, setReportData] = useState<any>()

  const [localFormState, setLocalFormState] = useState<any>()

  const { data: settingsData } = useQuery(QUERY_SETTINGS)

  // CATEGORY DROPDOWN LIST
  const { data: categoryData } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      level: 0,
      status: "visible"
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    categoryData?.categories?.edges?.map((elem: any) => (
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
        altId: elem?.node?.id
      })
    ));
    setCategoryDropdown(dropdownFormat);
  }, [categoryData]);

  // PROUDUCT DROPDOWN
  const { data: productData } = useQuery(POS_SCREEN_PRODUCT, {
    variables: {
      categoryList: localFormState?.category.length > 0 ? localFormState?.category : null,
    }
  });
  useEffect(() => {
    const productList: any = [];
    if (localFormState?.category.length > 0) {
      productData?.products?.edges?.map((elem: any) => (
        productList.push({
          id: elem?.node?.pk,
          title: elem?.node?.title,
          contentType: elem?.node?.contentType,
        })
      ));
    }
    setItemsDropDown(productList)
  }, [productData])


  // DETAIL SALES REPORT QUERY
  const [generateStockReport, { loading: stockReportLoading }] = useLazyQuery(STOCK_REPORT)

  const handleGenerateStockReport = async (values: any) => {
    const itemsObjList: any[] = []
    values?.items?.map((elem: any) => (
      itemsObjList.push({ contentType: productData?.products?.edges[0]?.node?.contentTypeId, objectId: elem })
    ))
    const response = await generateStockReport({
      variables: {
        category: values.category,
        items: itemsObjList,
        isTaxable: values.isTaxable,
        fromDateTime: values.fromDate,
        toDateTime: values.toDate,
      }
    })
    if (response?.data?.productStockHistoryReport?.createdOn) {
      setReportData(response?.data?.productStockHistoryReport)
      setReportOpenState(true)
    } else {
      toast.error("Report data available")
    }
  }

  const handleLocalStateChanges = (value: any, name: string) => {
    setLocalFormState((prev: any) => (
      {
        ...prev,
        [name]: value
      }))
  }

  const handleReset = (setFieldValue: any) => {
    setFieldValue('category', [])
    setFieldValue('items', [])
    setFieldValue('isTaxable', [])
    setFieldValue('fromDate', "")
    setFieldValue('toDate', "")
  }

  return (
    <StockComponent
      categoryDropdown={categoryDropdown}
      itemsDropDown={itemsDropDown}
      stockReportLoading={stockReportLoading}
      handleGenerateStockReport={handleGenerateStockReport}
      reportOpenState={reportOpenState}
      setReportOpenState={setReportOpenState}
      reportData={reportData}
      currency={settingsData?.basicWebsiteConfigurations?.currency}
      handleReset={handleReset}
      handleLocalStateChanges={handleLocalStateChanges}
      settingsData={settingsData}
    />
  )
}

export default Stock