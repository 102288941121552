import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import Loadable from "react-loadable";
import usePaginationHook from "../../customhooks/usePaginationHook";

import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import { toast } from "react-toastify";
import { DELETE_DELIVERY_METHOD, DELIVER_METHODS } from "./queries";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import { searchItemsVar } from "../../components/localstate/Cache";
import TableLoader from "../../components/reusable/Loader/TableLoader";

const OrderTypeComponent: any = Loadable({
  loader: () => import("../../components/OrderType/OrderTypeList"),
  loading: () => <TableLoader />,
});

const OrderType = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();
  const searchItems: any = useReactiveVar(searchItemsVar);

  //Deliver Methods
  const { data: deliveryMethods, loading: deliveryMethodsLoading } = useQuery(
    DELIVER_METHODS,
    {
      variables: {
        first: pageSize,
        offset: offset,
        title_Icontains: searchItems?.searchInput,
      },
      fetchPolicy: "network-only",
    }
  );

  // Delete DeliveryMethods
  const [deleteDeleteDeliveryMethod] = useMutation(DELETE_DELIVERY_METHOD, {
    refetchQueries: [
      {
        query: DELIVER_METHODS,
        variables: {
          first: pageSize,
          offset: offset,
          title_Icontains: "",
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleDeleteDeliverMethods = async (id: any) => {
    const response = await deleteDeleteDeliveryMethod({
      variables: {
        objectId: id,
      },
    });
    if (response?.data?.deleteDeliveryMethod?.success) {
      toast.success("Order Type  deleted successfully");
    } else {
      const errors = backendErrorDisplay(
        response?.data?.deleteDeliveryMethod?.errors
      );
      toast.error(errors);
    }
  };

  return (
    <div>
      <OrderTypeComponent
        data={deliveryMethods?.deliveryMethods?.edges ?? []}
        deliveryMethodsLoading={deliveryMethodsLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        offset={offset}
        setOffset={setOffset}
        handleDelete={handleDeleteDeliverMethods}
      />
    </div>
  );
};

export default OrderType;
