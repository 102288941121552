import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import Loadable from "react-loadable";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { UPDATE_CUSTOMER } from "../../mutations";
import { GET_CUSTOMER } from "./grapql";
import Spinner from "../../../components/reusable/Spinner";

const EditCustomerComponent: any = Loadable({
  loader: () => import("../../../components/Account/Customer/EditCustomer"),
  loading: DataLoadSpinner,
});

type Props = {};

const EditCustomer = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate()
  const [openAddressModal, setOpenAddressModal] = useState<boolean>(false);
  const { data: selectedCustomer, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      id: params.id,
    },
  });
  const [updateUserProfile, { loading: updateUserProfileLoading }] = useMutation(UPDATE_CUSTOMER, {
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        variables: {
          id: params.id,
        },
      },
    ],
  });

  const handleEditCustomer = async (values: any, props: any) => {
    const response = await updateUserProfile({
      variables: {
        id: selectedCustomer?.customer?.pk,
        fullName: values?.fullName,
        dob: values?.dob,
        mobileNumber: values?.mobileNumber,
        email: values?.email,
        trackingNumber: values?.trackingNumber,
        fullAddress: values?.fullAddress
      },
    });
    if (response?.data?.updateCustomer?.success) {
      toast.success("Customer Updated Successfully");
      navigate('/customer')
    } else {
      let errors = backendErrorDisplay(
        response.data?.updateCustomer?.errors,
      );
      props.setErrors(errors);
    }
  };

  return (
    <div>
      {loading ? <Spinner /> :
        <EditCustomerComponent
          selectedCustomer={selectedCustomer?.customer}
          loading={loading}
          setOpenAddressModal={setOpenAddressModal}
          openAddressModal={openAddressModal}
          handleEditCustomer={handleEditCustomer}
          updateUserProfileLoading={updateUserProfileLoading}
        />
      }
    </div>
  );
};

export default EditCustomer;
