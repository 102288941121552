import { Formik } from 'formik';
import React from 'react';
import { categoryInitialValues } from '../../../utils/formInitialValues';
import Card from '../../reusable/Card';
import CategoryForm from '../CategoryForm';

interface IAddSubCategoryProps {
  handleAddSubCategory: any
  addCategoryLoading: boolean
};

const AddSubCategory: React.FC<IAddSubCategoryProps> = (props) => {

  const { handleAddSubCategory, addCategoryLoading } = props
  return (
    <>
      <Card title={'Add Sub Category'}>
        <div>
          <Formik
            initialValues={categoryInitialValues}
            // validationSchema={}
            onSubmit={handleAddSubCategory}
          >
            {
              ({
                touched,
                values,
                setFieldValue,
                errors
              }) => {
                return (
                  <CategoryForm
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    categoryLoading={addCategoryLoading}
                    text="Add"
                    imageActive={false}
                  />
                )
              }
            }
          </Formik>
        </div>
      </Card>
    </>
  );
};

export default AddSubCategory;
