//@ts-ignore
import Axios from "axios";

const SYNC_INFO_API_ROOT: string =
  "https://tenantcontrol.dibsolutions.com.au/sync-history";

//create Sync Info axios instance
export const syncInfoInstance = Axios.create({
  baseURL: `${SYNC_INFO_API_ROOT}`,
});


//Get Data for Sync Info API
export const synInfoGetApiData = async (url, param = null) => {
  let response;
  try {
    response = await syncInfoInstance({
      method: "GET",
      url: `${url}`,
      params: param,
      transformResponse: [
        function (responseData) {
          // Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    return e.response;
  }
  return response;
};

