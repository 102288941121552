import { useMutation } from "@apollo/client";
import Loadable from "react-loadable";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { CREATE_POS_DISCOUNT } from "../../mutations";
import { QUERY_POS_DISCOUNT } from "../../queries";

const AddPOSDiscountComponent: any = Loadable({
  loader: () =>
    import("../../../components/Discount/POSDiscount/AddPOSDiscount"),
  loading: DataLoadSpinner,
});

type Props = {};

const AddPOSDiscount = (props: Props) => {
  const navigate = useNavigate();

  const { pageSize, offset } = usePaginationState();

  const [createPosDiscount, { loading: createPosDiscountLoading }] = useMutation(CREATE_POS_DISCOUNT, {
    refetchQueries: [
      {
        query: QUERY_POS_DISCOUNT,
        variables: {
          first: pageSize,
          offset: offset,
          discountType: "",
          title: ""
        },
      },
    ],
  });

  const handleAddPOSDiscount = async (values: any, props: any) => {
    const response = await createPosDiscount({
      variables: {
        title: values.title,
        discountType: values.discountType,
        rateAmount: values.rateAmount,
      },
    });
    if (response.data?.createPosDiscount?.success) {
      props.resetForm();
      navigate("/pos-discount");
      toast.success("Added GST");
    } else {
      let errors = backendErrorDisplay(
        response.data?.createPosDiscount?.errors,
      );
      props.setErrors(errors);
    }
  };

  return (
    <>
      <AddPOSDiscountComponent
        handleAddPOSDiscount={handleAddPOSDiscount}
        createPosDiscountLoading={createPosDiscountLoading}
      />
    </>
  );
};

export default AddPOSDiscount;
