import { useQuery } from "@tanstack/react-query";
import { synInfoGetApiData } from "../helpers/AxiosInstance";

interface IUseGetHook {
  queryKey: any;
  url: string;
  params: any;
  enabled?: any;
}

export const useSyncInfoGetHook = (props: IUseGetHook) => {
  const { queryKey, url, params, enabled } = props;
  let response;
  const { isLoading, isError, data, isSuccess, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      response = await synInfoGetApiData(url, params);
      if (response.status === 404) {
        throw new Error(response.data.message);
      }
      return response.data;
    },
    refetchOnWindowFocus: false,
    enabled,
  });

  return { isLoading, isError, data, isSuccess, refetch };
};
