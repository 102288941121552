import { useMutation, useReactiveVar } from '@apollo/client';
import Loadable from "react-loadable";
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import { backendErrorDisplay } from '../../utils/backendErrorDisplay';
import { CREATE_SERVICE_CHARGE } from '../mutations';
import { QUERY_SERVICE_CHARGES } from '../queries';
import { searchItemsVar } from '../../components/localstate/Cache';

const AddServiceChargeComponent: any = Loadable({
  loader: () => import('../../components/ServiceCharge/AddServiceCharge'),
  loading: DataLoadSpinner,
});

type Props = {}

const AddServiceCharge = (props: Props) => {
  const navigate = useNavigate();
  const searchItems: any = useReactiveVar(searchItemsVar)
  const [createServiceCharge, { loading: createServiceChargeLoading }] = useMutation(CREATE_SERVICE_CHARGE, {
    refetchQueries: [{
      query: QUERY_SERVICE_CHARGES, variables: {
        first: 10,
        offset: 0,
        title_Icontains: searchItems?.searchInput
      }
    }]
  })

  const handleAddServiceCharge = async (values: any, props: any) => {
    const response = await createServiceCharge({
      variables: {
        title: values.title,
        rate: values.rate
      }
    })
    if (response.data?.createServiceCharge?.success) {
      props.resetForm()
      navigate('/servicecharge')
      toast.success("Added Service Charge")
    }
    else {
      let errors = backendErrorDisplay(response.data?.createServiceCharge?.errors)
      props.setErrors(errors)
    }
  }

  return (
    <div>

      <AddServiceChargeComponent
        handleAddServiceCharge={handleAddServiceCharge}
        createServiceChargeLoading={createServiceChargeLoading}
      />
    </div>
  )
}

export default AddServiceCharge