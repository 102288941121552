import Loadable from 'react-loadable';

import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { backendErrorDisplay } from '../../utils/backendErrorDisplay';
import { CREATE_UPDATE_REWARD_SETTINGS } from '../mutations';
import DataLoadSpinner from '../../components/reusable/Spinner/DataLoadSpinner';
import { REWARD_SETTING_POINT } from '../queries';


const RewardSettingComponent: any = Loadable({
  loader: () => import('../../components/RewardSetting/RewardSetting'),
  loading: DataLoadSpinner,
});

type Props = {}

const RewardSetting = (props: Props) => {

  const { data: rewardSettingPointData, loading: rewardSettingPointLoading } = useQuery(REWARD_SETTING_POINT)


  const [createUpdateRewardPointSetting, { loading: createUpdateRewardPointSettingLoading }] = useMutation(CREATE_UPDATE_REWARD_SETTINGS, {
    refetchQueries: [{
      query: REWARD_SETTING_POINT
    }]
  })

  const handleCreateUpdateRewardPointSetting = async (values: any, props: any) => {
    const response = await createUpdateRewardPointSetting({
      variables: {
        amount: values?.amount,
        point: values?.point,
        minRequiredPoint: values?.minRequiredPoint,
        isActive: values?.isActive
      }
    })
    if (response.data?.createUpdateRewardPointSetting?.success) {
      toast.success("Successfully Added")
    } else {
      let errors = backendErrorDisplay(response.data?.createPrinter?.errors)
      props.setErrors(errors)
    }
  }

  if (rewardSettingPointLoading) return <DataLoadSpinner />

  return (
    <RewardSettingComponent
      handleCreateUpdateRewardPointSetting={handleCreateUpdateRewardPointSetting}
      loading={createUpdateRewardPointSettingLoading}
      rewardSettingPointData={rewardSettingPointData?.rewardPointSetting}
      initialValues={{
        amount: rewardSettingPointData?.rewardPointSetting?.amount ?? "",
        point: rewardSettingPointData?.rewardPointSetting?.point ?? "",
        minRequiredPoint: rewardSettingPointData?.rewardPointSetting?.minRequiredPoint ?? "",
        isActive: rewardSettingPointData?.rewardPointSetting?.isActive,
      }}
    />
  )
}

export default RewardSetting