import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { QUERY_SETTINGS } from "../pages/Settings/graphql";

const useWebSocketHook = () => {
  let chatSocket: any = null;
  const [qrCodeOrderMessageData, setQrCodeOrderMessageData] = useState<any>({});
  const [websiteOrderMessageData, setWebsiteOrderMessageData] = useState<any>({});
  const [posScreenOrderMessageData, setPosScreenOrderMessageData] = useState<any>({});

  const { data } = useQuery(QUERY_SETTINGS)


  const [reConnect, setReconnect] = useState(false);
  useEffect(() => {
    if (!data?.basicWebsiteConfigurations?.schema) return
    chatSocket = new WebSocket(`${process.env.REACT_APP_SOCKET_ENDPOINT}`);
    chatSocket.onopen = function (e: any) {
      console.log(e, "Successfully connected to the WebSocket.");
      chatSocket.send(
        JSON.stringify({ action: "registerDevice", message: `${data?.basicWebsiteConfigurations?.schema}` })
      );
    };
    chatSocket.onclose = function (e: any) {
      console.log(
        "WebSocket connection closed unexpectedly. Trying to reconnect in 2s..."
      );
      setTimeout(function () {
        console.log("Reconnecting...");
        setReconnect(!reConnect);
      }, 2000);
    };
    chatSocket.onmessage = function (e: any) {
      let data = JSON.parse(e.data)
      if (data?.type === "qrCode_order") {
        setQrCodeOrderMessageData(data)
      }
      if (data?.type === "website_order") {
        setWebsiteOrderMessageData(data)
      }
      if (data?.type === "pos_kitchen_order") {
        setPosScreenOrderMessageData(data)
      }
    };
    // scroll 'chatLog' to the bottom
    // chatLog.scrollTop = chatLog.scrollHeight;
    chatSocket.onerror = function (err: any) {
      console.log("WebSocket encountered an error: " + err.message);
      console.log("Closing the socket.");
      chatSocket.close();
    };
  }, [reConnect, data]);
  return { qrCodeOrderMessageData, websiteOrderMessageData, posScreenOrderMessageData };
};
export default useWebSocketHook;