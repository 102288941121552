import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import useCommonState from "../../../customhooks/useCommonState";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { GET_TRANSACTION_ACCOUNT, GET_TRANSACTION_ACCOUNTS } from "../../queries";
import Loadable from "react-loadable";


const AccountDetailComponent: any = Loadable({
  loader: () =>
    import("../../../components/Account/ManageAccounts/AccountDetailComponent"),
  loading: DataLoadSpinner,
});

const AccountDetail = () => {
  const id = useParams();
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();
  const { transactionStartDate, setTransactionStartDate, transactionEndDate, setTransactionEndDate } = useCommonState();

  const {
    data: selectedTransactionAccount,
    loading: loadingTransactionAccount,
  } = useQuery(GET_TRANSACTION_ACCOUNTS, {
    variables: {
      transaction_Account: id.id,
      first: pageSize,
      offset: offset,
      transaction_Created_Gte: moment(transactionStartDate).format(),
      transaction_Created_Lte: moment(transactionEndDate).format(),
    },
  });
  return (
    <div>
      <AccountDetailComponent
        selectedTransactionAccount={
          selectedTransactionAccount?.accountTransactionsView?.edges ?? []
        }
        loadingTransactionAccount={loadingTransactionAccount}
        pageSize={pageSize}
        setPageSize={setPageSize}
        offset={offset}
        setOffset={setOffset}
        transactionStartDate={transactionStartDate}
        setTransactionStartDate={setTransactionStartDate}
        transactionEndDate={transactionEndDate}
        setTransactionEndDate={setTransactionEndDate}
      />
    </div>
  );
};

export default AccountDetail;
