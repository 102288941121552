import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { toast } from 'react-toastify';
import DataLoadSpinner from '../../components/reusable/Spinner/DataLoadSpinner';
import { CREATE_FLOAT_AMOUNT, GET_FLOAT_AMOUNT } from '../queries';
import { QUERY_SETTINGS } from '../Settings/graphql';

const OpeningAndClosingComponent: any = Loadable({
  loader: () => import('../../components/Report/OpeningAndClosing'),
  loading: DataLoadSpinner,
});

type Props = {}

const OpeningAndClosing = (props: Props) => {
  const { data } = useQuery(GET_FLOAT_AMOUNT)

  const [noteState, setNoteState] = useState<any>([
    { note: 100, count: 0, value: 0, name: "hundred" },
    { note: 50, count: 0, value: 0, name: "fifty" },
    { note: 20, count: 0, value: 0, name: "twenty" },
    { note: 10, count: 0, value: 0, name: "ten" },
    { note: 5, count: 0, value: 0, name: "five" },
    { note: 2, count: 0, value: 0, name: "two" },
    { note: 1, count: 0, value: 0, name: "one" },
  ])

  const [coinState, setCoinState] = useState<any>([
    { coin: 5, count: 0, value: 0, name: "five" },
    { coin: 10, count: 0, value: 0, name: "ten" },
    { coin: 20, count: 0, value: 0, name: "twenty" },
    { coin: 50, count: 0, value: 0, name: "fifty" },
  ])
  const [floatValue, setFloatValue] = useState<any>("")


  // useEffect(() => {
  //   setNoteState([
  //     { note: 100, count: data?.todayFloatAmount?.notesReconciliation['hundred'], value: parseFloat(data?.todayFloatAmount?.notesReconciliation['hundred']) * 100, name: "hundred" },
  //     { note: 50, count: data?.todayFloatAmount?.notesReconciliation['fifty'], value: parseFloat(data?.todayFloatAmount?.notesReconciliation['fifty']) * 50, name: "fifty" },
  //     { note: 20, count: data?.todayFloatAmount?.notesReconciliation['twenty'], value: parseFloat(data?.todayFloatAmount?.notesReconciliation['twenty']) * 20, name: "twenty" },
  //     { note: 10, count: data?.todayFloatAmount?.notesReconciliation['ten'], value: parseFloat(data?.todayFloatAmount?.notesReconciliation['ten']) * 10, name: "ten" },
  //     { note: 5, count: data?.todayFloatAmount?.notesReconciliation['five'], value: parseFloat(data?.todayFloatAmount?.notesReconciliation['five']) * 5, name: "five" },
  //     { note: 2, count: data?.todayFloatAmount?.notesReconciliation['two'], value: parseFloat(data?.todayFloatAmount?.notesReconciliation['two']) * 2, name: "two" },
  //     { note: 1, count: data?.todayFloatAmount?.notesReconciliation['one'], value: parseFloat(data?.todayFloatAmount?.notesReconciliation['one']) * 1, name: "one" },
  //   ])
  //   setCoinState([
  //     { coin: 5, count: data?.todayFloatAmount?.coinsReconciliation['fifty'], value: parseFloat(data?.todayFloatAmount?.notesReconciliation['fifty']) * 50, name: "five" },
  //     { coin: 10, count: data?.todayFloatAmount?.coinsReconciliation['twenty'], value: parseFloat(data?.todayFloatAmount?.notesReconciliation['twenty']) * 20, name: "ten" },
  //     { coin: 20, count: data?.todayFloatAmount?.coinsReconciliation['ten'], value: parseFloat(data?.todayFloatAmount?.notesReconciliation['ten']) * 10, name: "twenty" },
  //     { coin: 50, count: data?.todayFloatAmount?.coinsReconciliation['five'], value: parseFloat(data?.todayFloatAmount?.notesReconciliation['five']) * 5, name: "fifty" },
  //   ])
  //   setFloatValue(data?.todayFloatAmount?.amount)

  // }, [data])

  const { data: settingsData } = useQuery(QUERY_SETTINGS)

  const notesReconciliationCount = (value: any) => {
    const state: any = noteState?.findIndex((elem: any) => elem?.name === value)
    return noteState[state]["count"]
  }

  const coinsReconciliationCount = (value: any) => {
    const state: any = coinState?.findIndex((elem: any) => elem?.name === value)
    return coinState[state]["count"]
  }


  const [generateCreateFloatAmountReport] = useMutation(CREATE_FLOAT_AMOUNT)

  const handleGenerateCreateFloatAmount = async () => {
    
    const response = await generateCreateFloatAmountReport({
      variables: {
        floatAmount: floatValue,
        terminal: [],
        coinsReconciliation: {
          "fifty": coinsReconciliationCount("fifty"),
          "twenty": coinsReconciliationCount("twenty"),
          "ten": coinsReconciliationCount("ten"),
          "five": coinsReconciliationCount("five"),
        },
        notesReconciliation: {
          "hundred": notesReconciliationCount("hundred"),
          "fifty": notesReconciliationCount("fifty"),
          "twenty": notesReconciliationCount("twenty"),
          "ten": notesReconciliationCount("ten"),
          "five": notesReconciliationCount("five"),
          "two": notesReconciliationCount("two"),
          "one": notesReconciliationCount("one"),
        },
      }

    })
    if (response?.data?.createFloatAmount?.success) {
      toast.success(response?.data?.createFloatAmount?.message ?? "Float Amount Successfully Created")
    } else {
      toast.error(response?.data?.createFloatAmount?.message ?? "Error")
    }
  }

  return (
    <OpeningAndClosingComponent
      noteState={noteState}
      setNoteState={setNoteState}
      coinState={coinState}
      setCoinState={setCoinState}
      currency={settingsData?.basicWebsiteConfigurations?.currency}
      handleGenerateCreateFloatAmount={handleGenerateCreateFloatAmount}
      data={data}
      floatValue={floatValue}
      setFloatValue={setFloatValue}

    />
  )
}

export default OpeningAndClosing