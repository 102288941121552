import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import Loadable from "react-loadable";
import GET_RECEIPT from "../../ReceiptSetting/queries";
import { REFUND_ORDER } from "../../mutations";
import { toast } from "react-toastify";
import { useState } from "react";
import {
  cache,
  cartItemsVar,
  customerCache,
  CustomerItemsVar,
} from "../../../components/localstate/Cache";
import {
  GET_CART_ITEMS,
  GET_CUSTOMER_ITEMS,
} from "../../../components/localstate/Cart";
import { POS_SCREEN_ORDERED_ITEMS } from "../../queries";
import usePaginationHook from "../../../customhooks/usePaginationHook";

const DashboardOrderDetailComponent: any = Loadable({
  loader: () =>
    import("../../../components/Orders/OrderList/DashboardDetailOrder"),
  loading: DataLoadSpinner,
});

const DashboardOrderDetail = () => {
  const params = useParams();
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();
  const navigate = useNavigate();

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const { data: receiptData } = useQuery(GET_RECEIPT);

  const { loading: isLoading, data: orderItemsData } = useQuery(
    POS_SCREEN_ORDERED_ITEMS,
    {
      variables: {
        id: params.id,
        first: pageSize,
        offset: offset,
      },
    }
  );

  const [refundOrder, { loading }] = useMutation(REFUND_ORDER, {
    refetchQueries: [
      {
        query: POS_SCREEN_ORDERED_ITEMS,
        variables: {
          id: params.id,
        },
      },
    ],
  });

  const handleRefund = async () => {
    const response = await refundOrder({
      variables: {
        objectId: orderItemsData?.order?.pk,
      },
    });
    if (response.data?.refundOrder?.success) {
      toast.success(response?.data?.refundOrder?.message);
    } else {
      toast.error(response?.data?.refundOrder?.message);
    }
    setOpenConfirmDialog(false);
  };

  const handleViewOrderInPos = () => {
    const itemToCart: any[] = [];

    orderItemsData?.order?.orderItems?.edges?.map((item: any) => {
      let modItems: any = [];
      if (item?.node?.modifier) {
        item?.node?.modifier?.map((modItem: any) =>
          modItems.push({
            id: modItem?.product?.id,
            pk: modItem?.product?.pk,
            contentTypeId: modItem?.contentTypeId,
            title: modItem?.product?.title,
            quantity: modItem?.quantity,
            price: modItem?.product?.finalPrice ?? 0,
            total:
              parseFloat(modItem?.quantity) *
                parseFloat(modItem?.product?.finalPrice ?? 0) ?? 0,
            tax:
              parseFloat(modItem?.quantity) *
              parseFloat(modItem?.product?.taxAmount),
            taxRate: {
              rate: modItem?.product?.tax?.rate ?? 0,
              isIncluded: modItem?.product?.tax?.isIncluded,
            },
            isSelected: false,
            isDeleted: false,
            isModified: false,
            note: modItem?.note,
          })
        );
      }
      itemToCart.push({
        id: item?.node?.product?.id,
        pk: item?.node?.product?.pk,
        contentTypeId: item?.node?.product?.contentTypeId,
        title: item?.node?.product?.title,
        quantity: item?.node?.quantity,
        price: item?.node?.product?.finalPrice ?? 0,
        total:
          parseFloat(item?.node?.quantity) *
            parseFloat(item?.node?.product?.finalPrice ?? 0) ?? 0,
        tax:
          parseFloat(item?.node?.quantity) *
          parseFloat(item?.node?.product?.taxAmount),
        taxRate: {
          rate: item?.node?.product?.tax?.rate ?? 0,
          isIncluded: item?.node?.product?.tax?.isIncluded,
        },
        isSelected: false,
        isDeleted: false,
        modifier: modItems,
        idToModifyKitchenOrder: item?.node?.pk,
        isModified: false,
        note: item?.node?.note,
      });
      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar(itemToCart),
        },
      });

      let customerItems = {
        id: orderItemsData?.order?.customer?.id ?? "",
        name: `${
          orderItemsData?.order?.customer
            ? orderItemsData?.order?.customer?.user?.profile?.firstName +
              " " +
              orderItemsData?.order?.customer?.user?.profile?.middleName +
              " " +
              orderItemsData?.order?.customer?.user?.profile?.lastName
            : ""
        }`,
        pk: orderItemsData?.order?.customer?.pk ?? "",
      };
      customerCache.writeQuery({
        query: GET_CUSTOMER_ITEMS,
        data: {
          customerItems: CustomerItemsVar(customerItems),
        },
      });
    });
    if (itemToCart) {
      navigate(`/posscreen`);
    }
  };

  return (
    <div>
      {isLoading ? (
        <DataLoadSpinner />
      ) : (
        <DashboardOrderDetailComponent
          isLoading={loading}
          orderItemsData={orderItemsData?.order}
          receiptData={receiptData}
          openConfirmDialog={openConfirmDialog}
          setOpenConfirmDialog={setOpenConfirmDialog}
          handleRefund={handleRefund}
          handleViewOrderInPos={handleViewOrderInPos}
          setPageSize={setPageSize}
          pageSize={pageSize}
          setOffset={setOffset}
          offset={offset}
          // data={data}
        />
      )}
    </div>
  );
};

export default DashboardOrderDetail;
