import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import Loadable from "react-loadable";
import { useMutation, useQuery } from '@apollo/client';
import { QUERY_COLLECTIONS } from '../queries';
import usePaginationState from '../../../customhooks/usePaginationHook';
import { DELETE_COLLECTION } from "../mutation";
import { toast } from "react-toastify";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";

const CollectionComponent: any = Loadable({
  loader: () => import("../../../components/Product/Collection/Collection"),
  loading: DataLoadSpinner,
})

type Props = {}

const Collection = (props: Props) => {

  const { pageSize, setPageSize, offset, setOffset } = usePaginationState()
  const { data: collectionData, loading: collectionDataLoading } = useQuery(QUERY_COLLECTIONS, {
    variables: {
      first: pageSize,
      offset: offset
    }
  })

  const [deleteCollection] = useMutation(DELETE_COLLECTION, {
    refetchQueries: [{
      query: QUERY_COLLECTIONS, variables: {
        first: pageSize,
        offset: offset
      }
    }],
    awaitRefetchQueries: true
  })

  const handleDeleteCollection = async (id: string) => {
    const response = await deleteCollection({
      variables: {
        objectId: id
      }
    })
    if (response?.data?.deleteCollection?.success) {
      toast.success("Deleted Collection Successfully")
    } else {
      backendErrorDisplay(response.data?.deleteCollection?.errors)
    }
  }

  return (
    <>
      <CollectionComponent
        data={collectionData?.collections}
        loading={collectionDataLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        offset={offset}
        setOffset={setOffset}
        handleDeleteCollection={handleDeleteCollection}
      />
    </>
  )
}

export default Collection