import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react'
import Loadable from "react-loadable";
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import { backendErrorDisplay } from '../../utils/backendErrorDisplay';
import { EDIT_SERVICE_CHARGE } from '../mutations';
import { GET_SERVICE_CHARGE, QUERY_SERVICE_CHARGES } from '../queries';

const EditServiceChargeComponent: any = Loadable({
  loader: () => import('../../components/ServiceCharge/EditServiceCharge'),
  loading: DataLoadSpinner,
});

type Props = {}

const EditServiceCharge = (props: Props) => {
  const navigate = useNavigate()
  const params = useParams()
  const [selectedServiceChargeInitailValues, setSelectedServiceChargeInitailValues] = useState<any>()

  const { data: selectedServiceCharge } = useQuery(GET_SERVICE_CHARGE, {
    variables: {
      id: params.id
    }
  })

  useEffect(() => {
    setSelectedServiceChargeInitailValues(null)
    if (selectedServiceCharge) {
      const newInitialValues = {
        title: selectedServiceCharge?.serviceCharge?.title,
        rate: selectedServiceCharge?.serviceCharge?.rate
      }
      setSelectedServiceChargeInitailValues(newInitialValues)
    }
  }, [selectedServiceCharge, params.id]);

  const [editServiceCharge, { loading: editServiceChargeLoading }] = useMutation(EDIT_SERVICE_CHARGE, {
    refetchQueries: [{
      query: QUERY_SERVICE_CHARGES, variables: {
        first: 10
      }
    }]
  })

  const handleEditServiceCharge = async (values: any, props: any) => {
    const response = await editServiceCharge({
      variables: {
        id: selectedServiceCharge?.serviceCharge?.pk,
        title: values.title,
        rate: values.rate
      }
    })
    if (response.data?.updateServiceCharge?.success) {
      props.resetForm()
      navigate('/servicecharge')
      toast.success("Edited Service Charge")
    } else {
      let errors = backendErrorDisplay(response.data?.updateServiceCharge?.errors)
      props.setErrors(errors)
    }
  }

  return (
    <div>
      <EditServiceChargeComponent
        handleEditServiceCharge={handleEditServiceCharge}
        selectedServiceChargeInitailValues={selectedServiceChargeInitailValues}
        editServiceChargeLoading={editServiceChargeLoading}
      />
    </div>
  )
}

export default EditServiceCharge