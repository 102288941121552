import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { searchItemsVar } from "../../components/localstate/Cache";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../customhooks/usePaginationHook";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import { EDIT_TAX } from "../mutations";
import { GET_TAX, QUERY_TAX } from "../queries";

const EditTaxComponent: any = Loadable({
  loader: () => import("../../components/Tax/EditTax"),
  loading: DataLoadSpinner,
});

type Props = {};

const EditTax = (props: Props) => {
  const navigate = useNavigate();
  const params = useParams();

  const { pageSize, offset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [selectedTaxInitailValues, setSelectedTaxInitailValues] =
    useState<any>();

  const { data: selectedTax } = useQuery(GET_TAX, {
    variables: {
      id: params.id,
    },
  });

  useEffect(() => {
    setSelectedTaxInitailValues(null);
    if (selectedTax) {
      const newInitialValues = {
        title: selectedTax?.tax?.title,
        rate: selectedTax?.tax?.rate,
        isIncluded: selectedTax?.tax?.isIncluded,
      };
      setSelectedTaxInitailValues(newInitialValues);
    }
  }, [selectedTax, params.id]);

  const [editTax, { loading: editTaxLoading }] = useMutation(EDIT_TAX, {
    refetchQueries: [
      {
        query: QUERY_TAX,
        variables: {
          first: pageSize,
          offset: offset,
          title_Icontains: searchItems?.searchInput,
        },
      },
    ],
  });

  const handleEditTax = async (values: any, props: any) => {
    const response = await editTax({
      variables: {
        id: selectedTax?.tax?.pk,
        title: values.title,
        rate: values.rate,
        isIncluded: values.isIncluded,
      },
    });
    if (response.data?.updateTax?.success) {
      props.resetForm();
      navigate("/tax");
      toast.success("Edited GST");
    } else {
      let errors = backendErrorDisplay(response.data?.updateTax?.errors);
      props.setErrors(errors);
    }
  };

  return (
    <div>
      <EditTaxComponent
        handleEditTax={handleEditTax}
        selectedTaxInitailValues={selectedTaxInitailValues}
        editTaxLoading={editTaxLoading}
      />
    </div>
  );
};

export default EditTax;
