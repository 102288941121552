//@ts-nocheck
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loadable from "react-loadable";
import { useNavigate, useParams } from "react-router-dom";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import { QUERY_TAX, QUERY_UNIT } from "../../queries";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { POS_SCREEN_UPDATE_PRODUCT, POS_SCREEN_UPDATE_PRODUCT_DESC } from "../mutation";
import { POS_SCREEN_PRODUCT_CATEGORY, POS_SCREEN_SINGLE_PRODUCT } from "../queries";
import Spinner from "../../../components/reusable/Spinner";

const EditModifierComponent: any = Loadable({
  loader: () =>
    import("../../../components/Product/Modifier/EditModifier"),
  loading: DataLoadSpinner,
});

type Props = {};

const EditModifier = (props: Props) => {
  let params: any = useParams();

  const [unitDropdown, setUnitDropdown] = useState([]);
  const [categoryDropdown, setCategoryDropdown] = useState([]);
  const [taxTypeDropdown, setTaxTypeDropdown] = useState([]);

  const navigate = useNavigate();

  //Product Categories
  const { data: modifierCategoryData } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      first: 100,
      level: 0,
      status: "modifier"
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    modifierCategoryData?.categories?.edges?.map((elem: any) => {
      return dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      });
    });
    setCategoryDropdown(dropdownFormat);
  }, [modifierCategoryData]);

  const { data: unitData } = useQuery(QUERY_UNIT, {
    variables: {
      first: 100,
    },
  });

  useEffect(() => {
    const dropdownFormat: any = [];
    unitData?.units?.edges.map((elem: any) => {
      return dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.title,
      });
    });
    setUnitDropdown(dropdownFormat);
  }, [unitData]);

  //Tax List
  const { data: taxData } = useQuery(QUERY_TAX, {
    variables: {
      first: 100,
      offset: 0,
      title_Icontains: "",
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    if (taxData) {
      taxData?.taxes?.edges?.map((elem: any) =>
        dropdownFormat.push({
          id: elem?.node?.pk,
          title: elem?.node?.title,
          rate: elem?.node?.rate,
          isIncluded: elem?.node?.isIncluded,
        }),
      );
      dropdownFormat.push({
        id: 0,
        title: "GST Free",
        rate: 0,
        isIncluded: true,
      });
    }
    setTaxTypeDropdown(dropdownFormat);
  }, [taxData]);

  const { data: singleModifier, loading: singleProductLoading } = useQuery(
    POS_SCREEN_SINGLE_PRODUCT,
    {
      variables: {
        id: params?.id,
      },
      fetchPolicy: "network-only",
    },
  );
  const [modifierUpdate, { loading: modifierUpdateLoading }] = useMutation(POS_SCREEN_UPDATE_PRODUCT, {
    awaitRefetchQueries: true,
  });
  const handleUpdateModifier = async (values: any, props: any) => {
    const response = await modifierUpdate({
      variables: {
        id: values?.id,
        title: values?.title,
        category: values?.category,
        unit: values?.unit?.pk,
        tax: values?.taxType,
        isModifier: true,
        printers: [],
        modifierCategories: [],
        image: typeof values?.image === 'string' ? null : values?.image,
        sellingPrice: values?.sellingPrice,
        costPrice: values?.costPrice,
        shortDesc: values?.description,
        isActive: true,
        isWeightable: false,
        isModifierCategoryGrid: false,
        isOpenPriceProduct: false,
        barcodeContent: "",
        plu: ""
      },
    });

    if (response?.data?.updateProduct?.success) {
      toast.success("Modifier Updated successfully");
      navigate(`/modifier`);
    } else {
      const errors = backendErrorDisplay(
        response?.data?.updateProduct?.errors,
      );
      props.setErrors(errors);
    }
  }


  return (
    <>
      {singleProductLoading ? (
        <Spinner />
      ) : (
        <EditModifierComponent
          unitData={unitDropdown}
          singleModifier={singleModifier?.product ?? singleModifier?.product}
          categoryDropdown={categoryDropdown}
          taxTypeDropdown={taxTypeDropdown}
          handleUpdateModifier={handleUpdateModifier}
        />
      )}
    </>
  );
};

export default EditModifier;
