import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type Props = {};

const ExpensesLoader = (props: Props) => {
  return (
    <>
      <div className="relative rounded bg-white shadow pl-0 p-6 my-7">
        <div className="flex justify-between mx-2">
          <h2 className="text-xl font-semibold ml-4 mb-4">
            <Skeleton height={30} width={100} />
          </h2>
          <div className="flex justify-between gap-4">
            <Skeleton height={30} width={100} />
            <Skeleton height={30} width={100} />
          </div>
        </div>
        <div className="flex w-full">
          <div className="p-4">
            <div className="mt-4">
              <Skeleton height={90} width={250} />
            </div>
            <div className="mt-4">
              <Skeleton height={90} width={250} />
            </div>
            <div className="mt-4">
              <Skeleton height={90} width={250} />
            </div>
            <div className="mt-4">
              <Skeleton height={90} width={250} />
            </div>
          </div>
          <div className="h-[400px] w-[90%] my-auto">
            <Skeleton  className="my-auto w-full h-full" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpensesLoader;
