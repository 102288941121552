import { useQuery } from "@apollo/client";
import Loadable from "react-loadable";
import { useParams } from "react-router";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import {
  GET_QUERY_SUPPLIER_TRANSACTIONS_VIEW, PURCHASE_HISTORIES,
} from "../../queries";
import { useState } from "react";

const DetailSupplierComponent: any = Loadable({
  loader: () => import("../../../components/Supplier/DetailSupplier"),
  loading: DataLoadSpinner,
});

type Props = {};

const DetailSupplier = (props: Props) => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();
  const { offset: purchaseHistoryOffset, setOffset: setPurchaseHistoryOffset, pageSize: purchaseHistoryPageSize, setPageSize: setPurchaseHistoryPageSize } = usePaginationHook();
  const params = useParams();
  const [supplierValue, setSupplierValue] = useState<any>();
  const [purchaseSupplierValue, setPurchaseSupplierValue] = useState<any>();
  const dateSupplierStart = supplierValue?.startDate
    ? new Date(`${supplierValue?.startDate}T00:00:00`)
    : null;
  const dateSupplierEnd = supplierValue?.endDate
    ? new Date(`${supplierValue?.endDate}T23:59:00`)
    : null;

  const {
    data: selectedSupplierTransaction,
    loading: supplierTransactionLoading,
  } = useQuery(GET_QUERY_SUPPLIER_TRANSACTIONS_VIEW, {
    variables: {
      transaction_User: params?.id,
      first: pageSize,
      offset: offset,
      transaction_Created_Gte: dateSupplierStart
        ? dateSupplierStart.toISOString()
        : null,
      transaction_Created_Lte: dateSupplierEnd
        ? dateSupplierEnd.toISOString()
        : null,
    },
  });
  const dateStart = purchaseSupplierValue?.startDate
    ? new Date(`${purchaseSupplierValue?.startDate}T00:00:00`)
    : null;
  const dateEnd = purchaseSupplierValue?.endDate
    ? new Date(`${purchaseSupplierValue?.endDate}T23:59:00`)
    : null;
  const {
    data: selectedSupplierPurchaseHistory,
    loading: supplierPurchaseHistoryLoading,
  } = useQuery(PURCHASE_HISTORIES, {
    variables: {
      transaction_User: params?.supplierId,
      first: purchaseHistoryPageSize,
      offset: purchaseHistoryOffset,
      created_Gte: dateStart ? dateStart.toISOString() : null,
      created_Lte: dateEnd ? dateEnd.toISOString() : null,
    },
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <DetailSupplierComponent
        selectedSupplierTransaction={selectedSupplierTransaction?.supplierTransactionsView}
        supplierPurchaseHistoryData={selectedSupplierPurchaseHistory?.purchaseHistories}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setOffset={setOffset}
        offset={offset}
        purchaseHistoryOffset={purchaseHistoryOffset}
        setPurchaseHistoryOffset={setPurchaseHistoryOffset}
        purchaseHistoryPageSize={purchaseHistoryPageSize}
        setPurchaseHistoryPageSize={setPurchaseHistoryPageSize}
        setSupplierValue={setSupplierValue}
        supplierValue={supplierValue}
        purchaseSupplierValue={purchaseSupplierValue}
        setPurchaseSupplierValue={setPurchaseSupplierValue}
        supplierTransactionLoading={supplierTransactionLoading}
        supplierPurchaseHistoryLoading={supplierPurchaseHistoryLoading}
      />
    </div>
  );
};

export default DetailSupplier;
