import React from "react";
import Divider from "../Divider";
import PrimaryButton from "../Buttons/PrimaryButton";

interface ICardProps {
  title: string;
  children?: React.ReactNode;
  isAddButton?: boolean;
  addButton?: boolean;
  setAddButton?: any;
  buttonText?: string;
  customButton?: any;
}

const Card: React.FC<ICardProps> = (props) => {
  const {
    title,
    children,
    addButton,
    setAddButton,
    buttonText,
    isAddButton,
    customButton,
  } = props;
  return (
    <div className="rounded-md bg-white mt-4 mb-4 p-5 shadow-md border">
      <div className="flex justify-between items-center">
        <header className="text-2xl capitalize mb-4 font-semibold">{title}</header>
        {customButton ? customButton : <></>}

        {isAddButton && (
          <div className="p-2">
            <PrimaryButton
              onClick={() => setAddButton(!addButton)}
              text={buttonText}
            />
          </div>
        )}
      </div>

      <Divider />
      <div className="py-3">{children}</div>
    </div>
  );
};

export default Card;
