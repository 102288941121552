import { useQuery } from "@apollo/client";
import isElectron from "../helpers/checkElectron";
import { QUERY_SETTINGS } from "../pages/Settings/graphql";


const useOpenScreenKeyboard = () => {
  const { data } = useQuery(QUERY_SETTINGS)
  const onScreenKeyboard = () => {
    if (data?.basicWebsiteConfigurations?.isVirtualKeyboard && isElectron()) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send("openKeyboard");
    }
  }
  return {
    onScreenKeyboard
  };
};
export default useOpenScreenKeyboard;
