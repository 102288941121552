import { gql } from "@apollo/client";
const GET_SEARCH_SELETCED_ITEMS = gql`
  query GetSearchSelectedItems {
    searchSelectedItems @client
  }
`;
const GET_SEARCH_ITEMS = gql`
  query GetSearchItems {
    searchItems @client
  }
`;
const GET_CART_ITEMS = gql`
  query GetCartItems {
    cartItems @client
  }
`;

const GET_PERMISSION_TYPE = gql`
  query GetPermissionType {
    permissionTypes @client
  }
`;
const GET_ORDER_PRINT_ITEMS = gql`
  query GetOrderPrintItems {
    orderPrintItems @client
  }
`;
const GET_TABLE_ITEMS = gql`
  query GetTableItems {
    tableItems @client
  }
`;
const GET_ORDER_TYPE = gql`
  query GetOrderType {
    orderType @client
  }
`;

const GET_SPLIT_PAY = gql`
  query GetSplitPay {
    orderType @client
  }
`;
const GET_CUSTOMER_ITEMS = gql`
  query GetCustomerItems {
    customerItems @client
  }
`;
const GET_KITCHEN_CARD_ID = gql`
  query GetKitchenOrderId {
    kitchenOrderId @client
  }
`;
const GET_MODIFIED_KITCHEN_CARD_ID = gql`
  query GetModifiedKitchenOrderId {
    modifiedKitchenOrderId @client
  }
`;

const GET_HOLD_KITCHEN_CARD_ID = gql`
  query GetHoldKitchenOrderId {
    holdKitchenOrderId @client
  }
`;
const GET_TOTAL_CALCULATION = gql`
  query GetTotalCalculation {
    totalCalculationItems @client
  }
`;
const GET_NOTE_ITEMS = gql`
  query GetNoteItems {
    noteItems @client
  }
`;

const GET_MYORDERS = gql`
  query GetMyOrderItems {
    myOrderItems @client
  }
`;

const GET_QR_CART = gql`
  query GetQrCart {
    qrCart @client
  }
`;

export {
  GET_SEARCH_ITEMS,
  GET_CART_ITEMS,
  GET_PERMISSION_TYPE,
  GET_KITCHEN_CARD_ID,
  GET_HOLD_KITCHEN_CARD_ID,
  GET_TOTAL_CALCULATION,
  GET_TABLE_ITEMS,
  GET_ORDER_TYPE,
  GET_SPLIT_PAY,
  GET_CUSTOMER_ITEMS,
  GET_NOTE_ITEMS,
  GET_MODIFIED_KITCHEN_CARD_ID,
  GET_ORDER_PRINT_ITEMS,
  GET_SEARCH_SELETCED_ITEMS,
  GET_MYORDERS,
  GET_QR_CART
};

// import { gql } from "@apollo/client";
// const GET_SEARCH_SELETCED_ITEMS = gql`
//   query GetSearchSelectedItems {
//     searchSelectedItems @client {
//       index
//       data
//     }
//   }
// `;
// const GET_SEARCH_ITEMS = gql`
//   query GetSearchItems {
//     searchItems @client {
//       name
//       searchInput
//     }
//   }
// `;
// const GET_CART_ITEMS = gql`
//   query GetCartItems {
//     cartItems @client {
//       id
//       pk
//       contentTypeId
//       title
//       quantity
//       price
//       total
//       isSelected
//       isDeleted
//       modifier
//       isModified
//     }
//   }
// `;
// const GET_ORDER_PRINT_ITEMS = gql`
//   query GetOrderPrintItems {
//     orderPrintItems @client
//   }
// `;
// const GET_TABLE_ITEMS = gql`
//   query GetTableItems {
//     tableItems @client {
//       id
//       status
//       pk
//       title
//     }
//   }
// `;
// const GET_CUSTOMER_ITEMS = gql`
//   query GetCustomerItems {
//     customerItems @client {
//       id
//       name
//       pk
//     }
//   }
// `;
// const GET_KITCHEN_CARD_ID = gql`
//   query GetKitchenOrderId {
//     kitchenOrderId @client {
//       id
//       pk
//     }
//   }
// `;
// const GET_MODIFIED_KITCHEN_CARD_ID = gql`
//   query GetModifiedKitchenOrderId {
//     modifiedKitchenOrderId @client {
//       kitchenOrderPk
//     }
//   }
// `;

// const GET_HOLD_KITCHEN_CARD_ID = gql`
//   query GetHoldKitchenOrderId {
//     holdKitchenOrderId @client{
//       pk
//       id
//     }
//   }
// `;
// const GET_TOTAL_CALCULATION = gql`
//   query GetTotalCalculation {
//     totalCalculationItems @client {
//       netAmount
//       discountAmount
//       totalAmtAfterDiscount
//       totalAmtAfterTax
//       totalAmtAfterServiceCharge
//       grossAmount
//       taxAmount
//       serviceChargeAmount
//     }
//   }
// `;
// const GET_NOTE_ITEMS = gql`
//   query GetNoteItems {
//     noteItems @client {
//       note
//     }
//   }
// `;
// export {
//   GET_SEARCH_ITEMS,
//   GET_CART_ITEMS,
//   GET_KITCHEN_CARD_ID,
//   GET_HOLD_KITCHEN_CARD_ID,
//   GET_TOTAL_CALCULATION,
//   GET_TABLE_ITEMS,
//   GET_CUSTOMER_ITEMS,
//   GET_NOTE_ITEMS,
//   GET_MODIFIED_KITCHEN_CARD_ID,
//   GET_ORDER_PRINT_ITEMS,
//   GET_SEARCH_SELETCED_ITEMS,
// };
