import { useMutation } from "@apollo/client";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";

import { CREATE_DELIVERY_METHODS, DELIVER_METHODS } from "./queries";
import usePaginationState from "../../customhooks/usePaginationHook";
import { useNavigate } from "react-router";

const AddOrderTypeComponent: any = Loadable({
  loader: () => import("../../components/OrderType/AddOrderType"),
  loading: DataLoadSpinner,
});

const AddOrderType = () => {
  const navigate = useNavigate();
  const { offset, pageSize } = usePaginationState();

  //PeriodicTasks
  const [createDeliveryMethod, { loading }] = useMutation(
    CREATE_DELIVERY_METHODS,
    {
      refetchQueries: [
        {
          query: DELIVER_METHODS,
          variables: {
            first: pageSize,
            offset: offset,
            title_Icontains: "",
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const handleCreateDeliveryMethod = async (values: any, props: any) => {
    const response = await createDeliveryMethod({
      variables: {
        title: values.title,
        description: values.description,
        amount: values.amount,
        displayInWebsite: values.displayInWebsite,
        isRedirectToCustomers: values.isRedirectToCustomers,
      },
    });
    if (response?.data?.createDeliveryMethod?.success) {
      toast.success("Order Type created  successfully");
      navigate("/order-type");
    } else {
      const errors = backendErrorDisplay(
        response?.data?.createDeliveryMethod?.errors
      );
      toast.error(errors);
    }
  };

  return (
    <div>
      <AddOrderTypeComponent
        handleCreate={handleCreateDeliveryMethod}
        loading={loading}
      />
    </div>
  );
};

export default AddOrderType;
