import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import Loadable from "react-loadable";
import CustomHeaderLayout from "../../layouts/CustomHeaderLayout"
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { POS_SINGLE_KITCHEN_ORDER_LIST } from "../Orders/queries";

const KitchenOrderDetailListComponent: any = Loadable({
  loader: () =>
    import("../../components/Orders/KitchenOrdersList/KitchenOrderDetail"),
  loading: DataLoadSpinner,
});
const DetailRecallKitchenDisplay = () => {
  const params = useParams();
  const { data: kitchenOrderItemsData } = useQuery(
    POS_SINGLE_KITCHEN_ORDER_LIST,
    {
      variables: {
        id: params.id
      }
    },
  );
  
  return (
    <>
      <CustomHeaderLayout layoutType={"kitchenDisplay"} />
      <div className="m-4">
        <KitchenOrderDetailListComponent data={kitchenOrderItemsData?.kitchenOrder} />
      </div>
    </>
  )
}

export default DetailRecallKitchenDisplay