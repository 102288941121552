import React, { useEffect, useState } from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import { useQuery, useLazyQuery } from "@apollo/client";
import { ACCOUNT_TRANSACTION, QUERY_TRANSACTIONS_VIEW } from "../../queries";
import moment from "moment";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import TableLoader from "../../../components/reusable/Loader/TableLoader";

const DiscountExpensesReportComponent: any = Loadable({
  loader: () =>
    import(
      "../../../components/ExpensesReport/DiscountExpenses/DiscountExpenses"
    ),
  loading: () => <TableLoader />,
});

const DiscountExpensesReport = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [getDiscountData, { loading: discountLoading, data: discountData }] =
    useLazyQuery(ACCOUNT_TRANSACTION);

  const {
    data: TransactionData,
    refetch: refetchTransactionData,
    loading: transactionsLoading,
  } = useQuery(QUERY_TRANSACTIONS_VIEW, {
    variables: {
      first: pageSize,
      offset: offset,
      createdGte: startDate
        ? moment(startDate).startOf("day").format("yyyy-MM-DDTHH:mm:ss")
        : null,
      createdLte: endDate
        ? moment(endDate).endOf("day").format("yyyy-MM-DDTHH:mm:ss")
        : null,
      account: discountData?.transactionAccounts?.edges[0].node?.id,
      isVisible: false,
    },
    skip: !discountData,
    fetchPolicy: "network-only",
  });
  const handleFilter = () => {
    refetchTransactionData({
      createdGte: startDate
        ? moment(startDate).startOf("day").format("yyyy-MM-DDTHH:mm:ss")
        : null,
      createdLte: endDate
        ? moment(endDate).endOf("day").format("yyyy-MM-DDTHH:mm:ss")
        : null,
      account: discountData?.transactionAccounts?.edges[0].node?.id,
      isVisible: false,
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getDiscountData({
          variables: {
            slug: "discount",
          },
        });
      } catch (error) {
        console.error("Error fetching discount data:", error);
      }
    };
    fetchData();
  }, [getDiscountData, refetchTransactionData]);

  const data = TransactionData?.transactions?.edges ?? [];

  return (
    <DiscountExpensesReportComponent
      data={data}
      transactionsLoading={transactionsLoading}
      setPageSize={setPageSize}
      pageSize={pageSize}
      setOffset={setOffset}
      offset={offset}
      handleFilter={handleFilter}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      startDate={startDate}
      endDate={endDate}
    />
  );
};

export default DiscountExpensesReport;
