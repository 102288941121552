import { gql } from "@apollo/client";

export const POS_KITCHEN_ORDER_LISTS = gql`
  query (
    $orderStatus: String
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $isBumped: Boolean
  ) {
    kitchenOrders(
      orderStatus: $orderStatus
      first: $first
      after: $after
      before: $before
      offset: $offset
      isBumped: $isBumped
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          created
          orderStatus
          totalItems
          totalPrice
          getInvoiceNumber
          customer {
            pk
            id
            user {
              pk
              id
              fullName
            }
          }
          table {
            pk
            id
            title
          }
          orderMethod {
            title
          }
          kitchenOrderItems {
            pk
            id
            product {
              finalPrice
              taxAmount
            }
            quantity
          }
        }
      }
    }
  }
`;

export const POS_KITCHEN_ORDER_LIST = gql`
  query (
    $orderStatus: String
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $isBumped: Boolean
  ) {
    kitchenOrders(
      orderStatus: $orderStatus
      first: $first
      after: $after
      before: $before
      offset: $offset
      isBumped: $isBumped
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          created
          orderStatus
          getInvoiceNumber
          customer {
            pk
            id
            user {
              pk
              id
              mobileNumber
              profile {
                pk
                id
                email
                firstName
                middleName
                lastName
              }
            }
          }
          table {
            pk
            id
            title
            capacity
            status
          }
          kitchenOrderItems {
            edges {
              node {
                pk
                id
                contentTypeId
                objectId
                quantity
                note
                isBumped
                modifier {
                  id
                  pk
                  contentTypeId
                  quantity
                  product {
                    id
                    pk
                    title
                    finalPrice
                    price {
                      sellingPrice
                      costPrice
                    }
                  }
                }
                product {
                  id
                  contentTypeId
                  pk
                  title
                  productType {
                    id
                    pk
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const POS_SINGLE_KITCHEN_ORDER_LIST = gql`
  query ($id: ID!) {
    kitchenOrder(id: $id) {
      pk
      id
      getInvoiceNumber
      orderStatus
      orderMethod {
        pk
        title
      }
      created
      createdBy {
        id
        pk
        fullName
      }
      note
      customer {
        pk
        id
        user {
          id
          pk
          mobileNumber
          email
          fullName
          profile {
            fullAddress
          }
        }
      }
      table {
        pk
        id
        status
        title
        currentKitchenOrder {
          id
        }
      }
      totalPrice
      totalItems
      kitchenOrderItems {
        id
        pk
        quantity
        taxAmount
        enteredAmount
        note
        modifiers {
          edges {
            node {
              id
              pk
              quantity
              note
              product {
                id
                pk
                title
                taxAmount
                tax {
                  rate
                  isIncluded
                }
                finalPrice
                sellingPrice
              }
            }
          }
        }
        product {
          id
          pk
          title
          taxAmount
          tax {
            rate
            isIncluded
          }
          finalPrice
          sellingPrice
          compound {
            id
            pk
            sellingPrice
            title
          }
        }
      }
    }
  }
`;
