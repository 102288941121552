import { useQuery, useReactiveVar } from "@apollo/client";
import Loadable from "react-loadable";
import { searchItemsVar } from "../../../components/localstate/Cache";
import usePaginationState from "../../../customhooks/usePaginationHook";
import TableLoader from "../../../components/reusable/Loader/TableLoader";
import { POS_SCREEN_ORDERED_ITEMS_LIST } from "../../queries";

const OrderListComponent: any = Loadable({
  loader: () => import("../../../components/Orders/OrderList/OrderList"),
  loading: () => <TableLoader />,
});

type Props = {};

const OrderList = (props: Props) => {
  const searchItems: any = useReactiveVar(searchItemsVar);
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const { loading, data } = useQuery(POS_SCREEN_ORDERED_ITEMS_LIST, {
    variables: {
      first: pageSize,
      offset: offset,
      search: searchItems?.searchInput,
    },
  });
  return (
    <>
      <OrderListComponent
        data={data?.orders ?? []}
        isLoading={loading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setOffset={setOffset}
        offset={offset}
      />
    </>
  );
};

export default OrderList;
