import { useLazyQuery, useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import {
  POS_SCREEN_PRODUCT,
  POS_SCREEN_PRODUCT_CATEGORY,
} from "../Product/queries";
import {
  DETAIL_SALES_REPORT,
  QUERY_RESTAURANT_TABLE,
  QUERY_TRANSACTION_ACCOUNTS,
} from "../queries";
import { QUERY_SETTINGS } from "../Settings/graphql";
import AccessControl from "../../helpers/accessControl";
import UserPermissionRequired from "../Error/UserPermisssionRequired";

const DetailSalesReportComponent: any = Loadable({
  loader: () => import("../../components/Report/DetailSalesReport"),
  loading: DataLoadSpinner,
});

type Props = {};

const DetailSalesReport = (props: Props) => {
  const [categoryDropdown, setCategoryDropdown] = useState<any>([]);
  const [itemsDropDown, setItemsDropDown] = useState<any>([]);
  const [tablesDropDown, setTablesDropDown] = useState<any>([]);
  const [transactionAccountDropdown, setTransactionAccountDropdown] =
    useState<any>([]);

  const [reportOpenState, setReportOpenState] = useState<boolean>(false);
  const [reportData, setReportData] = useState<any>();

  const [localFormState, setLocalFormState] = useState<any>();

  const { data: settingsData } = useQuery(QUERY_SETTINGS);

  // CATEGORY DROPDOWN LIST
  const { data: categoryData } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      level: 0,
      status: "visible",
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    categoryData?.categories?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
        altId: elem?.node?.id,
      })
    );
    setCategoryDropdown(dropdownFormat);
  }, [categoryData]);

  // PROUDUCT DROPDOWN
  const { data: productData } = useQuery(POS_SCREEN_PRODUCT, {
    variables: {
      categoryList:
        localFormState?.category.length > 0 ? localFormState?.category : null,
    },
  });
  useEffect(() => {
    const productList: any = [];
    if (localFormState?.category.length > 0) {
      productData?.products?.edges?.map((elem: any) =>
        productList.push({
          id: elem?.node?.pk,
          title: elem?.node?.title,
          contentType: elem?.node?.contentType,
        })
      );
    }
    setItemsDropDown(productList);
  }, [productData]);

  // RESTAURANT TABLE DROPDOWN
  const { data: RestaurantTableData } = useQuery(QUERY_RESTAURANT_TABLE);
  useEffect(() => {
    const dropdownFormat: any = [];
    RestaurantTableData?.restaurantTables?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.title,
      })
    );
    setTablesDropDown(dropdownFormat);
  }, [RestaurantTableData]);

  // TRANSACTION ACCOUNT DROPDOWN
  const { data: transactionAccount } = useQuery(QUERY_TRANSACTION_ACCOUNTS);
  useEffect(() => {
    const dropdownFormat: any = [];
    transactionAccount?.transactionAccounts?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      })
    );
    setTransactionAccountDropdown(dropdownFormat);
  }, [transactionAccount]);

  // DETAIL SALES REPORT QUERY
  const [generateDetailSalesReport, { loading: detailSalesReportLoading }] =
    useLazyQuery(DETAIL_SALES_REPORT);

  const handleGenerateDetailSalesReport = async (values: any) => {
    const itemsObjList: any[] = [];
    values?.items?.map((elem: any) =>
      itemsObjList.push({
        contentType: productData?.products?.edges[0]?.node?.contentTypeId,
        objectId: elem,
      })
    );
    const response = await generateDetailSalesReport({
      variables: {
        user: values.user,
        category: values.category,
        items: itemsObjList,
        table: values.table,
        paymentMethods: values.modeOfPayment,
        isTaxable: values.isTaxable,
        fromDateTime: values.fromDate,
        toDateTime: values.toDate,
      },
      fetchPolicy: "network-only",
    });
    if (response?.data?.detailSaleReport?.createdOn) {
      setReportData(response?.data?.detailSaleReport);
      setReportOpenState(true);
    } else {
      toast.error("Report data available");
    }
  };

  const handleLocalStateChanges = (value: any, name: string) => {
    setLocalFormState((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleReset = (setFieldValue: any) => {
    setFieldValue("user", []);
    setFieldValue("category", []);
    setFieldValue("items", []);
    setFieldValue("table", []);
    setFieldValue("modeOfPayment", []);
    setFieldValue("isTaxable", []);
    setFieldValue("fromDate", "");
    setFieldValue("toDate", "");
  };

  return (
    <>
      <AccessControl
        allowedPermissions={["can_view_report"]}
        renderNoAccess={<UserPermissionRequired />}
        children={
          <DetailSalesReportComponent
            categoryDropdown={categoryDropdown}
            itemsDropDown={itemsDropDown}
            tablesDropDown={tablesDropDown}
            transactionAccountDropdown={transactionAccountDropdown}
            detailSalesReportLoading={detailSalesReportLoading}
            handleGenerateDetailSalesReport={handleGenerateDetailSalesReport}
            reportOpenState={reportOpenState}
            setReportOpenState={setReportOpenState}
            reportData={reportData}
            currency={settingsData?.basicWebsiteConfigurations?.currency}
            handleReset={handleReset}
            handleLocalStateChanges={handleLocalStateChanges}
            settingsData={settingsData}
          />
        }
      />
    </>
  );
};

export default DetailSalesReport;
