import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { searchItemsVar } from "../../../components/localstate/Cache";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { EDIT_RESTAURANT_TABLE } from "../../mutations";
import {
  GET_RESTAURANT_TABLE,
  QUERY_RESTAURANT_FLOOR,
  QUERY_RESTAURANT_TABLE,
} from "../../queries";

const EditRestaurantTableComponent: any = Loadable({
  loader: () =>
    import("../../../components/RestaurantTable/Table/EditRestaurantTable"),
  loading: DataLoadSpinner,
});

type Props = {};

const EditRestaurantTable = (props: Props) => {
  const navigate = useNavigate();
  const params = useParams();

  const { pageSize, offset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [
    selectedRestaurantTableInitailValues,
    setSelectedRestaurantTableInitailValues,
  ] = useState<any>();

  const { data: selectedRestaurantTable } = useQuery(GET_RESTAURANT_TABLE, {
    variables: {
      id: params.id,
    },
  });

  //List of Floors
  const [floorTypeDropdown, setFloorTypeDropdown] = useState([]);

  const { data: restaurantFloorData } = useQuery(QUERY_RESTAURANT_FLOOR, {
    variables: {
      first: 100,
      offset: 0,
      title_Icontains: "",
    },
  });

  useEffect(() => {
    const dropdownFormat: any = [];
    restaurantFloorData?.floors?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.title,
      })
    );
    setFloorTypeDropdown(dropdownFormat);
  }, [restaurantFloorData]);

  useEffect(() => {
    setSelectedRestaurantTableInitailValues(null);
    if (selectedRestaurantTable) {
      const newInitialValues = {
        title: selectedRestaurantTable?.restaurantTable?.title,
        capacity: selectedRestaurantTable?.restaurantTable?.capacity,
        floor: selectedRestaurantTable?.restaurantTable?.floor?.pk,
        note: selectedRestaurantTable?.restaurantTable?.note,
      };
      setSelectedRestaurantTableInitailValues(newInitialValues);
    }
  }, [selectedRestaurantTable, params.id]);

  const [editRestaurantTable, { loading: editRestaurantTableLoading }] =
    useMutation(EDIT_RESTAURANT_TABLE, {
      refetchQueries: [
        {
          query: QUERY_RESTAURANT_TABLE,
          variables: {
            first: pageSize,
            offset: offset,
            title_Icontains: searchItems?.searchInput,
            subTable_Isnull: true,
            isVisible: true,
          },
        },
      ],
    });

  const handleEditRestaurantTable = async (values: any, props: any) => {
    const response = await editRestaurantTable({
      variables: {
        id: selectedRestaurantTable?.restaurantTable?.pk,
        title: values.title,
        capacity: values.capacity,
        floor: values.floor,
        note: values.note,
      },
    });
    if (response.data?.updateRestaurantTable?.success) {
      props.resetForm();
      navigate("/restaurant-table");
      toast.success("Updated SuccessFully");
    } else {
      let errors = backendErrorDisplay(
        response.data?.updateRestaurantTable?.errors
      );
      props.setErrors(errors);
    }
  };

  return (
    <div>
      <EditRestaurantTableComponent
        handleEditRestaurantTable={handleEditRestaurantTable}
        selectedRestaurantTableInitailValues={
          selectedRestaurantTableInitailValues
        }
        editRestaurantTableLoading={editRestaurantTableLoading}
        floorTypeDropdown={floorTypeDropdown}
      />
    </div>
  );
};

export default EditRestaurantTable;
