import KitchenScreenIcon from "../../src/assets/kitchenscreen.svg"
import AccountIcon from "../../src/assets/account.svg"
import ClockInClockOutIcon from "../assets/clockinclockout.svg"
import { Menu, Transition } from "@headlessui/react";
import isElectron from "../helpers/checkElectron";
import { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "../utils/classNames";
import { removeLocalKey } from "../helpers/sessionKey";
import RefreshButton from "./RefreshButton"
import { useMutation } from "@apollo/client"
import { SYNC_SERVER } from "../pages/mutations"
import { toast } from "react-toastify"
type Props = {
  layoutType?: string;
}
const CustomHeaderLayout = (props: Props) => {

  const { layoutType } = props;
  const navigate = useNavigate()
  const handleCloseElectron = () => {
    removeLocalKey("token")
    const { ipcRenderer } = window.require("electron");
    return ipcRenderer.send("closeApp");
  }
  const handleMinimizeElectron = () => {
    const { ipcRenderer } = window.require("electron");
    return ipcRenderer.send("minimizeApp");
  }
  const handleMaximizeElectron = () => {
    const { ipcRenderer } = window.require("electron");
    return ipcRenderer.send("maximizeApp");
  }
  const handleLogout = () => {
    navigate("/login");
    removeLocalKey("token");
  };
  const handleChangeSyncServer = () => {
    const id = toast.loading("Please wait...")
    //do something else

    if (isElectron()) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send("getQrCodeDomain");
      ipcRenderer.on("getQrCodeDomain", async (event: any, arg: any) => {

        // const response = await syncServer({
        //   variables: {
        //     schemaName: arg
        //   }
        // })

        // if (response?.data?.syncWithServer?.success) {
        //   toast.update(id, { render: response.data?.syncWithServer?.message, type: "success", isLoading: false, autoClose: 500 });
        // } else {
        //   toast.update(id, { render: response.data?.syncWithServer?.message, type: "error", isLoading: false, autoClose: 500 });
        // }
      })
    }
  }
  // const head: string = location?.pathname?.split("/").join("")

  const posLayout = () => {
    switch (layoutType) {
      case 'dashboard':
        return <>
          <div className={"flex justify-between w-full"}>
            <div className="dragging w-[500px] cursor-move"></div>
            <div>
              <div className="ml-4 flex items-center md:ml-6 cursor-pointer">
                {/* <p className="flex uppercase text-white justify-center  text-center items-center tracking-wide ">{head}</p> */}
                <RefreshButton />
                <button className={""} onClick={() => navigate("/attendance")}>
                  <img className="w-7 h-7 m-1 p-1 hover:bg-indigo-800" src={ClockInClockOutIcon} alt="" />
                </button>
                <button onClick={() => navigate("/kitchenDisplay")}>
                  <img className="w-7 h-7 m-1 p-1 hover:bg-indigo-800" src={KitchenScreenIcon} alt="" />
                </button>
                {/* Profile dropdown */}
          
              </div>
            </div>
          </div>

        </>
      case 'kitchenDisplay':
        return <>
          <div className={"flex justify-between w-full"}>
            <div className={"mt-2 space-x-6"}>
              {/* <button className={" cursor-pointer"} onClick={() => navigate("/")}>
                <img className="w-6 h-6 m-0 p-1 hover:bg-indigo-800" src={DIcon} alt="" />
              </button>
              <button className={"cursor-pointer"} onClick={() => navigate(-1)}>
                <img className="w-6 h-6 m-0 p-1 hover:bg-indigo-800 cursor-pointer" src={BackwardButton} alt="" />
              </button>
              <button className={" cursor-pointer"} onClick={() => navigate(+1)}>
                <img className="w-6 h-6 m-0 p-1 hover:bg-indigo-800 cursor-pointer" src={ForwardButton} alt="" />
              </button> */}
            </div>
            <div>
              <p
                className="p-[1px] flex items-center justify-center font-poppins font-semibold text-[16px] text-[#FFFFFF] text-center leading-[35px]">
                Kitchen Display
              </p>
            </div>
            <div>
              <div className="w-full flex items-center">
                <div className={"flex"}>
                  <RefreshButton />
                  <Menu as="div" className="ml-0 z-50">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button
                          // className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            {/* <span className="sr-only">Open user menu</span> */}
                            <img
                              className="h-7 w-7 mt-0 pt-2 text-center hover:bg-indigo-800"
                              src={AccountIcon}
                              alt=""
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <Menu.Item>
                              <Link
                                to={"/profile"}
                                className={classNames(
                                  "block px-4 py-2 text-sm text-gray-700",
                                )}
                              >
                                Profile
                              </Link>
                            </Menu.Item>
                            {/* @ts-ignore */}

                            <Menu.Item onClick={() => navigate('/')}>
                              <Link
                                to={"/"}
                                className={classNames(
                                  "block px-4 py-2 text-sm text-gray-700",
                                )}
                              >
                                <>Dashboard</>
                              </Link>
                            </Menu.Item>
                            {/* @ts-ignore */}

                            <Menu.Item onClick={handleLogout}>
                              <Link
                                to={"/login"}
                                className={classNames(
                                  "block px-4 py-2 text-sm text-gray-700",
                                )}
                              >
                                <>Logout</>
                              </Link>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            </div>
          </div>


          {/* Profile dropdown */}
        </>
      case 'offlinePosDisplay':
        return <>
          <div className={"flex justify-between w-full"}>
            <div className={"mt-2 space-x-6"}>
              {/* <button className={" cursor-pointer"} onClick={() => navigate("/")}>
                <img className="w-6 h-6 m-0 p-1 hover:bg-indigo-800" src={DIcon} alt="" />
              </button> */}
            </div>
            <div>
              <p
                className="p-[1px] flex items-center justify-center font-poppins font-semibold text-[16px] text-[#FFFFFF] text-center leading-[35px]">
              </p>
            </div>
            <div>
              <div className="w-full flex items-center">
                <div className={"flex"}>
                  <RefreshButton />
                </div>
                <button className={""} onClick={() => navigate("/attendance")}>
                  <img className="w-7 h-7 m-1 p-1 hover:bg-indigo-800" src={ClockInClockOutIcon} alt="" />
                </button>
              </div>
            </div>
          </div>
        </>
      default:
        break;
    }
  }

  return (
    <div className={` ${layoutType === "posScreen" ? 'bg-[#4338CA]' : 'bg-[#4338CA]'} w-[100%]`}>
      {isElectron() ?
        <div className="w-[99%] m-auto flex justify-end ">
          {posLayout()}
          {/* <button onClick={() => handleMinimizeElectron()}>
            <img className="w-6 h-6 m-1 p-1 hover:bg-indigo-800" src={MinimizeIcon} alt="" />
          </button>
          <button onClick={() => handleMaximizeElectron()}>
            <img className="w-6 h-6 m-1 p-1 hover:bg-indigo-800" src={MaximizeIcon} alt="" />

          </button>
          <button onClick={() => handleCloseElectron()}>
            <img className="w-6 h-6 m-1 p-1 hover:bg-red-600" src={CloseIcon} alt="" />
          </button> */}
        </div>
        : null}
    </div>
  )
}

export default CustomHeaderLayout