import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { searchItemsVar } from "../../components/localstate/Cache";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../customhooks/usePaginationHook";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import {
  EDIT_RECEIPT_SETTING,
  EDIT_TERMINAL,
  EDIT_SCALE_SETTING,
  EDIT_SCREEN_SAVER,
  EDIT_CDU_SETTING,
  DELETE_SCREEN_SAVE_IMAGE,
} from "../mutations";
import { GET_TERMINAL, QUERY_PRINTER, QUERY_TERMINAL } from "../queries";


const EditTerminalComponent: any = Loadable({
  loader: () => import('../../components/Terminal/EditTerminal'),
  loading: DataLoadSpinner,
});
type Props = {};

const EditTerminal = (props: Props) => {
  const navigate = useNavigate();
  const params = useParams();

  const { pageSize, offset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  //Single terminal inital data
  const { data: selectedTerminal, loading } = useQuery(GET_TERMINAL, {
    variables: {
      id: params.id,
    },
    fetchPolicy: "network-only",
  });
  //PrinterList
  const [printerTypeDropdown, setPrinterTypeDropdown] = useState([]);

  const { data: printerData } = useQuery(QUERY_PRINTER, {
    variables: {
      first: 100,
      offset: 0,
      name_Icontains: "",
    },
  });

  useEffect(() => {
    const dropdownFormat: any = [];
    printerData?.printers?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      }),
    );
    setPrinterTypeDropdown(dropdownFormat);
  }, [printerData]);

  const [editTerminal, { loading: editTerminalLoading }] = useMutation(
    EDIT_TERMINAL,
    {
      refetchQueries: [
        {
          query: QUERY_TERMINAL,
          variables: {
            first: pageSize,
            offset: offset,
            name_Icontains: searchItems?.searchInput,
          },
        },
      ],
    },
  );

  const [editReciptSetting] = useMutation(EDIT_RECEIPT_SETTING, {
    refetchQueries: [
      {
        query: GET_TERMINAL,
        variables: {
          id: params.id,
        },
      },
    ],
  });
  const [editCDUSetting] = useMutation(EDIT_CDU_SETTING, {
    refetchQueries: [
      {
        query: GET_TERMINAL,
        variables: {
          id: params.id,
        },
      },
    ],
  });
  const [editScreenSaverSetting] = useMutation(EDIT_SCREEN_SAVER, {
    refetchQueries: [
      {
        query: GET_TERMINAL,
        variables: {
          id: params.id,
        },
      },
    ],
  });
  const [deleteScreenSaverImage] = useMutation(DELETE_SCREEN_SAVE_IMAGE, {
    refetchQueries: [
      {
        query: GET_TERMINAL,
        variables: {
          id: params.id,
        },
      },
    ],
  });
  const [editScaleSetting, { loading: editScaleSettingLoading }] = useMutation(
    EDIT_SCALE_SETTING,
    {
      refetchQueries: [
        {
          query: GET_TERMINAL,
          variables: {
            id: params.id,
          },
        },
      ],
    },
  );
  const handleEditTerminal = async (values: any, props: any) => {
    const response = await editTerminal({
      variables: {
        id: selectedTerminal?.terminal?.pk,
        name: values.name,
        printer: values.printer,
        note: values.note,
      },
    });
    if (response.data?.updateTerminal?.success) {
      props.resetForm();
      navigate("/terminal");
      toast.success("Updated SuccessFully");
    } else {
      let errors = backendErrorDisplay(response.data?.updateTerminal?.errors);
      props.setErrors(errors);
    }
  };

  const handleReciptSettingUpdate = async (e: any, name: any) => {
    switch (name) {
      case "netAmount":
        await editReciptSetting({
          variables: {
            id: selectedTerminal?.terminal?.terminalsetting.pk,
            netAmount: e,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_TERMINAL,
              variables: {
                id: params.id,
              },
            },
          ],
        });
        break;
      case "amountAfterDiscount":
        await editReciptSetting({
          variables: {
            id: selectedTerminal?.terminal?.terminalsetting.pk,
            amountAfterDiscount: e,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_TERMINAL,
              variables: {
                id: params.id,
              },
            },
          ],
        });
        break;
      case "gst":
        await editReciptSetting({
          variables: {
            id: selectedTerminal?.terminal?.terminalsetting.pk,
            gst: e,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_TERMINAL,
              variables: {
                id: params.id,
              },
            },
          ],
        });
        break;
      case "surCharge":
        await editReciptSetting({
          variables: {
            id: selectedTerminal?.terminal?.terminalsetting.pk,
            surCharge: e,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_TERMINAL,
              variables: {
                id: params.id,
              },
            },
          ],
        });
        break;
      case "serviceCharge":
        await editReciptSetting({
          variables: {
            id: selectedTerminal?.terminal?.terminalsetting.pk,
            serviceCharge: e,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_TERMINAL,
              variables: {
                id: params.id,
              },
            },
          ],
        });
        break;
      case "eftposCharge":
        await editReciptSetting({
          variables: {
            id: selectedTerminal?.terminal?.terminalsetting.pk,
            eftposCharge: e,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_TERMINAL,
              variables: {
                id: params.id,
              },
            },
          ],
        });
        break;
      case "grossAmount":
        await editReciptSetting({
          variables: {
            id: selectedTerminal?.terminal?.terminalsetting.pk,
            grossAmount: e,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_TERMINAL,
              variables: {
                id: params.id,
              },
            },
          ],
        });
        break;
      case "openPopupAfterRemotePrint":
        await editReciptSetting({
          variables: {
            id: selectedTerminal?.terminal?.terminalsetting.pk,
            openPopupAfterRemotePrint: e,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_TERMINAL,
              variables: {
                id: params.id,
              },
            },
          ],
        });
        break;
      case "serviceChargeToggleCheckbox":
        await editReciptSetting({
          variables: {
            id: selectedTerminal?.terminal?.terminalsetting.pk,
            serviceChargeToggleCheckbox: e,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_TERMINAL,
              variables: {
                id: params.id,
              },
            },
          ],
        });
        break;
      case "compulsoryKitchenPrint":
        await editReciptSetting({
          variables: {
            id: selectedTerminal?.terminal?.terminalsetting.pk,
            compulsoryKitchenPrint: e,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_TERMINAL,
              variables: {
                id: params.id,
              },
            },
          ],
        });
        break;
      case "isOpenPopupAfterPayment":
        await editReciptSetting({
          variables: {
            id: selectedTerminal?.terminal?.terminalsetting.pk,
            isOpenPopupAfterPayment: e,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_TERMINAL,
              variables: {
                id: params.id,
              },
            },
          ],
        });
        break;
    }
  };

  const handleCDUSettingUpdate = async (e: any, name: any) => {
    switch (name) {
      case "isActive":
        await editCDUSetting({
          variables: {
            id: selectedTerminal?.terminal?.terminalsetting?.pk,
            isActive: e,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_TERMINAL,
              variables: {
                id: params.id,
              },
            },
          ],
        });
        break;
      case "isScreenSaveOnly":
        await editCDUSetting({
          variables: {
            id: selectedTerminal?.terminal?.terminalsetting?.pk,
            isScreenSaveOnly: e,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_TERMINAL,
              variables: {
                id: params.id,
              },
            },
          ],
        });
        break;
      case "slideDuration":
        await editCDUSetting({
          variables: {
            id: selectedTerminal?.terminal?.terminalsetting?.pk,
            slideDuration: e,
          },
          fetchPolicy: "network-only",
          refetchQueries: [
            {
              query: GET_TERMINAL,
              variables: {
                id: params.id,
              },
            },
          ],
        });
        break;
    }
  };

  const handleScreenSaverImageUpdate = async (value: any) => {
    const response = await editScreenSaverSetting({
      variables: {
        id: selectedTerminal?.terminal?.terminalsetting.pk,
        image: value,
      },
    });
  };

  const handleScaleSettingUpdate = async (values: any, props: any) => {
    const response = await editScaleSetting({
      variables: {
        id: selectedTerminal?.terminal?.terminalsetting.pk,
        isActiveScale: values.isActiveScale,
        scaleBaudRate: values.scaleBaudRate,
        scaleDateBits: values.scaleDateBits,
        scaleParity: values.scaleParity,
        scalePort: values.scalePort,
        scaleStopBits: values.scaleStopBits,
      },
    });
    if (response.data?.updateTerminalSetting?.success) {
      props.resetForm();
      navigate("/terminal");
      toast.success("Updated SuccessFully");
    } else {
      let errors = backendErrorDisplay(response.data?.updateTerminal?.errors);
      props.setErrors(errors);
    }
  };

  const handleDeleteScreenSave = async (id: any) => {
    const response = await deleteScreenSaverImage({
      variables: {
        id: id,
      },
    });

    if (response.data?.deleteScreenSaverImage?.success) {
      toast.success(response.data?.deleteScreenSaverImage?.message);
    } else {
      toast.error(
        response.data?.deleteScreenSaverImage?.errors?.nonFieldErrors[0]
          ?.message,
      );
    }
  };
  return (
    <>

      <EditTerminalComponent
        handleEditTerminal={handleEditTerminal}
        selectedTerminal={selectedTerminal}
        editTerminalLoading={editTerminalLoading}
        printerTypeDropdown={printerTypeDropdown}
        handleReciptSettingUpdate={handleReciptSettingUpdate}
        handleScaleSettingUpdate={handleScaleSettingUpdate}
        handleCDUSettingUpdate={handleCDUSettingUpdate}
        editScaleSettingLoading={editScaleSettingLoading}
        handleScreenSaverImageUpdate={handleScreenSaverImageUpdate}
        handleDeleteScreenSave={handleDeleteScreenSave}
        loading={loading}
      />
    </>
  );
};

export default EditTerminal;
