import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loadable from "react-loadable";
import { useNavigate } from "react-router-dom";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import { QUERY_TAX, QUERY_UNIT } from "../../queries";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { searchItemsVar } from "../../../components/localstate/Cache";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import { POS_SCREEN_PRODUCT_CATEGORY, PRODUCT_LIST } from "../queries";
import { POS_SCREEN_CREATE_PRODUCT } from "../mutation";
import Spinner from "../../../components/reusable/Spinner";

const AddModifierComponent: any = Loadable({
  loader: () => import("../../../components/Product/Modifier/AddModifier"),
  loading: DataLoadSpinner,
});

type Props = {};

const AddModifier = (props: Props) => {
  const [categoryDropdown, setCategoryDropdown] = useState([]);
  const [taxTypeDropdown, setTaxTypeDropdown] = useState([]);

  const navigate = useNavigate();

  const searchItems: any = useReactiveVar(searchItemsVar);
  const { offset, pageSize } = usePaginationHook();

  //Product Categories
  const { data: modifierCategoryData } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      first: 100,
      level: 0,
      status: "modifier",
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    modifierCategoryData?.categories?.edges?.map((elem: any) => {
      return dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      });
    });
    setCategoryDropdown(dropdownFormat);
  }, [modifierCategoryData]);

  const { data: unitData, loading: unitDataLoading } = useQuery(QUERY_UNIT, {
    variables: {
      first: 100,
    },
  });
  const [unitDropdown, setUnitDropdown] = useState([
    {
      id: unitData?.units[0]?.node.pk,
      title: unitData?.units[0]?.node.title,
    },
  ]);

  useEffect(() => {
    const dropdownFormat: any = [];
    unitData?.units?.edges.map((elem: any) => {
      return dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.title,
      });
    });
    setUnitDropdown(dropdownFormat);
  }, [unitData]);

  //Tax List
  const { data: taxData, loading: taxDataLoading } = useQuery(QUERY_TAX, {
    variables: {
      first: 100,
      offset: 0,
      title_Icontains: "",
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    if (taxData) {
      taxData?.taxes?.edges?.map((elem: any) =>
        dropdownFormat.push({
          id: elem?.node?.pk,
          title: elem?.node?.title,
          rate: elem?.node?.rate,
          isIncluded: elem?.node?.isIncluded,
        })
      );
      dropdownFormat.push({
        id: 0,
        title: "GST Free",
        rate: 0,
        isIncluded: true,
      });
    }
    setTaxTypeDropdown(dropdownFormat);
  }, [taxData]);

  const [modifierCreate, { loading: modifierCreateLoading }] = useMutation(
    POS_SCREEN_CREATE_PRODUCT,
    {
      refetchQueries: [
        {
          query: PRODUCT_LIST,
          variables: {
            first: pageSize,
            offset: offset,
            title_Icontains: searchItems?.searchInput,
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const handleCreateModifier = async (values: any, props: any) => {
    const response = await modifierCreate({
      variables: {
        title: values?.title,
        category: values?.category,
        unit: values?.unit,
        tax: values?.taxType ?? taxTypeDropdown[0]?.id,
        isModifier: true,
        printers: [],
        modifierCategories: [],
        image: values?.image,
        sellingPrice: values?.sellingPrice,
        costPrice: values?.costPrice,
        shortDesc: values?.description,
      },
    });

    if (response?.data?.createProduct?.success) {
      toast.success("Modifier Created successfully");
      navigate(`/modifier`);
    } else {
      const errors = backendErrorDisplay(response?.data?.createProduct?.errors);
      props.setErrors(errors);
    }
  };
  return (
    <>
      {taxDataLoading && unitDataLoading ? (
        <Spinner />
      ) : (
        <AddModifierComponent
          unitData={unitDropdown}
          handleCreateModifier={handleCreateModifier}
          categoryDropdown={categoryDropdown}
          modifierCreateLoading={modifierCreateLoading}
          taxTypeDropdown={taxTypeDropdown}
        />
      )}
    </>
  );
};

export default AddModifier;
