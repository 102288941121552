import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useRef } from "react";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import { searchItemsVar } from "../../components/localstate/Cache";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../customhooks/usePaginationHook";
import { DELETE_PRINTER } from "../mutations";
import { QUERY_PRINTER } from "../queries";
import GET_RECEIPT from "../ReceiptSetting/queries";
import { QUERY_SETTINGS } from "../Settings/graphql";
import TableLoader from "../../components/reusable/Loader/TableLoader";

const PrinterListComponent: any = Loadable({
  loader: () => import("../../components/Printer/PrinterList"),
  loading: () => <TableLoader />,
});

type Props = {};

const Printer = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();

  const searchItems: any = useReactiveVar(searchItemsVar);

  const { data: basicSetting } = useQuery(QUERY_SETTINGS);
  const { data: receiptData } = useQuery(GET_RECEIPT);

  const [deletePrinter] = useMutation(DELETE_PRINTER, {
    refetchQueries: [
      {
        query: QUERY_PRINTER,
        variables: {
          first: pageSize,
          offset: offset,
          name_Icontains: searchItems?.searchInput,
        },
      },
    ],
  });

  const { data: printerData, loading: printerLoading } = useQuery(
    QUERY_PRINTER,
    {
      variables: {
        first: pageSize,
        offset: offset,
        name_Icontains: searchItems?.searchInput,
      },
    }
  );

  const handleDeletePrinter = async (id: string) => {
    const response = await deletePrinter({
      variables: {
        objectId: id,
      },
    });
    if (response.data?.deletePrinter?.success) {
      toast.success(response.data?.deletePrinter?.message);
    } else {
      toast.error(
        response.data?.deletePrinter?.errors?.nonFieldErrors[0]?.message
      );
    }
  };

  return (
    <>
      <PrinterListComponent
        data={printerData?.printers}
        printerLoading={printerLoading}
        handleDeletePrinter={handleDeletePrinter}
        pageSize={pageSize}
        setPageSize={setPageSize}
        offset={offset}
        setOffset={setOffset}
        basicSetting={basicSetting}
        receiptData={receiptData}
      />
    </>
  );
};

export default Printer;
