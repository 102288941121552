import { gql } from "@apollo/client";

export const QUERY_SETTINGS = gql`
  query QUERY_SETTINGS {
    basicWebsiteConfigurations {
      id
      pk
      title
      timezone
      currency
      isStockMaintain
      printProductByCategoryOrder
      serviceCharge {
        title
        pk
      }
      country
      faviconIcon
      logo
      isAutoPrint
      isProductWisePrint
      noOfColumns
      qrOrderUrl
    }
  }
`;
export const BARCODE_SETTING = gql`
  query {
    barcodeSetting {
      id
      pk
      content
    }
  }
`;
export const UPDATAE_CREATE_BARCODE_CONTENT = gql`
  mutation ($content: JSONString) {
    createUpdateBarcodeContent(input: { content: $content }) {
      errors
      success
      barcode {
        content
      }
    }
  }
`;
export const DOWNLOAD_BARCODE = gql`
  mutation ($productId: String!) {
    downloadBarcode(input: { productId: $productId }) {
      success
      errors
      barcodeHtmlString
    }
  }
`;

export const REGISTERED_TASKS = gql`
  query {
    registeredTasks
  }
`;

export const SETUP_LINKLY_DEVICE = gql`
  mutation ($username: String!, $password: String!, $pairCode: String!) {
    setupLinklyDevice(
      input: { username: $username, password: $password, pairCode: $pairCode }
    ) {
      errors
      success
      message
    }
  }
`;
