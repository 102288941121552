import { gql } from "@apollo/client";

export const CREATE_CUSTOMER = gql`
  mutation (
    $fullName: String
    $email: String
    $mobileNumber: String
    $fullAddress: String
    $trackingNumber: String
  ) {
    createCustomer(
      input: {
        fullName: $fullName
        email: $email
        mobileNumber: $mobileNumber
        fullAddress: $fullAddress
        trackingNumber: $trackingNumber
      }
    ) {
      success
      errors
      customer {
        user {
          email
          mobileNumber
          fullName
        }
        id
        pk
      }
    }
  }
`;

export const CUSTOMER_LIST = gql`
  query customers(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $search: String
  ) {
    customers(
      first: $first
      after: $after
      before: $before
      offset: $offset
      search: $search
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          outstandingBalance
          todayCredit
          trackingNumber
          user {
            id
            pk
            email
            mobileNumber
            fullName
          }
        }
      }
    }
  }
`;

export const GET_CUSTOMER_CREDIT_AMOUNT = gql`
  query ($id: ID!) {
    customer(id: $id) {
      id
      pk
      creditAmount
      outstandingBalance
      user {
        id
        pk
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      outstandingBalance
      trackingNumber
      user {
        pk
        email
        mobileNumber
        fullName
        email
        profile {
          pk
          id
          fullAddress
          dobEnglish
        }
      }
      id
      pk
    }
  }
`;
