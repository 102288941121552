import { useMutation } from "@apollo/client";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import { CREATE_PRODUCT_PRINT_ORDERS } from "../mutations";
import { PRODUCT_PRINT_ORDERS } from "../queries";

const AddProductPrintOrdersComponent: any = Loadable({
  loader: () => import("../../components/ProductPrintOrders/AddProductPrintOrders"),
  loading: DataLoadSpinner,
});

type Props = {
  setAddPopup:any
};

const AddProductPrintOrders = (props: Props) => {
  const {setAddPopup} = props

  const [createProductPrintOrders, { loading }] = useMutation(CREATE_PRODUCT_PRINT_ORDERS, {
    refetchQueries: [
      {
        query: PRODUCT_PRINT_ORDERS,
      },
    ],
  });

  const handleAddProductPrintOrders = async (values: any, props: any) => {
    const response = await createProductPrintOrders({
      variables: {
        name: values.name,
        productOrder: "5"
      },
    });
    if (response.data?.createProductPrintOrder?.success) {
      props.resetForm();
      setAddPopup(false)
      toast.success("ProductPrintOrder Created SuccessFully");
    } else {
      let errors = backendErrorDisplay(response.data?.createProductPrintOrder?.errors);
      props.setErrors(errors);
    }
  };

  return (
      <AddProductPrintOrdersComponent 
        handleAddProductPrintOrders={handleAddProductPrintOrders}
        loading={loading}
      />
  );
};

export default AddProductPrintOrders;
