import { useQuery } from "@apollo/client";
import Loadable from "react-loadable";
import { QUERY_OBJECT_COUNT } from "../queries";
import AccessControl from "../../helpers/accessControl";
import UserSupport from "../Error/UserSupport";
import SalesLoaderLayout from "../../components/reusable/Loader/SalesLoaderLayout";

const DashboardListComponent: any = Loadable({
  loader: () => import("../../components/Dashboard/DashboardItems"),
  loading: () => <SalesLoaderLayout />,
});

const SalesReport: React.FC<any> = () => {
  const { data: salesData } = useQuery(QUERY_OBJECT_COUNT, {
    fetchPolicy: "network-only",
  });
  return (
    <AccessControl
      allowedPermissions={["can_view_report"]}
      renderNoAccess={<UserSupport />}
      children={<DashboardListComponent salesData={salesData} />}
    />
  );
};

export default SalesReport;
