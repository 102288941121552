import Loadable from "react-loadable";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";

import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { QUERY_TAX } from "../queries";
import { useNavigate } from "react-router";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import { POS_SCREEN_COMPOUND_PRODUCT } from "../Product/queries";
import { POS_SCREEN_CREATE_PROMOTION } from "../Product/mutation";
import Spinner from "../../components/reusable/Spinner";

const AddPromotionComponent: any = Loadable({
  loader: () =>
    import("../../components/Promotion/AddPromotion"),
  loading: DataLoadSpinner,
});

type Props = {};

const AddPromotion = (props: Props) => {
  let navigate = useNavigate();
  // const { offset, pageSize } = usePaginationHook();
  const [selectedProductItems, setSelectedProductItems] = useState<any>([])
  const [taxTypeDropdown, setTaxTypeDropdown] = useState<any>([])


  //Tax List
  const { data: taxData, loading: taxDataLoading } = useQuery(QUERY_TAX, {
    variables: {
      first: 100,
      offset: 0,
      title_Icontains: "",
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    if (taxData) {
      taxData?.taxes?.edges?.map((elem: any) =>
        dropdownFormat.push({
          id: elem?.node?.pk,
          title: elem?.node?.title,
          rate: elem?.node?.rate,
          isIncluded: elem?.node?.isIncluded,
        }),
      );
      dropdownFormat.push({
        id: 0,
        title: "GST Free",
        rate: 0,
        isIncluded: true,
      });
    }
    setTaxTypeDropdown(dropdownFormat);
  }, [taxData]);


  const [addPromotion, { loading: addPromotionLoading }] = useMutation(
    POS_SCREEN_CREATE_PROMOTION,
    {
      refetchQueries: [
        {
          query: POS_SCREEN_COMPOUND_PRODUCT,
          variables: {
            first: 10,
            offset: 0,
            search: "",
            compound_Isnull: "true",
          },
        },
      ],
    },
  );

  const handleAddPromotion = async (values: any, props: any) => {
    console.log(values, "asas")
    if (selectedProductItems?.length > 0) {
      let filteredPromo: any = [];
      selectedProductItems?.map((item: any) => filteredPromo.push(item?.pk));
      const response = await addPromotion({
        variables: {
          title: values?.title,
          sellingPrice: values?.sellingPrice,
          compound: filteredPromo.join(),
          tax: values?.taxType ?? null,
        },
      });
      if (response.data?.createProduct?.success) {
        navigate("/promotions");
        toast.success("Added  Successfully");
      } else {
        let errors = backendErrorDisplay(response.data?.createProduct?.errors);
        props.setErrors(errors);
      }
    } else {
      toast.info("Select Products");
    }
  };



  return (
    <>
      {taxDataLoading ? <Spinner /> :
        <AddPromotionComponent
          handleAdd={handleAddPromotion}
          loading={false}
          selectedProductItems={selectedProductItems}
          setSelectedProductItems={setSelectedProductItems}
          taxTypeDropdown={taxTypeDropdown} />
      }
    </>
  );
};

export default AddPromotion;
