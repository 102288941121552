import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type Props = {
  count?: number;
  showLeftBar?: boolean;
  showTotal?: boolean;
  showRightBar?: boolean;
};

const TableLoader = (props: Props) => {
  const {
    count,
    showLeftBar = true,
    showTotal = false,
    showRightBar = true,
  } = props;

  return (
    <div className="">
      <div className="flex justify-between w-full mb-2">
        {showLeftBar ? <Skeleton height={30} width={200} /> : <div></div>}
        {showRightBar ? <Skeleton height={30} width={200} /> : <div></div>}
      </div>
      <Skeleton height={40} />
      <Skeleton height={50} count={count ?? 10} />
      <div className="flex justify-between w-full my-2">
        <Skeleton height={30} width={100} />
        {showTotal ? <Skeleton height={30} width={100} /> : ""}
        <Skeleton height={30} width={100} />
      </div>
    </div>
  );
};

export default TableLoader;
