//@ts-nocheck
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import Loadable from "react-loadable";
import usePaginationState from "../../customhooks/usePaginationHook";
import { useQuery, useReactiveVar } from "@apollo/client";
import { PRODUCTWISE_REPORT } from "../queries";
import { useState } from "react";
import moment from "moment";
import AccessControl from "../../helpers/accessControl";
import UserPermissionRequired from "../Error/UserPermisssionRequired";
import { searchItemsVar } from "../../components/localstate/Cache";
import TableLoader from "../../components/reusable/Loader/TableLoader";

const ReportComponent: any = Loadable({
  loader: () => import("../../components/Reports/ReportList"),
  loading: () => <TableLoader />,
});
type Props = {};

const ProductWiseReport = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();

  const [startDate, setStartDate] = useState(
    moment(new Date()).startOf("day").format("yyyy-MM-DDTHH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).endOf("day").format("yyyy-MM-DDTHH:mm:ss")
  );
  const searchItems: any = useReactiveVar(searchItemsVar);

  const { data, loading: productWiseLoading } = useQuery(PRODUCTWISE_REPORT, {
    variables: {
      fromDateTime: startDate,
      toDateTime: endDate,
      terminal: [],
      query: searchItems?.searchInput ?? "",
    },
    fetchPolicy: "network-only",
  });

  return (
    <AccessControl
      allowedPermissions={["can_view_report"]}
      renderNoAccess={<UserPermissionRequired />}
      children={
        <ReportComponent
          data={data?.detailProductWiseReport ?? []}
          productWiseLoading={productWiseLoading}
          pageSize={pageSize}
          setPageSize={setPageSize}
          offset={offset}
          setOffset={setOffset}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      }
    />
  );
};

export default ProductWiseReport;
