import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import moment from "moment";
import { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import {
  customerCache,
  CustomerItemsVar,
} from "../../../components/localstate/Cache";
import { GET_CUSTOMER_ITEMS } from "../../../components/localstate/Cart";

import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import useCommonState from "../../../customhooks/useCommonState";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import usePosScreenHandleClear from "../../../customhooks/usePosScreenHandleClear";

import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import {
  ACCEPT_PAYMENT,
  ADD_EXPENSES,
  MAKE_SUPPLIER_PAYMENT,
} from "../../mutations";
import {
  QUERY_SUPPLIER,
  QUERY_TRANSACTIONS_VIEW,
  QUERY_TRANSACTION_ACCOUNTS,
} from "../../queries";
import { QUERY_SETTINGS } from "../../Settings/graphql";
import { CUSTOMER_LIST, GET_CUSTOMER_CREDIT_AMOUNT } from "../Customer/grapql";
import AccessControl from "../../../helpers/accessControl";
import UserPermissionRequired from "../../Error/UserPermisssionRequired";
import TableLoader from "../../../components/reusable/Loader/TableLoader";
const TransactionsComponent: any = Loadable({
  loader: () =>
    import("../../../components/Account/Transactions/TransactionsList"),
  loading: () => <TableLoader />,
});
const Transactions = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();

  const {
    transactionStartDate,
    setTransactionStartDate,
    transactionEndDate,
    setTransactionEndDate,
  } = useCommonState();

  const [selecteFilter, setSelecteFilter] = useState<any>("");
  const customerItems: any = useReactiveVar(CustomerItemsVar);
  const [selected, setSelected] = useState({
    id: "",
    pk: "",
    name: "",
  });
  const { handleClearPosScreen } = usePosScreenHandleClear();
  let refetchVariable: any = {
    first: pageSize,
    offset: offset,
    createdGte: transactionStartDate?.startDate
      ? moment(transactionStartDate?.startDate)
          .startOf("day")
          .format("yyyy-MM-DDTHH:mm:ss")
      : null,
    createdLte: transactionStartDate?.endDate
      ? moment(transactionStartDate?.endDate)
          .endOf("day")
          .format("yyyy-MM-DDTHH:mm:ss")
      : null,
    account: selecteFilter ?? "",
    account_IsVisible: true,
  };

  const [customerCreditAmount, { data: selectedCustomer }] = useLazyQuery(
    GET_CUSTOMER_CREDIT_AMOUNT
  );

  // Used for customer cache
  useEffect(() => {
    if (selected?.id) {
      customerCreditAmount({
        variables: {
          id: selected?.id,
        },
      });
      let customerItem = {
        id: selected.id,
        name: selected.name,
        pk: selected.pk,
      };
      customerCache.writeQuery({
        query: GET_CUSTOMER_ITEMS,
        data: {
          customerItems: CustomerItemsVar(customerItem),
        },
      });
    }
  }, [selected]);

  const {
    data,
    refetch,
    loading: transactionLoading,
  } = useQuery(QUERY_TRANSACTIONS_VIEW, {
    variables: refetchVariable,
  });

  const {
    data: transactionsByDateViewData,
    refetch: transactionsByDateViewDataRefetch,
  } = useQuery(QUERY_TRANSACTIONS_VIEW, {
    variables: refetchVariable,
  });

  const { data: transactionAccountsData } = useQuery(
    QUERY_TRANSACTION_ACCOUNTS,
    {
      variables: {
        isVisible: true,
      },
    }
  );

  // FOR TRANSACTION ACCOUNT DROP DOWN
  const transactionAccountsList: any = [];
  transactionAccountsData?.transactionAccounts?.edges?.map((elem: any) => {
    return transactionAccountsList.push({
      id: elem?.node?.pk,
      title: elem?.node?.name,
    });
  });

  //  FOR TRANSACTION ACCOUNT FILTER
  const transactionAccountsFilter: any = [];
  transactionAccountsData?.transactionAccounts?.edges?.map((elem: any) => {
    return transactionAccountsFilter.push({
      value: elem?.node?.id,
      label: elem?.node?.name,
    });
  });

  const [expenses, setExpenses] = useState<boolean>(false);
  const [openAcceptPayment, setOpenAcceptPayment] = useState<boolean>(false);
  const [makePayment, setMakePayment] = useState<boolean>(false);

  const { data: supplierData } = useQuery(QUERY_SUPPLIER, {
    variables: {
      first: 100,
    },
  });

  // SUPPLIER DROPDOWN FORMAT
  const supplierList: any = [];
  supplierData?.suppliers?.edges?.map((elem: any) => {
    return supplierList.push({
      id: elem?.node?.user?.pk,
      title: elem?.node?.user?.profile?.firstName,
    });
  });

  const { data: customerData } = useQuery(CUSTOMER_LIST, {
    variables: {
      first: 100,
    },
  });

  // CUSTOMER DROPDOWN FORMAT
  const customerList: any = [];
  customerData?.customers?.edges?.map((elem: any) => {
    return customerList.push({
      id: elem?.node?.user?.pk,
      title: elem?.node?.user?.profile?.firstName,
    });
  });

  const [makeSupplierPayment, { loading: makeSupplierPaymentLoading }] =
    useMutation(MAKE_SUPPLIER_PAYMENT, {
      refetchQueries: [
        {
          query: QUERY_TRANSACTIONS_VIEW,
          variables: refetchVariable,
        },
        {
          query: QUERY_TRANSACTIONS_VIEW,
          variables: refetchVariable,
        },
      ],
      awaitRefetchQueries: true,
    });

  const [addExpenses, { loading: addExpensesLoading }] = useMutation(
    ADD_EXPENSES,
    {
      refetchQueries: [
        {
          query: QUERY_TRANSACTIONS_VIEW,
          variables: refetchVariable,
        },
        {
          query: QUERY_TRANSACTIONS_VIEW,
          variables: refetchVariable,
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [acceptPayment, { loading: acceptPaymentLoading }] = useMutation(
    ACCEPT_PAYMENT,
    {
      refetchQueries: [
        {
          query: QUERY_TRANSACTIONS_VIEW,
          variables: refetchVariable,
        },
        {
          query: QUERY_TRANSACTIONS_VIEW,
          variables: refetchVariable,
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const handleAddExpenses = async (values: any, props: any) => {
    const response = await addExpenses({
      variables: {
        amount: values.amount,
        account: values.account,
        note: values.note,
        transactionSource: "pos",
      },
    });
    if (response?.data?.addExpense?.success) {
      setExpenses(false);
      toast.success(response?.data?.addExpense?.message);
      transactionsByDateViewDataRefetch(refetchVariable);
      refetch(refetchVariable);
    } else {
      let errors = backendErrorDisplay(response.data?.addExpense?.errors);
      props.setErrors(errors);
    }
  };

  const handleAcceptPayment = async (values: any, props: any) => {
    const response = await acceptPayment({
      variables: {
        account: values.account,
        transactionSource: "pos",
        amount: (values?.amount - values?.discount).toString(),
        note: values.note,
        user: values.user,
        discount: values?.discount,
      },
    });
    if (response?.data?.acceptPayment?.success) {
      handleClearPosScreen();
      setOpenAcceptPayment(false);
      toast.success(response?.data?.acceptPayment?.message);
    } else {
      let errors = backendErrorDisplay(response.data?.acceptPayment?.errors);
      props.setErrors(errors);
    }
  };

  const handlePayment = async (values: any, props: any) => {
    const response = await makeSupplierPayment({
      variables: {
        user: values.user,
        account: values.transactionAccounts,
        amount: values.amount,
        note: values.note,
      },
    });
    if (response?.data?.makePayment?.success) {
      setMakePayment(false);
      toast.success(response?.data?.makePayment?.message);
    } else {
      let errors = backendErrorDisplay(response.data?.makePayment?.errors);
      props.setErrors(errors);
    }
  };
  const { data: basicSettings } = useQuery(QUERY_SETTINGS);

  return (
    <AccessControl
      allowedPermissions={["can_view_transactions"]}
      renderNoAccess={<UserPermissionRequired />}
      children={
        <div>
          <TransactionsComponent
            data={
              selecteFilter === "today"
                ? transactionsByDateViewData?.transactions
                : data?.transactions
            }
            setExpenses={setExpenses}
            expenses={expenses}
            openAcceptPayment={openAcceptPayment}
            setOpenAcceptPayment={setOpenAcceptPayment}
            setMakePayment={setMakePayment}
            makePayment={makePayment}
            handleAddExpenses={handleAddExpenses}
            handleAcceptPayment={handleAcceptPayment}
            handlePayment={handlePayment}
            supplierList={supplierList}
            customerList={customerList}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setOffset={setOffset}
            offset={offset}
            transactionAccountsList={transactionAccountsList}
            transactionAccountsFilter={transactionAccountsFilter}
            selecteFilter={selecteFilter}
            setSelecteFilter={setSelecteFilter}
            transactionStartDate={transactionStartDate}
            setTransactionStartDate={setTransactionStartDate}
            transactionEndDate={transactionEndDate}
            setTransactionEndDate={setTransactionEndDate}
            transactionsByDateViewData={
              transactionsByDateViewData?.transactions
            }
            customerItems={customerItems}
            selected={selected}
            setSelected={setSelected}
            selectedCustomer={selectedCustomer}
            calenderType={
              basicSettings?.basicWebsiteConfigurations?.calendarType
            }
            makeSupplierPaymentLoading={makeSupplierPaymentLoading}
            addExpensesLoading={addExpensesLoading}
            acceptPaymentLoading={acceptPaymentLoading}
            transactionLoading={transactionLoading}
          />
        </div>
      }
    />
  );
};

export default Transactions;
