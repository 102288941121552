import React from 'react'
import Loadable from "react-loadable";
import DataLoadSpinner from '../../../components/reusable/Spinner/DataLoadSpinner';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import usePaginationState from '../../../customhooks/usePaginationHook';
import usePaginationHook from '../../../customhooks/usePaginationHook';
import { POS_SCREEN_ORDERED_ITEMS } from '../../queries';


const RewardProgramViewComponent: any = Loadable({
  loader: () =>
    import("../../../components/ExpensesReport/RewardProgramExpenses/RewardProgramView"),
  loading: DataLoadSpinner,
});
const RewardProgramView = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();
  const { id } = useParams()
  const { data: orderItemsData } = useQuery(POS_SCREEN_ORDERED_ITEMS, {
    variables: {
      first: pageSize,
      offset: offset,
      id: id
    },
  });
  return (
    <div><RewardProgramViewComponent
      orderItemsData={orderItemsData ?? []}
      setPageSize={setPageSize}
      pageSize={pageSize}
      setOffset={setOffset}
      offset={offset}
    /></div>
  )
}

export default RewardProgramView