import { useMutation } from "@apollo/client";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { useNavigate } from "react-router-dom";
import { QUERY_TRANSACTION_ACCOUNTS } from "../../queries";
import { CREATE_TRANSACTION_ACCOUNTS } from "../../mutations";

const AddAccountComponent: any = Loadable({
  loader: () =>
    import("../../../components/Account/ManageAccounts/AccountAddForm"),
  loading: DataLoadSpinner,
});

const AddAccount = () => {
  const navigate = useNavigate();

  const [createAccount, { loading: createAccountLoading }] = useMutation(
    CREATE_TRANSACTION_ACCOUNTS,
    {
      refetchQueries: [
        {
          query: QUERY_TRANSACTION_ACCOUNTS,
          variables: {
            first: 10,
            offset: 0,
          },
        },
      ],
    }
  );

  const handleCreateAccount = async (values: any, props: any) => {
    const response = await createAccount({
      variables: {
        name: values?.name,
        transactionCost: values?.transactionCost
          ? values?.transactionCost
          : "0",
      },
    });

    if (response?.data?.createTransactionAccount?.success) {
      toast.success("Created Successfully");
      props.resetForm();
      navigate("/account");
    } else {
      let errors = backendErrorDisplay(
        response?.data?.createTransactionAccount?.errors
      );
      props.setErrors(errors);
    }
  };

  return (
    <div>
      <AddAccountComponent
        handleCreateAccount={handleCreateAccount}
        createAccountLoading={createAccountLoading}
      />
    </div>
  );
};

export default AddAccount;
