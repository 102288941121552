import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import { POS_KITCHEN_ORDER_LIST } from "../queries";

const DashboardKitchenOrderListComponent: any = Loadable({
  loader: () =>
    import("../../../components/Orders/KitchenOrdersList/DashboardKitchenOrderList"),
  loading: DataLoadSpinner,
});

type Props = {};

const KitchenOrderList = (props: Props) => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();

  const [state, setState] = useState("");
  const { loading, data } = useQuery(POS_KITCHEN_ORDER_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      first: pageSize,
      offset: offset,
      orderStatus: state
    },
  });



  // const [modifyKitchenOrderStatus] = useMutation(POS_SCREEN_HOLD_KITCHEN_ORDER_LIST)

  const handleChangeOrderStatus = async (value: any, id: any) => {
    // const response = await modifyKitchenOrderStatus({
    //   variables: {
    //     orderStatus: value.id,
    //     id: id
    //   }
    // })
    // if (response?.data?.modifyKitchenOrderStatus?.success) {
    //   toast.success("Changed Status Successfully")
    // } else {
    //   backendErrorDisplay(response?.data?.modifyKitchenOrderStatus?.error)
    // }
  }

  return (
    <>
      <DashboardKitchenOrderListComponent
        isLoading={loading}
        data={data?.kitchenOrders ?? []}
        isPosScreenKitchenOrder={false}
        pageSize={pageSize}
        setPageSize={setPageSize}
        offset={offset}
        setOffset={setOffset}
        state={state}
        setState={setState}
        handleChangeOrderStatus={handleChangeOrderStatus}
      />
    </>
  );
};

export default KitchenOrderList;
