import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { toast } from 'react-toastify';
import DataLoadSpinner from '../../components/reusable/Spinner/DataLoadSpinner';
import { POS_SCREEN_PRODUCT, POS_SCREEN_PRODUCT_CATEGORY } from '../Product/queries';
import { QUERY_USERS, WASTAGE_AND_LOSS_REPORT } from '../queries';
import { QUERY_SETTINGS } from '../Settings/graphql';

const WastageAndLossComponent: any = Loadable({
  loader: () => import('../../components/Report/WastageAndLoss'),
  loading: DataLoadSpinner,
});

type Props = {}

const WastageAndLoss = (props: Props) => {

  const [categoryDropdown, setCategoryDropdown] = useState<any>([]);
  const [usersDropDown, setusersDropDown] = useState<any>([]);
  const [itemsDropDown, setItemsDropDown] = useState<any>([]);

  const [reportOpenState, setReportOpenState] = useState<boolean>(false)
  const [reportData, setReportData] = useState<any>()

  const [localFormState, setLocalFormState] = useState<any>()

  const { data: settingsData } = useQuery(QUERY_SETTINGS)

  // CATEGORY DROPDOWN LIST
  const { data: categoryData } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      level: 0,
      status: "visible"
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    categoryData?.categories?.edges?.map((elem: any) => (
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
        altId: elem?.node?.id
      })
    ));
    setCategoryDropdown(dropdownFormat);
  }, [categoryData]);

  // USERS DROPDOWN LIST
  const { data: userData } = useQuery(QUERY_USERS)
  useEffect(() => {
    const dropdownFormat: any = [];
    userData?.users?.edges?.map((elem: any) => (
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.username,
      })
    ));
    setusersDropDown(dropdownFormat);
  }, [userData]);

  // PROUDUCT DROPDOWN
  const { data: productData } = useQuery(POS_SCREEN_PRODUCT, {
    variables: {
      categoryList: localFormState?.category.length > 0 ? localFormState?.category : null,
    }
  });
  useEffect(() => {
    const productList: any = [];
    if (localFormState?.category.length > 0) {
      productData?.products?.edges?.map((elem: any) => (
        productList.push({
          id: elem?.node?.pk,
          title: elem?.node?.title,
          contentType: elem?.node?.contentType,
        })
      ));
    }
    setItemsDropDown(productList)
  }, [productData])

  // DETAIL SALES REPORT QUERY
  const [generateWastageAndLossReport, { loading: wastageAndLossReportLoading }] = useLazyQuery(WASTAGE_AND_LOSS_REPORT)

  const handleGenerateWastageAndLossReport = async (values: any) => {
    const itemsObjList: any[] = []
    values?.items?.map((elem: any) => (
      itemsObjList.push({ contentType: productData?.products?.edges[0]?.node?.contentTypeId, objectId: elem })
    ))
    const response = await generateWastageAndLossReport({
      variables: {
        user: values.user,
        category: values.category,
        items: itemsObjList,
        fromDateTime: values.fromDate,
        toDateTime: values.toDate,
      }
    })
    if (response?.data?.wastageAndLossReport?.createdOn) {
      setReportData(response?.data?.wastageAndLossReport)
      setReportOpenState(true)
    } else {
      toast.error("Report data available")
    }
  }

  const handleLocalStateChanges = (value: any, name: string) => {
    setLocalFormState((prev: any) => (
      {
        ...prev,
        [name]: value
      }))
  }

  const handleReset = (setFieldValue: any) => {
    setFieldValue('user', [])
    setFieldValue('category', [])
    setFieldValue('items', [])
    setFieldValue('fromDate', "")
    setFieldValue('toDate', "")
  }

  return (
    <WastageAndLossComponent
      categoryDropdown={categoryDropdown}
      usersDropDown={usersDropDown}
      itemsDropDown={itemsDropDown}
      wastageAndLossReportLoading={wastageAndLossReportLoading}
      handleGenerateWastageAndLossReport={handleGenerateWastageAndLossReport}
      reportOpenState={reportOpenState}
      setReportOpenState={setReportOpenState}
      reportData={reportData}
      currency={settingsData?.basicWebsiteConfigurations?.currency}
      handleReset={handleReset}
      handleLocalStateChanges={handleLocalStateChanges}
      settingsData={settingsData}
    />
  )
}

export default WastageAndLoss