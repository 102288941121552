
import Loadable from "react-loadable";
// import { toast } from 'react-toastify';
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";

const ProductBarcodeComponent: any = Loadable({
  loader: () => import('../../../components/reusable/Spinner/DataLoadSpinner'),
  loading: DataLoadSpinner,
});

type Props = {}

const ProductBarcode = (props: Props) => {
  // const {data: receiptData} = useQuery(GET_RECEIPT)
  // const [getReceiptData] = useMutation(CREATE_RECEIPT);

  const handleReceiptData = async (headerRef: any, footerRef: any) => {
    // const headerContent = headerRef.current.getContent().toString()
    // const footerContent = footerRef.current.getContent().toString()
    // const response = await getReceiptData({
    //   variables: {
    //     header: headerContent,
    //     footer: footerContent,
    //   },
    // });
    // if (response.data?.createUpdateReceiptSetting?.success) {
    //   toast.success("Receipt Updated successfully");
    // } else {
    //   toast.error("Failed..");
    // }
  }
  return (
    <div>
      <ProductBarcodeComponent
        handleReceiptData={handleReceiptData}
      />
    </div>
  )
}

export default ProductBarcode