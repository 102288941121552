import { Form } from "formik";
import PrimaryButton from "../reusable/Buttons/PrimaryButton";
import TextField from "../reusable/Forms/TextField";
import SingleImageUpload from "../reusable/SingleImageUpload";

type Props = {
  touched: any;
  values: any;
  setFieldValue: any;
  errors: any;
  categoryLoading: boolean;
  text: string;
  imageActive: boolean;
};

const CategoryForm = (props: Props) => {
  const {
    touched,
    values,
    setFieldValue,
    errors,
    categoryLoading,
    text,
    imageActive = false,
  } = props;
  return (
    <div>
      <Form>
        <div className="grid grid-cols-2 gap-8  mb-4 ">
          <TextField
            name="name"
            label="Category Name"
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            placeHolder="Enter category name"
          />
          <div className="my-auto pt-8">
            <input
              type="checkbox"
              name="status"
              value={values?.status}
              onChange={(e: any) => setFieldValue("status", e.target.checked)}
              checked={values?.status}
            />
            <label htmlFor="status" className="ml-2">
              Is Modifier?
            </label>
          </div>
        </div>
        {imageActive ? (
          <SingleImageUpload
            setFieldValue={setFieldValue}
            values={values}
            label={"Image"}
          />
        ) : (
          ""
        )}

        <PrimaryButton
          text={text}
          loading={categoryLoading}
          disable={categoryLoading}
        />
      </Form>
    </div>
  );
};

export default CategoryForm;
