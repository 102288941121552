import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import { searchItemsVar } from "../../../components/localstate/Cache";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { DELETE_RESTAURANT_FLOOR } from "../../mutations";
import { QUERY_RESTAURANT_FLOOR } from "../../queries";
import TableLoader from "../../../components/reusable/Loader/TableLoader";

const RestaurantFloorListComponent: any = Loadable({
  loader: () =>
    import("../../../components/RestaurantTable/Floor/RestaurantFloorList"),
  loading: () => <TableLoader showTotal={false} />,
});

type Props = {};

const RestaurantFloor = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const { data: restaurantFloorData, loading: restaurantFloorLoading } =
    useQuery(QUERY_RESTAURANT_FLOOR, {
      variables: {
        first: pageSize,
        offset: offset,
        title_Icontains: searchItems?.searchInput,
      },
    });

  const [deleteRestaurantFloor] = useMutation(DELETE_RESTAURANT_FLOOR, {
    refetchQueries: [
      {
        query: QUERY_RESTAURANT_FLOOR,
        variables: {
          first: pageSize,
          offset: offset,
          title_Icontains: searchItems?.searchInput,
        },
      },
    ],
  });

  const handleDeleteRestaurantFloor = async (id: string) => {
    const response = await deleteRestaurantFloor({
      variables: {
        objectId: id,
      },
    });
    if (response.data?.deleteRestaurantFloor?.success) {
      toast.success(response.data?.deleteRestaurantFloor?.message);
    } else {
      toast.error(
        response.data?.deleteRestaurantFloor?.errors?.nonFieldErrors[0]?.message
      );
    }
  };

  return (
    <RestaurantFloorListComponent
      data={restaurantFloorData?.floors}
      handleDeleteRestaurantFloor={handleDeleteRestaurantFloor}
      pageSize={pageSize}
      setPageSize={setPageSize}
      setOffset={setOffset}
      offset={offset}
      restaurantFloorLoading={restaurantFloorLoading}
    />
  );
};

export default RestaurantFloor;
