import Sidebar from "./SideBar";

interface ISideBar {
  visibleModulesData?: any;
}

const SideBar: React.FC<ISideBar> = (props) => {


  return (
    <>
      <Sidebar navigation={[]} />
    </>
  );
};

export default SideBar;
