import "./index.css";


import React from "react";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";

export default function Root() {
  return(
     <BrowserRouter basename="ecommerce">
    <Suspense fallback={<div>Loading... </div>}>
      <App />
    </Suspense>
  </BrowserRouter>
  )
}
