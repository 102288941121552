import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type Props = {};

const OrderLoader = (props: Props) => {
  return (
    <div className="relative rounded bg-white shadow pl-0 p-6 my-7">
      <div className="flex justify-between mx-2">
        <h2 className="text-xl font-semibold ml-4 mb-4">
          <Skeleton height={30} width={100} />
        </h2>
        <div className="flex justify-between gap-4">
          <Skeleton height={30} width={100} />
          <Skeleton height={30} width={100} />
        </div>
      </div>
      <div className="h-96 grid grid-cols-2">
        <div className="mx-auto">
          <Skeleton circle width={300} height={300} />
        </div>
        <div className="grid grid-cols-2 gap-12 p-10 overflow-auto">
          <div className="grid grid-cols-2 items-center">
            <div className="w-16 h-14 text-xl mt-3 font-bold flex justify-center items-center text-white rounded-lg">
              <Skeleton height={60} width={60} />
            </div>
            <div className="flex flex-col gap-y-3">
              <Skeleton height={30} width={80} />
              <Skeleton height={30} width={80} />
            </div>
          </div>
          <div className="grid grid-cols-2 items-center">
            <div className="w-16 h-14 text-xl mt-3 font-bold flex justify-center items-center text-white rounded-lg">
              <Skeleton height={60} width={60} />
            </div>
            <div className="flex flex-col gap-y-3">
              <Skeleton height={30} width={80} />
              <Skeleton height={30} width={80} />
            </div>
          </div>
          <div className="grid grid-cols-2 items-center">
            <div className="w-16 h-14 text-xl mt-3 font-bold flex justify-center items-center text-white rounded-lg">
              <Skeleton height={60} width={60} />
            </div>
            <div className="flex flex-col gap-y-3">
              <Skeleton height={30} width={80} />
              <Skeleton height={30} width={80} />
            </div>
          </div>
          <div className="grid grid-cols-2 items-center">
            <div className="w-16 h-14 text-xl mt-3 font-bold flex justify-center items-center text-white rounded-lg">
              <Skeleton height={60} width={60} />
            </div>
            <div className="flex flex-col gap-y-3">
              <Skeleton height={30} width={80} />
              <Skeleton height={30} width={80} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderLoader;
