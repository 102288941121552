import { gql } from "@apollo/client";

export const POS_SCREEN_CREATE_PRODUCT = gql`
  mutation (
    $posScreenProductId: String
    $category: [String]
    $title: String
    $unit: String
    $isActive: String
    $isModifier: String
    $tax: String
    $isWeightable: String
    $printOrder: String
    $isModifierCategoryGrid: String
    $isScalable: String
    $isOpenPriceProduct: String
    $sellingPrice: String
    $costPrice: String
    $image: Upload
    $modifierCategories: [String]
    $printers: [String]
    $barcodeContent: String
    $plu: String
    $shortDesc: String
    $isOpenProduct: String
    $displayInWebsite: String
  ) {
    createProduct(
      input: {
        category: $category
        posScreenProductId: $posScreenProductId
        title: $title
        unit: $unit
        isActive: $isActive
        tax: $tax
        isModifier: $isModifier
        isWeightable: $isWeightable
        printOrder: $printOrder
        isModifierCategoryGrid: $isModifierCategoryGrid
        isScalable: $isScalable
        isOpenPriceProduct: $isOpenPriceProduct
        printers: $printers
        modifierCategories: $modifierCategories
        image: $image
        sellingPrice: $sellingPrice
        costPrice: $costPrice
        barcodeContent: $barcodeContent
        plu: $plu
        shortDesc: $shortDesc
        isOpenProduct: $isOpenProduct
        displayInWebsite: $displayInWebsite
      }
    ) {
      errors
      success
      product {
        pk
        id
        title
        finalPrice
        sellingPrice
        costPrice
        isActive
        tax {
          id
          pk
          title
        }
      }
    }
  }
`;
export const POS_SCREEN_CREATE_PROMOTION = gql`
  mutation (
    $title: String
    $tax: String
    $compound: String
    $sellingPrice: String
  ) {
    createProduct(
      input: {
        compound: $compound
        title: $title
        tax: $tax
        sellingPrice: $sellingPrice
      }
    ) {
      errors
      success
      product {
        pk
        id
        title
        finalPrice
        sellingPrice
        tax {
          id
          pk
          title
        }
      }
    }
  }
`;
export const POS_SCREEN_UPDATE_PRODUCT = gql`
  mutation (
    $id: String!
    $category: [String]
    $title: String
    $unit: String
    $isActive: String
    $isModifier: String
    $tax: String
    $isWeightable: String
    $printOrder: String
    $isModifierCategoryGrid: String
    $isScalable: String
    $isOpenPriceProduct: String
    $sellingPrice: String
    $costPrice: String
    $image: Upload
    $modifierCategories: [String]
    $printers: [String]
    $barcodeContent: String
    $plu: String
    $shortDesc: String
    $displayInWebsite: String
  ) {
    updateProduct(
      input: {
        id: $id
        category: $category
        title: $title
        unit: $unit
        isActive: $isActive
        tax: $tax
        isModifier: $isModifier
        isWeightable: $isWeightable
        printOrder: $printOrder
        isModifierCategoryGrid: $isModifierCategoryGrid
        isScalable: $isScalable
        isOpenPriceProduct: $isOpenPriceProduct
        printers: $printers
        modifierCategories: $modifierCategories
        image: $image
        sellingPrice: $sellingPrice
        costPrice: $costPrice
        barcodeContent: $barcodeContent
        plu: $plu
        shortDesc: $shortDesc
        displayInWebsite: $displayInWebsite
      }
    ) {
      errors
      success
      product {
        pk
        id
        title
        finalPrice
        image
        sellingPrice
        shortDesc
        costPrice
        isActive
        plu
        tax {
          id
          pk
          title
        }
      }
    }
  }
`;
export const POS_SCREEN_UPDATE_PRODUCT_DESC = gql`
  mutation (
    $shortDesc: String
    $longDesc: String
    $contentType: String
    $objectId: String
  ) {
    createUpdateDescription(
      input: {
        shortDesc: $shortDesc
        longDesc: $longDesc
        contentType: $contentType
        objectId: $objectId
      }
    ) {
      errors
      success
    }
  }
`;

export const POS_SCREEN_DELETE_PRODUCT = gql`
  mutation ($objectId: String!) {
    deleteProduct(input: { objectId: $objectId }) {
      clientMutationId
      errors
      success
    }
  }
`;

export const POS_PRODUCT_SOLD_OUT = gql`
  mutation ($objectId: String!) {
    toggleProduct(input: { objectId: $objectId }) {
      success
      errors
      message
      clientMutationId
    }
  }
`;

export const POS_SCREEN_ASSIGN_PRINTER = gql`
  mutation ($contentType: Int, $objectId: String, $printer: [String]) {
    assignProductPrinter(
      input: {
        contentType: $contentType
        objectId: $objectId
        printer: $printer
      }
    ) {
      errors
      success
      message
    }
  }
`;

export const COMMIT_IMPORT_PRODUCT = gql`
  mutation COMMIT_IMPORT_PRODUCT($products: [ImportProductListInput]) {
    commitImportProduct(input: { products: $products }) {
      success
      errors
      message
    }
  }
`;

export const CREATE_COLLECTION = gql`
  mutation CREATE_COLLECTION($name: String, $isVisible: String) {
    createCollection(input: { name: $name, isVisible: $isVisible }) {
      success
      errors
      collection {
        name
        isVisible
      }
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation ($objectId: String!) {
    deleteCollection(input: { objectId: $objectId }) {
      success
      errors
    }
  }
`;
