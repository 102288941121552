import { useMutation, useReactiveVar } from '@apollo/client';
import Loadable from "react-loadable";
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { searchItemsVar } from '../../../components/localstate/Cache';
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from '../../../customhooks/usePaginationHook';
import { backendErrorDisplay } from '../../../utils/backendErrorDisplay';
import { CREATE_RESTAURANT_FLOOR } from '../../mutations';
import { QUERY_RESTAURANT_FLOOR, QUERY_RESTAURANT_TABLE } from '../../queries';

const AddRestaurantFloorComponent: any = Loadable({
  loader: () => import('../../../components/RestaurantTable/Floor/AddRestaurantFloor'),
  loading: DataLoadSpinner,
});

type Props = {}

const AddRestaurantFloor = (props: Props) => {
  const navigate = useNavigate()

  const { pageSize, offset } = usePaginationState()
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [createRestuarantFloor, { loading: createRestuarantFloorLoading }] = useMutation(CREATE_RESTAURANT_FLOOR, {
    refetchQueries: [{
      query: QUERY_RESTAURANT_FLOOR, variables: {
        first: pageSize,
        offset: offset,
        title_Icontains: searchItems?.searchInput
      }
    }]
  })

  const handleAddRestaurantFloor = async (values: any, props: any) => {
    const response = await createRestuarantFloor({
      variables: {
        title: values.title,
        capacity: values.capacity,
        note: values.note
      }
    })
    if (response.data?.createFloor?.success) {
      props.resetForm()
      navigate('/restaurant-floor')
      toast.success("Floor Created Successfully")
    }
    else {
      let errors = backendErrorDisplay(response.data?.createRestaurantFloor?.errors)
      props.setErrors(errors)
    }
  }

  return (
    <div>
      <AddRestaurantFloorComponent
        handleAddRestaurantFloor={handleAddRestaurantFloor}
        createRestuarantFloorLoading={createRestuarantFloorLoading}
      />
    </div>
  )
}

export default AddRestaurantFloor