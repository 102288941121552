import { useQuery } from "@apollo/client";
import moment from "moment";
import Loadable from "react-loadable";
import { useParams } from "react-router-dom";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import useCommonState from "../../../customhooks/useCommonState";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import { GET_QUERY_CUSTOMER_TRANSACTIONS_VIEW } from "../../queries";
import { QUERY_SETTINGS } from "../../Settings/graphql";

const CutomerDetailComponent: any = Loadable({
  loader: () => import("../../../components/Account/Customer/CutomerDetail"),
  loading: DataLoadSpinner,
});

const CustomerDetail = () => {
  const id = useParams();
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();
  const {
    transactionStartDate,
    setTransactionStartDate,
    transactionEndDate,
    setTransactionEndDate,
  } = useCommonState();


  const { data: selectedCustomer } = useQuery(
    GET_QUERY_CUSTOMER_TRANSACTIONS_VIEW,
    {
      variables: {
        transaction_User: id.id,
        first: pageSize,
        offset: offset,
        transaction_Created_Gte: moment(transactionStartDate).format(),
        transaction_Created_Lte: moment(transactionEndDate).format(),
      },
    },
  );
  const { data: basicSettings } = useQuery(QUERY_SETTINGS);

  const { offset: ordersOffset, setOffset: setOrdersOffset, pageSize: ordersPageSize, setPageSize: setOrdersPageSize } = usePaginationHook()
  
  

  return (
    <div>
      <CutomerDetailComponent
        selectedCustomer={
          selectedCustomer?.customerTransactionsView?.edges ?? []
        }
        pageSize={pageSize}
        setPageSize={setPageSize}
        offset={offset}
        setOffset={setOffset}
        transactionStartDate={transactionStartDate}
        setTransactionStartDate={setTransactionStartDate}
        transactionEndDate={transactionEndDate}
        setTransactionEndDate={setTransactionEndDate}
        calenderType={basicSettings?.basicWebsiteConfigurations?.calendarType}

        ordersOffset={ordersOffset}
        setOrdersOffset={setOrdersOffset}
        ordersPageSize={ordersPageSize}
        setOrdersPageSize={setOrdersPageSize}
      />
    </div>
  );
};

export default CustomerDetail;
