import { useLazyQuery, useQuery } from '@apollo/client';
import { useState } from 'react';
import Loadable from 'react-loadable';
import { toast } from 'react-toastify';
import DataLoadSpinner from '../../components/reusable/Spinner/DataLoadSpinner';
import { MEMBERS_REPORT } from '../queries';
import { QUERY_SETTINGS } from '../Settings/graphql';

const MembersComponent: any = Loadable({
  loader: () => import('../../components/Report/Members'),
  loading: DataLoadSpinner,
});

type Props = {}

const Members = (props: Props) => {

  const [reportOpenState, setReportOpenState] = useState<boolean>(false)
  const [reportData, setReportData] = useState<any>()

  const { data: settingsData } = useQuery(QUERY_SETTINGS)


  // DETAIL SALES REPORT QUERY
  const [generateMemberReport, { loading: memberReportLoading }] = useLazyQuery(MEMBERS_REPORT)

  const handleGenerateMemberReport = async (values: any) => {
    const response = await generateMemberReport({
      variables: {
        fromDateTime: values.fromDate,
        toDateTime: values.toDate,
      }
    })
    if (response?.data?.membersReport?.createdOn) {
      setReportData(response?.data?.membersReport)
      setReportOpenState(true)
    } else {
      toast.error("Report data available")
    }
  }


  const handleReset = (setFieldValue: any) => {
    setFieldValue('fromDate', "")
    setFieldValue('toDate', "")
  }

  return (
    <MembersComponent
      memberReportLoading={memberReportLoading}
      handleGenerateMemberReport={handleGenerateMemberReport}
      reportOpenState={reportOpenState}
      setReportOpenState={setReportOpenState}
      reportData={reportData}
      handleReset={handleReset}
      settingsData={settingsData}
    />
  )
}

export default Members