import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import CustomHeaderLayout from "../../layouts/CustomHeaderLayout";

import { POS_KITCHEN_ORDER_LIST } from "../Orders/queries";
import { UPDATE_KITCHEN_ORDER_BUMPED_STATUS, UPDATE_KITCHEN_ORDER_ITEMS_BUMPED_STATUS } from "./mutation";
import useWebSocketHook from "../../customhooks/useWebSocketHook";

const KitchenDisplayComponent: any = Loadable({
  loader: () => import("../../components/KitchenDisplay/KitchenDisplay"),
  loading: DataLoadSpinner,
});

const KitchenDisplay = () => {
  const { data: kitchenOrderData, refetch } = useQuery(
    POS_KITCHEN_ORDER_LIST,
    {
      // fetchPolicy: "no-cache",
      variables: {
        orderStatus: "pending",
        isBumped: false
      },
      pollInterval: 10000,
    },
  );


  const { qrCodeOrderMessageData, posScreenOrderMessageData } = useWebSocketHook();

  // UPDATE_KITCHEN_ORDER_ITEMS_BUMPED_STATUS
  const [updateKitchenOrderItemsBumpedStatus, {
    loading: updateKitchenOrderItemsBumpedStatusLoading,
  }] = useMutation(
    UPDATE_KITCHEN_ORDER_ITEMS_BUMPED_STATUS,
    {
      refetchQueries: [
        {
          query: POS_KITCHEN_ORDER_LIST,
          variables: {
            orderStatus: "pending",
            isBumped: false
          },
        },
      ],
    }
  );

  const handleUpdateKitchenOrderItemsBumpedStatus = async (item: any) => {

    const modifiedResponse = await updateKitchenOrderItemsBumpedStatus({
      variables: {
        orderItem: (item?.node?.pk).toString(),
        isBumped: true
      },
    });
    if (modifiedResponse) {
      toast.success(" handleUpdateKitchenOrderItemsBumpedStatus Modified");
    }
  }

  const [updateKitchenOrderBumpedStatus, { loading: updateKitchenOrderBumpedStatusLoading }] = useMutation(UPDATE_KITCHEN_ORDER_BUMPED_STATUS,
    {
      refetchQueries: [
        {
          query: POS_KITCHEN_ORDER_LIST,
          variables: {
            orderStatus: "pending",
            isBumped: false
          },
        },
      ],
    });
  const handleBumpOrder = async (orderItemPk: any) => {
    if (orderItemPk) {
      const response = await updateKitchenOrderBumpedStatus({
        variables: {
          orderId: orderItemPk,
          isBumped: true,
        },
      });
      if (response) {
        toast.success("Successfully Bumped Order");
      }
    }
  }
  useEffect(() => {
    refetch({
      orderStatus: "pending",
      isBumped: false
    })
  }, [qrCodeOrderMessageData, posScreenOrderMessageData])

  return (
    <>
      <CustomHeaderLayout layoutType={"kitchenDisplay"} />
      <KitchenDisplayComponent
        kitchenOrderData={kitchenOrderData}
        handleBumpOrder={handleBumpOrder}
        bumpItemLoading={updateKitchenOrderItemsBumpedStatusLoading}
        bumpOrderLoading={updateKitchenOrderBumpedStatusLoading}
        handleUpdateKitchenOrderItemsBumpedStatus={handleUpdateKitchenOrderItemsBumpedStatus}
      />
    </>
  );
};

export default KitchenDisplay;
