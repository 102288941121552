import React, { LegacyRef } from "react";

const BarCodePrintPreviewUI = React.forwardRef(
  (props: any, ref: LegacyRef<any> | undefined) => {
    const { barcodeHtmlString } = props;
    return (
      <>
        <div ref={ref} style={{ margin: 0, padding: 5, maxWidth: "800px" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: barcodeHtmlString,
            }}
          ></div>
        </div>
      </>
    );
  },
);

export default BarCodePrintPreviewUI;
