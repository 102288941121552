import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useState } from "react";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import { searchItemsVar } from "../../../components/localstate/Cache";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { DELETE_POS_DISCOUNT } from "../../mutations";
import { QUERY_POS_DISCOUNT } from "../../queries";
import TableLoader from "../../../components/reusable/Loader/TableLoader";

const POSDiscountListComponent: any = Loadable({
  loader: () =>
    import("../../../components/Discount/POSDiscount/POSDiscountList"),
  loading: () => <TableLoader />,
});

type Props = {};

const POSDiscount = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();

  const [state, setState] = useState("");
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [deletePosDiscount] = useMutation(DELETE_POS_DISCOUNT, {
    refetchQueries: [
      {
        query: QUERY_POS_DISCOUNT,
        variables: {
          first: pageSize,
          offset: offset,
          discountType: state,
          title: "",
        },
      },
    ],
  });

  const { data: posDiscountData, loading: discountLoading } = useQuery(
    QUERY_POS_DISCOUNT,
    {
      variables: {
        first: pageSize,
        offset: offset,
        discountType: state,
        title: searchItems?.searchInput,
      },
    }
  );

  const handleDeletePOSDiscount = async (id: string) => {
    const response = await deletePosDiscount({
      variables: {
        objectId: id,
      },
    });
    if (response.data?.deletePosDiscount?.success) {
      toast.success(response.data?.deletePosDiscount?.message);
    } else {
      toast.error(
        response.data?.deletePosDiscount?.errors?.nonFieldErrors[0]?.message
      );
    }
  };

  return (
    <>
      <POSDiscountListComponent
        data={posDiscountData?.discounts}
        discountLoading={discountLoading}
        handleDeletePOSDiscount={handleDeletePOSDiscount}
        setPageSize={setPageSize}
        pageSize={pageSize}
        offset={offset}
        setOffset={setOffset}
        state={state}
        setState={setState}
      />
    </>
  );
};

export default POSDiscount;
