import { useMutation, useQuery } from "@apollo/client";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import CREATE_RECEIPT from "./mutation";
import GET_RECEIPT from "./queries";

const ReceiptSettingComponent: any = Loadable({
  loader: () => import("../../components/ReceiptSetting/ReceiptSetting"),
  loading: DataLoadSpinner,
});
const ReceiptSetting: React.FC<any> = () => {
  const { data: receiptData } = useQuery(GET_RECEIPT, {
    fetchPolicy: 'network-only'
  });
  const [getReceiptData, { loading: recieptLoading }] = useMutation(CREATE_RECEIPT, {
    refetchQueries: [
      {
        query: GET_RECEIPT
      }
    ]
  });

  const handleReceiptData = async (headerRef: any, footerRef: any, barcodeEnable: any) => {
    const headerContent = headerRef.current.getContent().toString();
    const footerContent = footerRef.current.getContent().toString();
    const response = await getReceiptData({
      variables: {
        header: headerContent,
        footer: footerContent,
        isEnabledBarcode: barcodeEnable ? barcodeEnable : false

      },
    });
    if (response.data?.createUpdateReceiptSetting?.success) {
      toast.success("Receipt Updated successfully");
    } else {
      toast.error("Receipt Updated Failed");
    }
  };

  return (
    <div>
      <ReceiptSettingComponent
        receiptData={receiptData}
        handleReceiptData={handleReceiptData}
        recieptLoading={recieptLoading}
      />
    </div>
  );
};
export default ReceiptSetting;
