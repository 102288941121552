import { Tab } from "@headlessui/react";
import classNames from "../../../../utils/classNames";
import Divider from "../../Divider";

type Props = {
  tabTitle: any;
  tabPanels: any;
};

const TabPanel = (props: Props) => {
  const { tabTitle, tabPanels } = props;

  return (
    <div className="rounded-md bg-white mt-4 mb-4">
      <Tab.Group>
        <Tab.List className="flex">
          {tabTitle?.map((elem: any) => {
            return (
              <Tab
                key={elem}
                className={({ selected }) =>
                  classNames(
                    "text-md capitalize p-2 px-2 first:rounded-tl-md",
                    selected
                      ? "bg-gray-50 shadow border-b border-0 border-purple-700 "
                      : "hover:bg-gray-200 text-gray-600 hover:text-gray-700 bg-gray-200",
                  )
                }
              >
                {elem}
              </Tab>
            );
          })}
        </Tab.List>
        <Divider />
        <Tab.Panels>
          {tabPanels?.map((elem: any, index: number) => {
            return (
              <Tab.Panel className="p-2" key={index}>
                {elem}
              </Tab.Panel>
            );
          })}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default TabPanel;
