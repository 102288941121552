import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react'
import Loadable from "react-loadable";
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { searchItemsVar } from '../../../components/localstate/Cache';
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from '../../../customhooks/usePaginationHook';
import { backendErrorDisplay } from '../../../utils/backendErrorDisplay';
import { EDIT_RESTAURANT_FLOOR } from '../../mutations';
import { GET_RESTAURANT_FLOOR, QUERY_RESTAURANT_FLOOR } from '../../queries';

const EditRestaurantFloorComponent: any = Loadable({
  loader: () => import('../../../components/RestaurantTable/Floor/EditRestaurantFloor'),
  loading: DataLoadSpinner,
});

type Props = {}

const EditRestaurantFloor = (props: Props) => {
  const navigate = useNavigate()
  const params = useParams()

  const { pageSize, offset } = usePaginationState()
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [selectedRestaurantFloorInitailValues, setSelectedRestaurantFloorInitailValues] = useState<any>()

  const { data: selectedRestaurantFloor } = useQuery(GET_RESTAURANT_FLOOR, {
    variables: {
      id: params.id
    }
  })
  

  useEffect(() => {
    setSelectedRestaurantFloorInitailValues(null)
    if (selectedRestaurantFloor) {
      const newInitialValues = {
        title: selectedRestaurantFloor?.floor?.title,
        note: selectedRestaurantFloor?.floor?.note
      }
      setSelectedRestaurantFloorInitailValues(newInitialValues)
    }
  }, [selectedRestaurantFloor, params.id]);

  const [editRestaurantFloor, { loading: editRestaurantFloorLoading }] = useMutation(EDIT_RESTAURANT_FLOOR, {
    refetchQueries: [{
      query: QUERY_RESTAURANT_FLOOR, variables: {
        first: pageSize,
        offset: offset,
        title_Icontains: searchItems?.searchInput
      }
    }]
  })

  const handleEditRestaurantFloor = async (values: any, props: any) => {
    const response = await editRestaurantFloor({
      variables: {
        id: selectedRestaurantFloor?.floor?.pk,
        title: values.title,
        note: values.note
      }
    })
    if (response.data?.updateFloor?.success) {
      props.resetForm()
      navigate('/restaurant-floor')
      toast.success("Updated SuccessFully")
    } else {
      let errors = backendErrorDisplay(response.data?.updateFloor?.errors)
      props.setErrors(errors)
    }
  }

  return (
    <div>
      <EditRestaurantFloorComponent
        handleEditRestaurantFloor={handleEditRestaurantFloor}
        selectedRestaurantFloorInitailValues={selectedRestaurantFloorInitailValues}
        editRestaurantFloorLoading={editRestaurantFloorLoading}
      />
    </div>
  )
}

export default EditRestaurantFloor