import React, { useState } from 'react'
import Loadable from 'react-loadable';
import DataLoadSpinner from '../../components/reusable/Spinner/DataLoadSpinner';
import { useLazyQuery, useQuery } from '@apollo/client';
import { HOURLY_SALES_REPORT } from '../queries';
import { toast } from 'react-toastify';
import moment from 'moment';
import { QUERY_SETTINGS } from '../Settings/graphql';
import AccessControl from '../../helpers/accessControl';
import UserPermissionRequired from '../Error/UserPermisssionRequired';

const HourlySalesReportComponent: any = Loadable({
  loader: () => import('../../components/Report/HourlySalesReport'),
  loading: DataLoadSpinner,
});
const HourlySalesReport = () => {
  const [reportData, setReportData] = useState<any>()
  const [reportOpenState, setReportOpenState] = useState<boolean>(false)
  const [value, setValue] = useState<any>({
    startDate: moment().format(),
    endDate: moment().format(),
  });
  const [generateHourlySalesReport, { loading: hourlySalesReportLoading }] = useLazyQuery(HOURLY_SALES_REPORT)

  const handleGenerateHourlySalesReport = async (values: any) => {
    const response = await generateHourlySalesReport({
      variables: {
        fromDate: moment(value.startDate).format('YYYY-MM-DD'),
        toDate: moment(value.endDate).format('YYYY-MM-DD'),
      }
    })
    if (response?.data?.hourlyTransactionHistory) {
      setReportData(response?.data?.hourlyTransactionHistory)
      setReportOpenState(true)
    } else {
      toast.error("Report data available")
    }
  }
  const { data: settingsData } = useQuery(QUERY_SETTINGS)
  const handleReset = (setFieldValue: any) => {
    setValue({
      startDate: moment().format(),
      endDate: moment().format(),
    })
  }

  return (
    <AccessControl
      allowedPermissions={['can_view_report']}
      renderNoAccess={<UserPermissionRequired />}
      children={
        <HourlySalesReportComponent
          hourlySalesReportLoading={hourlySalesReportLoading}
          handleGenerateHourlySalesReport={handleGenerateHourlySalesReport}
          reportData={reportData}
          setReportOpenState={setReportOpenState}
          reportOpenState={reportOpenState}
          currency={settingsData?.basicWebsiteConfigurations?.currency}
          handleReset={handleReset}
          setValue={setValue}
          value={value}

        />
      }
    />

  )
}

export default HourlySalesReport