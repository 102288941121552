//@ts-nocheck
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import PrivateRoute from "./PrivateRoute";
import DashboardLayout from "../layouts/DashboardLayout";
import DashboardNotFound from "../pages/Error/DashboardNotFound";
import Setting from "../pages/Settings/Setting";
import CategoryList from "../pages/Category/CategoryList";
import AddCategory from "../pages/Category/AddCategory";
import EditCategory from "../pages/Category/EditCategory";
import ProductList from "../pages/Product";
import CustomerList from "../pages/Account/Customer/CustomerList";
import Unit from "../pages/Units/Unit";
import AddSubCategory from "../pages/Category/SubCategory/AddSubCategory";
import AddCustomer from "../pages/Account/Customer/AddCustomer";
import CustomerDetail from "../pages/Account/Customer/CustomerDetail";
import Tax from "../pages/Tax/Tax";
import AddTax from "../pages/Tax/AddTax";
import EditTax from "../pages/Tax/EditTax";
import AddServiceCharge from "../pages/ServiceCharge/AddServiceCharge";
import EditServiceCharge from "../pages/ServiceCharge/EditServiceCharge";
import ServiceCharge from "../pages/ServiceCharge/ServiceCharge";
import Modifier from "../pages/Product/Modifier/Modifier";
import AddModifier from "../pages/Product/Modifier/AddModifier";
import EditModifier from "../pages/Product/Modifier/EditModifier";
import AddPosScreenProduct from "../pages/Product/AddProduct";
import Printer from "../pages/Printer/Printer";
import AddPrinter from "../pages/Printer/AddPrinter";
import EditPrinter from "../pages/Printer/EditPrinter";
import POSDiscount from "../pages/Discount/POSDiscount/POSDiscount";
import AddPOSDiscount from "../pages/Discount/POSDiscount/AddPOSDiscount";
import EditPOSDiscount from "../pages/Discount/POSDiscount/EditPOSDiscount";
import RestaurantTable from "../pages/RestaurantTable/Table/RestaurantTable";
import AddRestaurantTable from "../pages/RestaurantTable/Table/AddRestaurantTable";
import EditRestaurantTable from "../pages/RestaurantTable/Table/EditRestaurantTable";
import ReceiptSetting from "../pages/ReceiptSetting/ReceiptSetting";
import KitchenOrderList from "../pages/Orders/Orders/KitchenOrderList";
import KitchenOrderDetail from "../pages/Orders/Orders/KitchenOrderDetail";
import CreateSupplier from "../pages/Account/Supplier/CreateSupplier";
import EditSupplier from "../pages/Account/Supplier/EditSupplier";
import DetailSupplier from "../pages/Account/Supplier/DetailSupplier";
import EditCustomer from "../pages/Account/Customer/EditCustomer";
import BulkImportProduct from "../pages/Product/BulkImportProduct";
import ProductBarcode from "../pages/Product/ProductBarcode/ProductBarcode";
import BarcodeSetting from "../pages/Settings/BarcodeSetting";
import DashboardOrderDetail from "../pages/Orders/Orders/DashboardOrderDetail";
import Account from "../pages/Account/ManageAccounts/Account";
import AddAccount from "../pages/Account/ManageAccounts/AddAccount";
import EditAccount from "../pages/Account/ManageAccounts/EditAccount";
import AccountDetail from "../pages/Account/ManageAccounts/AccountDetail";
import Transactions from "../pages/Account/Transactions/Transactions";
import Suppliers from "../pages/Account/Supplier/SupplierList";

import Collection from "../pages/Product/Collection/Collection";
import CreateCollection from "../pages/Product/Collection/CreateCollection";
import EditCollection from "../pages/Product/Collection/EditCollection";

import AddOrder from "../components/Orders/AddOrder";

import KitchenDisplay from "../pages/KitchenDisplay/KitchenDisplay";

import RecallKitchenDisplay from "../pages/KitchenDisplay/RecallKitchenDisplay";

import SalesReport from "../pages/Reports/SalesReport";
import QuickSalesReport from "../pages/Report/QuickSalesReport";
import DetailSalesReport from "../pages/Report/DetailSalesReport";
import EmployeeHour from "../pages/Report/EmployeeHour";
import Members from "../pages/Report/Members";
import NoSale from "../pages/Report/NoSale";
import OpeningAndClosing from "../pages/Report/OpeningAndClosing";
import Stock from "../pages/Report/Stock";
import WastageAndLoss from "../pages/Report/WastageAndLoss";

import DetailRecallKitchenDisplay from "../pages/KitchenDisplay/DetailRecallKitchenDisplay";
import RestaurantFloor from "../pages/RestaurantTable/Floor/RestaurantFloor";
import AddRestaurantFloor from "../pages/RestaurantTable/Floor/AddRestaurantFloor";
import EditRestaurantFloor from "../pages/RestaurantTable/Floor/EditRestaurantFloor";
import Terminal from "../pages/Terminal/Terminal";
import AddTerminal from "../pages/Terminal/AddTerminal";
import EditTerminal from "../pages/Terminal/EditTerminal";
import Reports from "../pages/Reports/ProductWiseReport";
import CategoryWiseReport from "../pages/Reports/CategorywiseReport";
import UserWiseReport from "../pages/Reports/UserwiseReport";
import FloorWiseReport from "../pages/Reports/FloorwiseReport";
import TableWiseReport from "../pages/Reports/TablewiseReport";
import Promotion from "../pages/Promotion/Promotion";
import EditPromotion from "../pages/Promotion/EditPromotion";
import AddPromotion from "../pages/Promotion/AddPromotion";
import ProductPrinOrders from "../pages/ProductPrinOrders/ProductPrintOrders";

import HourlySalesReport from "../pages/Report/HourlySalesReport";
import OrderType from "../pages/DeliveryMethods/OrderType";
import AddOrderType from "../pages/DeliveryMethods/AddOrderType";
import EditOrderType from "../pages/DeliveryMethods/EditOrderType";
import RewardSetting from "../pages/RewardSetting/RewardSetting";
import EditProduct from "../pages/Product/EditProduct";
import OrderList from "../pages/Orders/Orders/OrderList";
import RefundExpensesReport from "../pages/ExpensesReport/RefundExpenses/RefundExpenses";
import ViewDiscountExpenses from "../pages/ExpensesReport/DiscountExpenses/ViewDiscountExpenses";
import RefundExpensesView from "../pages/ExpensesReport/RefundExpenses/RefundExpensesView";
import RewardProgramView from "../pages/ExpensesReport/RewardProgramExpenses/RewardProgramView";
import CashOutExpenses from "../pages/ExpensesReport/CashOutExpenses/CashOutExpenses";
import VoidOrderExpensesReport from "../pages/ExpensesReport/VoidOrderExpenses/VoidOrderExpenses";
import DiscountExpensesReport from "../pages/ExpensesReport/DiscountExpenses/DiscountExpensesReport";
import RewardProgramExpensesReport from "../pages/ExpensesReport/RewardProgramExpenses/RewardProgramExpenses";
import CustomerOrderDetails from "../components/Account/Customer/CustomerOrderDetails";
import SyncSetting from "../pages/Settings/SyncSetting";
import HoldCart from "../pages/Orders/HoldCart/HoldCart";
import HoldOrderDetails from "../pages/Orders/HoldCart/HoldOrderDetails";
import RemoteOrder from "../pages/Orders/RemoteOrder/RemoteOrder";

// https://adarshaacharya.com.np/blog/role-based-auth-with-react-router-v6

/**
 * Top level application router
 *
 * @returns {Component}
 */
const Router = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }
      >
        <Route path={"/"} element={<Dashboard />} />
        <Route path="/promotions" element={<Promotion />} />
        <Route path="/promotions/create" element={<AddPromotion />} />
        <Route path="/promotions/edit/:id" element={<EditPromotion />} />
        <Route path={"/product-print-orders"} element={<ProductPrinOrders />} />
        <Route path={"/order-type"} element={<OrderType />} />
        <Route path={"/order-type/create"} element={<AddOrderType />} />
        <Route path={"/order-type/edit/:id"} element={<EditOrderType />} />
        <Route path={"/quick-sales-report"} element={<QuickSalesReport />} />
        <Route path={"/sales-report"} element={<SalesReport />} />
        <Route path={"/productwise-report"} element={<Reports />} />
        <Route path={"/categorywise-report"} element={<CategoryWiseReport />} />
        <Route path={"/userwise-report"} element={<UserWiseReport />} />
        <Route path={"/floorwise-report"} element={<FloorWiseReport />} />
        <Route path={"/tablewise-report"} element={<TableWiseReport />} />
        <Route path={"/detail-sales-report"} element={<DetailSalesReport />} />
        <Route path={"/hourly-sales-report"} element={<HourlySalesReport />} />
        <Route path={"/employee-hour-report"} element={<EmployeeHour />} />
        <Route path={"/members-report"} element={<Members />} />
        <Route path={"/no-sale-report"} element={<NoSale />} />
        <Route
          path={"/opening-&-closing-report"}
          element={<OpeningAndClosing />}
        />
        <Route path={"/stock-report"} element={<Stock />} />
        <Route path={"/wastage-loss-report"} element={<WastageAndLoss />} />
        <Route path={"/category"} element={<CategoryList />} />
        <Route path={"/category/create"} element={<AddCategory />} />
        <Route path={"/category/edit/:id"} element={<EditCategory />} />
        <Route path={"/category/edit/:id/:pk"} element={<AddSubCategory />} />
        <Route path={"/product"} element={<ProductList />} />
        <Route path={"/product/create"} element={<AddPosScreenProduct />} />
        <Route path={"/product/edit/:id"} element={<EditProduct />} />
        <Route path={"/product/bulk"} element={<BulkImportProduct />} />
        <Route path={"/product/collection"} element={<Collection />} />
        <Route
          path={"/product/collection/create"}
          element={<CreateCollection />}
        />
        <Route
          path={"/product/collection/edit/:id"}
          element={<EditCollection />}
        />
        <Route path={"/customer"} element={<CustomerList />} />
        <Route path={"/customer/create"} element={<AddCustomer />} />
        <Route path={"/customer/edit/:id"} element={<EditCustomer />} />
        <Route path={"/customer/detail/:id"} element={<CustomerDetail />} />
        <Route path={"/orders"} element={<OrderList />} />
        <Route path={"/orders/detail/:id"} element={<DashboardOrderDetail />} />
        <Route path={"/hold-cart"} element={<HoldCart />} />
        <Route path={"/hold-cart/detail/:id"} element={<HoldOrderDetails />} />
        <Route path={"/remote-orders"} element={<RemoteOrder />} />
        <Route
          path={"/website-orders/detail/:id"}
          element={<DashboardOrderDetail />}
        />
        <Route path="/supplier" element={<Suppliers />} />
        <Route path={"/supplier/create"} element={<CreateSupplier />} />
        <Route path={"/supplier/edit/:id"} element={<EditSupplier />} />
        <Route
          path={"/supplier/detail/:id/:supplierId"}
          element={<DetailSupplier />}
        />
        <Route path={"/kitchen-orders"} element={<KitchenOrderList />} />
        <Route
          path={"/kitchen-orders/detail/:id"}
          element={<KitchenOrderDetail />}
        />
        <Route path="/account" element={<Account />} />
        <Route path={"/account/create"} element={<AddAccount />} />
        <Route path={"/account/edit/:id"} element={<EditAccount />} />
        <Route path={"/account/detail/:id"} element={<AccountDetail />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route
          path={"/transactions/detail/:id"}
          element={<CustomerOrderDetails />}
        />
        <Route path={"/orders/create"} element={<AddOrder />} />
        <Route path={"/basic-setting"} element={<Setting />} />
        <Route path={"/reward-setting"} element={<RewardSetting />} />
        <Route path={"/sync-setting"} element={<SyncSetting />} />
        <Route
          path={"/barcode-setting/:pk/:barcodecontent/"}
          element={<BarcodeSetting />}
        />
        <Route path={"/"} element={<Dashboard />} />
        <Route path={"/unit"} element={<Unit />} />
        <Route path={"/tax"} element={<Tax />} />
        <Route path={"/tax/create"} element={<AddTax />} />
        <Route path={"/tax/edit/:id"} element={<EditTax />} />
        <Route path={"/servicecharge"} element={<ServiceCharge />} />
        <Route path={"/servicecharge/create"} element={<AddServiceCharge />} />
        <Route
          path={"/servicecharge/edit/:id"}
          element={<EditServiceCharge />}
        />
        <Route path={"/refund"} element={<RefundExpensesReport />} />
        <Route
          path={"/view-discount-expenses/:id"}
          element={<ViewDiscountExpenses />}
        />
        <Route
          path={"/view-refund-expenses/:id"}
          element={<RefundExpensesView />}
        />
        <Route
          path={"/view-reward-program-expenses/:id"}
          element={<RewardProgramView />}
        />
        <Route path={"/cashout"} element={<CashOutExpenses />} />{" "}
        <Route
          path={"/void-order-expenses"}
          element={<VoidOrderExpensesReport />}
        />{" "}
        <Route
          path={"/discountexpenses"}
          element={<DiscountExpensesReport />}
        />{" "}
        <Route
          path={"/reward-programs"}
          element={<RewardProgramExpensesReport />}
        />
        <Route path={"/modifier"} element={<Modifier />} />
        <Route path={"/modifier/create"} element={<AddModifier />} />
        <Route path={"/modifier/edit/:id"} element={<EditModifier />} />
        <Route path={"/product-barcode/:id"} element={<ProductBarcode />} />
        <Route path={"/receipt-setting"} element={<ReceiptSetting />} />
        <Route path={"/printer"} element={<Printer />} />
        <Route path={"/printer/create"} element={<AddPrinter />} />
        <Route path={"/printer/edit/:id"} element={<EditPrinter />} />
        <Route path={"/pos-discount"} element={<POSDiscount />} />
        <Route path={"/pos-discount/create"} element={<AddPOSDiscount />} />
        <Route path={"/pos-discount/edit/:id"} element={<EditPOSDiscount />} />
        <Route path={"/restaurant-table"} element={<RestaurantTable />} />
        <Route
          path={"/restaurant-table/create"}
          element={<AddRestaurantTable />}
        />
        <Route
          path={"/restaurant-table/edit/:id"}
          element={<EditRestaurantTable />}
        />
        <Route path={"/restaurant-floor"} element={<RestaurantFloor />} />
        <Route
          path={"/restaurant-floor/create"}
          element={<AddRestaurantFloor />}
        />
        <Route
          path={"/restaurant-floor/edit/:id"}
          element={<EditRestaurantFloor />}
        />
        <Route path={"/terminal"} element={<Terminal />} />
        <Route path={"/terminal/create"} element={<AddTerminal />} />
        <Route path={"/terminal/edit/:id"} element={<EditTerminal />} />
        <Route path="/*" element={<DashboardNotFound />} />
      </Route>
      <Route path={"/kitchenDisplay"} element={<KitchenDisplay />} />
      <Route
        path={"/kitchenDisplay/RecallOrder"}
        element={<RecallKitchenDisplay />}
      />
      <Route
        path={"/kitchenDisplay/RecallOrder/detail/:id"}
        element={<DetailRecallKitchenDisplay />}
      />
    </Routes>
  );
};

export default Router;
