import { gql } from "@apollo/client";

export const DELIVER_METHODS = gql`
  query (
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $title_Icontains: String
  ) {
    deliveryMethods(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title_Icontains: $title_Icontains
    ) {
      edges {
        node {
          id
          pk
          title
          amount
          description
        }
      }
    }
  }
`;
export const CREATE_DELIVERY_METHODS = gql`
  mutation (
    $title: String
    $description: String
    $amount: String
    $displayInWebsite: String
    $isRedirectToCustomers: String
  ) {
    createDeliveryMethod(
      input: {
        title: $title
        description: $description
        amount: $amount
        isRedirectToCustomers: $isRedirectToCustomers
        displayInWebsite: $displayInWebsite
      }
    ) {
      errors
      success
    }
  }
`;
export const UPDATE_DELIVERY_METHODS = gql`
  mutation (
    $title: String
    $description: String
    $amount: String
    $deliveryMethodId: String!
    $isRedirectToCustomers: String!
    $displayInWebsite: String!
  ) {
    updateDeliveryMethod(
      input: {
        title: $title
        description: $description
        amount: $amount
        deliveryMethodId: $deliveryMethodId
        displayInWebsite: $displayInWebsite
        isRedirectToCustomers: $isRedirectToCustomers
      }
    ) {
      errors
      success
    }
  }
`;
export const SINGLE_DELIVERY_METHOD = gql`
  query ($id: ID!) {
    deliveryMethod(id: $id) {
      id
      pk
      created
      title
      description
      amount
      isRedirectToCustomers
      displayInWebsite
    }
  }
`;
export const DELETE_DELIVERY_METHOD = gql`
  mutation ($objectId: String!) {
    deleteDeliveryMethod(input: { objectId: $objectId }) {
      errors
      success
    }
  }
`;
