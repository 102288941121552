import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../customhooks/usePaginationHook";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import {
  DELIVER_METHODS,
  SINGLE_DELIVERY_METHOD,
  UPDATE_DELIVERY_METHODS,
} from "./queries";

const EditOrderTypeComponent: any = Loadable({
  loader: () => import("../../components/OrderType/EditOrderType"),
  loading: DataLoadSpinner,
});

type Props = {};

const EditOrderType = (props: Props) => {
  const navigate = useNavigate();
  const params = useParams();

  const { offset, pageSize } = usePaginationState();

  const { data: singleDeliverMethod, loading: singleDeliveryMethodLoading } =
    useQuery(SINGLE_DELIVERY_METHOD, {
      variables: {
        id: params?.id,
      },
      fetchPolicy: "network-only",
    });

  const [editDeliveryMethod, { loading }] = useMutation(
    UPDATE_DELIVERY_METHODS,
    {
      refetchQueries: [
        {
          query: DELIVER_METHODS,
          variables: {
            first: pageSize,
            offset: offset,
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const handleEdit = async (values: any, props: any) => {
    const response = await editDeliveryMethod({
      variables: {
        deliveryMethodId: singleDeliverMethod?.deliveryMethod?.pk.toString(),
        title: values.title,
        description: values.description,
        amount: values.amount,
        displayInWebsite: values.displayInWebsite,
        isRedirectToCustomers: values.isRedirectToCustomers,
      },
    });
    if (response?.data?.updateDeliveryMethod?.success) {
      toast.success("Delivery Method updated  successfully");
      navigate("/order-type");
    } else {
      const errors = backendErrorDisplay(
        response?.data?.updateDeliveryMethod?.errors
      );
      toast.error(errors);
    }
  };

  return (
    <div>
      <EditOrderTypeComponent
        handleEdit={handleEdit}
        loading={loading}
        singleDeliveryMethodLoading={singleDeliveryMethodLoading}
        singleDeliverMethod={singleDeliverMethod}
      />
    </div>
  );
};

export default EditOrderType;
