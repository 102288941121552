import { useQuery } from "@apollo/client";

interface IAccessControlProps {
  allowedPermissions: any;
  children: any;
  renderNoAccess?: any;
}

const AccessControl = ({
  allowedPermissions,
  children,
  renderNoAccess,
}: IAccessControlProps) => {
  // const { data } = useQuery(PROFILE);
  // const permissionsLists: any =
  //   data && data?.me?.assignPermissions?.map((item: any) => item.codename);

  // let isAllowed: boolean = permissionsLists?.some((permission: any) =>
  //   allowedPermissions.includes(permission) || allowedPermissions.includes("no-permission-required"),
  // );
  // if (isAllowed) {
    // }
      return children;

  // return renderNoAccess ?? null;
};



export default AccessControl;
