import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { GET_SUPPLIER } from "../../queries";

const EditSupplierComponent: any = Loadable({
  loader: () => import("../../../components/Account/Supplier/EditSupplier"),
  loading: DataLoadSpinner,
});

type Props = {};

const EditSupplier = (props: Props) => {
  const params = useParams();

  const [selectedSupplierInitialValues, setSelectedSupplierInitailValues] =
    useState<any>();
  const { data: selectedSupplier } = useQuery(GET_SUPPLIER, {
    variables: {
      id: params.id,
    },
  });

  const [openAddressModal, setOpenAddressModal] = useState<boolean>(false);

  useEffect(() => {
    setSelectedSupplierInitailValues(null);
    if (selectedSupplier) {
      const newInitialValues = {
        firstName: selectedSupplier?.supplier?.user?.fullName,
        email: selectedSupplier?.supplier?.user?.email,
        mobileNumber: selectedSupplier?.supplier?.user?.mobileNumber,
        dob: selectedSupplier?.supplier?.user?.profile?.dob,
        fullAddress:
          selectedSupplier?.supplier?.user?.ptofile?.fullAddress
      };
      setSelectedSupplierInitailValues(newInitialValues);
    }
  }, [selectedSupplier, params.id]);


  const handleEditSupplier = (values: any) => {
    toast.success("Edited Successfully");
  };



  // const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE, {
  //   refetchQueries: [
  //     {
  //       query: GET_SUPPLIER,
  //       variables: {
  //         id: params.id,
  //       },
  //     },
  //   ],
  // });

  // const handleEditUserProfile = async (values: any, props: any) => {
  //   const response = await updateUserProfile({
  //     variables: {
  //       id: selectedSupplier?.supplier?.user?.profile?.pk,
  //       firstName: values?.firstName,
  //       middleName: values?.middleName,
  //       lastName: values?.lastName,
  //       dobEnglish: values?.dobEnglish,
  //       contactNumber: values?.contactNumber,
  //       email: values?.email,
  //     },
  //   });
  //   if (response?.data?.updateUserProfile?.success) {
  //     toast.success("Profile Updated Successfully");
  //   } else {
  //     let errors = backendErrorDisplay(
  //       response.data?.updateUserProfile?.errors,
  //     );
  //     props.setErrors(errors);
  //   }
  // };

  return (
    <div>
      <EditSupplierComponent
        handleEditSupplier={handleEditSupplier}
        selectedSupplierInitialValues={selectedSupplierInitialValues}
        addresses={selectedSupplier?.supplier?.user?.addresses}
        setOpenAddressModal={setOpenAddressModal}
        openAddressModal={openAddressModal}
        selectedSupplier={selectedSupplier}
        handleEditUserProfile={{}}
      />
    </div>
  );
};

export default EditSupplier;
