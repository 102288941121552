import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { searchItemsVar } from "../../../components/localstate/Cache";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { CREATE_RESTAURANT_TABLE } from "../../mutations";
import { QUERY_RESTAURANT_FLOOR, QUERY_RESTAURANT_TABLE } from "../../queries";

const AddRestaurantTableComponent: any = Loadable({
  loader: () =>
    import("../../../components/RestaurantTable/Table/AddRestaurantTable"),
  loading: DataLoadSpinner,
});

type Props = {};

const AddRestaurantTable = (props: Props) => {
  const navigate = useNavigate();

  const { pageSize, offset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [createRestuarant, { loading: createRestuarantLoading }] = useMutation(
    CREATE_RESTAURANT_TABLE,
    {
      refetchQueries: [
        {
          query: QUERY_RESTAURANT_TABLE,
          variables: {
            first: pageSize,
            offset: offset,
            title_Icontains: searchItems?.searchInput,
            subTable_Isnull: true,
            isVisible: true,
          },
        },
      ],
    }
  );

  //List of Floors
  const [floorTypeDropdown, setFloorTypeDropdown] = useState([]);

  const { data: restaurantFloorData } = useQuery(QUERY_RESTAURANT_FLOOR, {
    variables: {
      first: 100,
      offset: 0,
      title_Icontains: "",
    },
  });

  useEffect(() => {
    const dropdownFormat: any = [];
    restaurantFloorData?.floors?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.title,
      })
    );
    setFloorTypeDropdown(dropdownFormat);
  }, [restaurantFloorData]);

  const handleAddRestaurantTable = async (values: any, props: any) => {
    const response = await createRestuarant({
      variables: {
        title: values.title,
        capacity: values.capacity,
        floor: values.floor,
        note: values.note,
      },
    });
    if (response.data?.createRestaurantTable?.success) {
      props.resetForm();
      navigate("/restaurant-table");
      toast.success("Table Added Succesfully");
    } else {
      let errors = backendErrorDisplay(
        response.data?.createRestaurantTable?.errors
      );
      props.setErrors(errors);
    }
  };

  return (
    <div>
      <AddRestaurantTableComponent
        handleAddRestaurantTable={handleAddRestaurantTable}
        createRestuarantLoading={createRestuarantLoading}
        floorTypeDropdown={floorTypeDropdown}
      />
    </div>
  );
};

export default AddRestaurantTable;
