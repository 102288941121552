import { gql } from "@apollo/client";

const CREATE_RECEIPT = gql`
  mutation ($header: String, $footer: String,$isEnabledBarcode:String) {
    createUpdateReceiptSetting(input: { header: $header, footer: $footer,isEnabledBarcode:$isEnabledBarcode }) {
      errors
      success
      receiptSetting {
        pk
        id
        header
        footer
        isEnabledBarcode
      }
    }
  }
`;
export default CREATE_RECEIPT;
