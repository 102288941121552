import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import React from "react";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import { searchItemsVar } from "../../components/localstate/Cache";
import usePaginationState from "../../customhooks/usePaginationHook";
import { DELETE_SERVICE_CHARGE } from "../mutations";
import { QUERY_SERVICE_CHARGES } from "../queries";
import TableLoader from "../../components/reusable/Loader/TableLoader";

const ServiceChargeListComponent: any = Loadable({
  loader: () => import("../../components/ServiceCharge/ServiceChargeList"),
  loading: () => <TableLoader />,
});
const ServiceCharge: React.FC<any> = () => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const { data: serviceChargeData, loading: serviceChargeLoading } = useQuery(
    QUERY_SERVICE_CHARGES,
    {
      variables: {
        first: pageSize,
        offset: offset,
        title_Icontains: searchItems?.searchInput,
      },
      fetchPolicy: "network-only",
    }
  );

  const [deleteServiceCharge] = useMutation(DELETE_SERVICE_CHARGE, {
    refetchQueries: [
      {
        query: QUERY_SERVICE_CHARGES,
        variables: {
          first: pageSize,
          offset: offset,
          title_Icontains: searchItems?.searchInput,
        },
      },
    ],
  });

  const handleDeleteServiceCharge = async (id: string) => {
    const response = await deleteServiceCharge({
      variables: {
        objectId: id,
      },
    });
    if (response.data?.deleteServiceCharge?.success) {
      toast.success(response.data?.deleteServiceCharge?.message);
    } else {
      toast.error(
        response.data?.deleteServiceCharge?.errors?.nonFieldErrors[0]?.message
      );
    }
  };

  return (
    <>
      <ServiceChargeListComponent
        data={serviceChargeData?.serviceCharges?.edges}
        serviceChargeLoading={serviceChargeLoading}
        handleDeleteServiceCharge={handleDeleteServiceCharge}
        setPageSize={setPageSize}
        pageSize={pageSize}
        offset={offset}
        setOffset={setOffset}
      />
    </>
  );
};

export default ServiceCharge;
