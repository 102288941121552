import Barcode from 'react-barcode'

const BarcodeComponent = (props) => {
  const {barcodeObject, selectValue, value} = props;

  const handleBarCodeOptions = (value) => {
    if (selectValue === "mm") {
      return parseFloat(value) * 3.7795275591
    } else {
      return parseFloat(value) * 96
    }
  }
  return (
    <div>
      <div className="relative m-0 p-0">
        <div style={{
          width: "100%",
          position: "absolute",
          zIndex: 2,
          top: 0
        }}>
          <div dangerouslySetInnerHTML={{__html: barcodeObject?.headerContent}}/>
        </div>
        <Barcode
          value={value}
          width={handleBarCodeOptions(barcodeObject.barcodeImageWidth)}
          height={handleBarCodeOptions(barcodeObject.barcodeImageHeight)}
          marginLeft={handleBarCodeOptions(barcodeObject.labelPaddingLeft)}
          marginTop={handleBarCodeOptions(barcodeObject.labelPaddingTop)}
          marginBottom={handleBarCodeOptions(barcodeObject.labelPaddingBottom)}
          marginRight={handleBarCodeOptions(barcodeObject.labelPaddingRight)}
          format="CODE39"
        />

        <div style={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          zIndex: 2
        }}>
          <div dangerouslySetInnerHTML={{__html: barcodeObject?.footerContent}}/>
        </div>
      </div>
    </div>
  )
}

export default BarcodeComponent

