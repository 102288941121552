import { useMutation, useReactiveVar } from "@apollo/client";
import Loadable from "react-loadable";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { searchItemsVar } from "../../../components/localstate/Cache";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { CREATE_SUPPLIER } from "../../mutations";
import { QUERY_SUPPLIER } from "../../queries";

const CreateSupplierComponent: any = Loadable({
  loader: () => import("../../../components/Account/Supplier/CreateSupplier"),
  loading: DataLoadSpinner,
});

type Props = {};

const CreateSupplier = (props: Props) => {
  const navigate = useNavigate();
  const { pageSize, offset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [createSupplier, { loading: createSupplierLoading }] = useMutation(CREATE_SUPPLIER, {
    refetchQueries: [
      {
        query: QUERY_SUPPLIER,
        variables: {
          first: pageSize,
          offset: offset,
          search: searchItems?.searchInput,
        },
      },
    ],
  });

  

  const handleAddSupplier = async (values: any, props: any) => {
    const response = await createSupplier({
      variables: {
        fullName: values.fullName,
        email: values.email ?? null,
        mobileNumber: values.mobileNumber,
        fullAddress: values.fullAddress,
      },
    });
    if (response?.data?.createSupplier?.success) {
      toast.success(response?.data?.createSupplier?.message ?? "Supllier added sucessfully");

      props.resetForm();
      navigate("/supplier");
    } else {
      let errors = backendErrorDisplay(response.data?.createSupplier?.errors);
      props.setErrors(errors);
    }
  };

  return (
    
      <CreateSupplierComponent
        handleAddSupplier={handleAddSupplier}
        createSupplierLoading={createSupplierLoading}
      />
  
  );
};

export default CreateSupplier;
