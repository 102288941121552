//@ts-nocheck
import { Popover } from "@headlessui/react";
import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import routerObject from "../../router/router";
import classNames from "../../utils/classNames";

interface ISideBar {
  navigation?: any;
}

const SideBar: React.FC<ISideBar> = (props: ISideBar) => {
  const location = useLocation();
  return (
    <nav className="flex-1 px-2 space-y-1">
      <Popover.Group>
        {routerObject.map((item: any) =>
          item?.children?.length ? (
            <Popover className="relative" key={item?.name}>
              <Popover.Button
                className={classNames(
                  item.path === location.pathname
                    ? "bg-indigo-800 text-white"
                    : "text-indigo-100 hover:bg-indigo-600",
                  "group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full"
                )}
              >
                <img src={item?.icon} alt="" className="w-5 h-5 mr-2" />

                {item?.name}
              </Popover.Button>
              <Popover.Panel className="">
                {item?.children?.map((elem: any) => (
                  <NavLink
                    key={elem.name}
                    to={elem.path}
                    className={({ isActive, isPending }) => {
                      return `${
                        isActive ? "bg-indigo-800 " : ""
                      } group flex items-center px-2 py-2 pl-5 text-sm font-medium rounded-md text-white`;
                    }}
                  >
                    <img src={elem?.icon} alt="" className="w-5 h-5 mr-2" />
                    {elem?.name}
                  </NavLink>
                ))}
              </Popover.Panel>
            </Popover>
          ) : (
            <NavLink
              key={item.name}
              to={item.path}
              className={({ isActive, isPending }) => {
                return `${
                  isActive ? "bg-indigo-800 " : ""
                } group flex items-center px-2 py-2 text-sm font-medium rounded-md text-white`;
              }}
            >
              <img src={item?.icon} alt="" className="w-5 h-5 mr-2" />
              {item.name}
            </NavLink>
          )
        )}
      </Popover.Group>
    </nav>
  );
};

export default SideBar;
