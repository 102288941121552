import bgPosImage from '../../assets/icon/bgPos1.jpg'


const UserSupport = () => {

  return (
    <div className="w-full h-full absolute top-0 left-0 right-0 bottom-0">
      <img className="w-full h-full object-fit"
        src={bgPosImage}
        alt="" />
    </div>
  )
}

export default UserSupport
