import { useQuery } from "@apollo/client";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationHook from "../../customhooks/usePaginationHook";

import { POS_KITCHEN_ORDER_LIST } from "../Orders/queries";
import CustomHeaderLayout from "../../layouts/CustomHeaderLayout";

const RecallKitchenDisplayComponent: any = Loadable({
  loader: () => import("../../components/KitchenDisplay/RecallKitchenDisplayList"),
  loading: DataLoadSpinner,
});

const RecallKitchenDisplay = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();

  const { loading, data: kitchenOrderData } = useQuery(
    POS_KITCHEN_ORDER_LIST,

    {
      fetchPolicy: "no-cache",
      variables: {
        orderStatus: "pending",
        isBumped: true,
        first: pageSize,
        offset: offset,
      },
    },
  );

  return (
    <>
      <CustomHeaderLayout layoutType={"kitchenDisplay"} />
      {loading ? <DataLoadSpinner /> :
        <div className="relative h-[90vh] w-full p-8">
          <RecallKitchenDisplayComponent
            data={kitchenOrderData?.kitchenOrders?.edges ?? []}
            pageSize={pageSize}
            setPageSize={setPageSize}
            offset={offset}
            setOffset={setOffset}
            pageInfo={kitchenOrderData?.kitchenOrders?.pageInfo}
          />
        </div>
      }
    </>
  );
};

export default RecallKitchenDisplay;
