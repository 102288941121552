import moment from "moment";
import { useState } from "react";

const useCommonState = () => {
  const [note, setNote] = useState();
  let currentDate = new Date();
  let fromDate = new Date();
  fromDate.setDate(currentDate.getDate() - 7);
  const [onBasisOrderHistroy, setOnBasisOrderHistory] = useState<number>(0);
  const [onBasisSaleHistory, setOnBasisSaleHistory] = useState<number>(0);
  const [onBasisExpensesHistory, setonBasisExpensesHistory] =
    useState<number>(0);

  const [toDateSaleHistory, setToDateSaleHistory] = useState<any>(currentDate);
  const [fromDateSaleHistory, setFromDateSaleHistory] = useState<any>(fromDate);

  const [toDateOrderHistroy, setToDateOrderHistroy] =
    useState<any>(currentDate);
  const [fromDateOrderHistroy, setFromDateOrderHistroy] =
    useState<any>(fromDate);

  const [toDateExpensesHistory, setToDateExpensesHistory] =
    useState<any>(currentDate);
  const [fromDateExpensesHistory, setFromDateExpensesHistory] =
    useState<any>(fromDate);

  const [transactionEndDate, setTransactionEndDate] =
    useState<any>(currentDate);
  const [transactionStartDate, setTransactionStartDate] =
    useState<any>(fromDate);

  return {
    note,
    setNote,

    toDateOrderHistroy,
    setToDateOrderHistroy,
    fromDateOrderHistroy,
    setFromDateOrderHistroy,
    toDateSaleHistory,
    setToDateSaleHistory,
    fromDateSaleHistory,
    setFromDateSaleHistory,
    toDateExpensesHistory,
    setToDateExpensesHistory,
    fromDateExpensesHistory,
    setFromDateExpensesHistory,

    onBasisSaleHistory,
    setOnBasisSaleHistory,
    onBasisOrderHistroy,
    setOnBasisOrderHistory,
    onBasisExpensesHistory,
    setonBasisExpensesHistory,

    transactionStartDate,
    setTransactionStartDate,
    transactionEndDate,
    setTransactionEndDate,
  };
};
export default useCommonState;
