//@ts-nocheck
import { useQuery, useReactiveVar } from "@apollo/client";
import Loadable from "react-loadable";
import { searchItemsVar } from "../../../components/localstate/Cache";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import { CUSTOMER_LIST } from "./grapql";
import AccessControl from "../../../helpers/accessControl";
import UserPermissionRequired from "../../Error/UserPermisssionRequired";
import TableLoader from "../../../components/reusable/Loader/TableLoader";

const CustomerListComponent: any = Loadable({
  loader: () => import("../../../components/Account/Customer/CustomerList"),
  loading: () => <TableLoader />,
});

const CustomerList = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const { data, loading: loadingCustomer } = useQuery(CUSTOMER_LIST, {
    variables: {
      first: pageSize,
      offset: offset,
      search: searchItems?.searchInput,
    },
    fetchPolicy: "network-only",
  });

  const customerEdit = (id: any) => {};

  return (
    <>
      <AccessControl
        allowedPermissions={["can_view_customer"]}
        renderNoAccess={<UserPermissionRequired />}
        children={
          <CustomerListComponent
            data={data?.customers}
            customerEdit={customerEdit}
            loadingCustomer={loadingCustomer}
            pageSize={pageSize}
            setPageSize={setPageSize}
            offset={offset}
            setOffset={setOffset}
          />
        }
      />
    </>
  );
};

export default CustomerList;
