import React from "react";
import useOpenScreenKeyboard from "../../../../customhooks/useOpenScreenKeyboard";
import randomString from "../../../../utils/randomString";
import { IHTMLFieldProps } from "../interface";

const TextField: React.FC<IHTMLFieldProps> = (props) => {
  const {
    name,
    label,
    values,
    touched,
    errors,
    setFieldValue,
    handleChange = null,
    helperText,
    disabled = false,
    placeHolder,
    onBlur,
  } = props;
  const { onScreenKeyboard } = useOpenScreenKeyboard();

  const id = randomString(10, "-text-field");
  const handleOnChange = (value: string) => {
    setFieldValue(name, value);
    handleChange && handleChange(value, setFieldValue);
  };
  return (
    <div className="relative rounded">
      <label
        htmlFor={id}
        className="font-medium"
      >
        {label}
      </label>
      <input
        id={id}
        type="text"
        name={name}
        className="relative peer mt-2 p-2 rounded w-full text-gray-700 focus: outline-none border border-gray-300 focus:border-primary transition duration-500"
        placeholder={placeHolder ?? " "}
        value={values[name] ?? ""}
        disabled={disabled}
        onChange={(e: any) => handleOnChange(e.target.value)}
        onClick={() => onScreenKeyboard()}
        onBlur={onBlur}
        autoComplete="off"
      />
      {Boolean(touched[name] && errors[name]) && (
        <p className="text-sm text-red-500">{errors[name]}</p>
      )}

      <p className="text-xs text-blue-500">{helperText}</p>
    </div>
  );
};

export default TextField;
