import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type Props = {};

const SalesLoader = (props: Props) => {
  const {} = props;

  return (
    <div className="relative rounded bg-white shadow pl-0 p-6 my-7">
      <div className="flex justify-between h-12">
        <div className="ml-4 mb-4">
          <Skeleton height={30} width={100} />
        </div>
        <div className="flex justify-between gap-4">
          <Skeleton height={30} width={100} />
          <Skeleton height={30} width={100} />
        </div>
      </div>
      <div className="grid grid-cols-5 gap-10" style={{ width: "100%" }}>
        <div className="h-96 col-span-3 pl-8 " style={{ width: "100%" }}>
          <Skeleton className="w-full h-full" />
        </div>
        <div className="w-full col-span-2 items-center mt-20 mr-20">
          <div className="mt-4">
            <Skeleton height={90} width={250} />
          </div>
          <div className="mt-4">
            <Skeleton height={90} width={250} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesLoader;
