import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { toast } from 'react-toastify';
import DataLoadSpinner from '../../components/reusable/Spinner/DataLoadSpinner';
import { EMPLOYEE_WORKING_HOUR_REPORT, QUERY_USERS } from '../queries';
import { QUERY_SETTINGS } from '../Settings/graphql';

const EmployeeHourComponent: any = Loadable({
  loader: () => import('../../components/Report/EmployeeHour'),
  loading: DataLoadSpinner,
});

type Props = {}

const EmployeeHour = (props: Props) => {
  const [usersDropDown, setusersDropDown] = useState<any>([]);

  const [reportOpenState, setReportOpenState] = useState<boolean>(false)
  const [reportData, setReportData] = useState<any>()

  const { data: settingsData } = useQuery(QUERY_SETTINGS)

  // USERS DROPDOWN LIST
  const { data: userData } = useQuery(QUERY_USERS)
  useEffect(() => {
    const dropdownFormat: any = [];
    userData?.users?.edges?.map((elem: any) => (
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.username,
      })
    ));
    setusersDropDown(dropdownFormat);
  }, [userData]);


  const [generateEmployeeWorkingReport, { loading: employeeWorkingReportLoading }] = useLazyQuery(EMPLOYEE_WORKING_HOUR_REPORT)

  const handleGenerateEmployeeReport = async (values: any) => {
    const response = await generateEmployeeWorkingReport({
      variables: {
        user: values.user,
        fromDateTime: values.fromDate,
        toDateTime: values.toDate,
      }
    })
    if (response?.data?.employeeWorkingHourReport?.createdOn) {
      setReportData(response?.data?.employeeWorkingHourReport)
      setReportOpenState(true)
    } else {
      toast.error("Report data available")
    }
  }

  const handleReset = (setFieldValue: any) => {
    setFieldValue('user', [])
    setFieldValue('fromDate', "")
    setFieldValue('toDate', "")
  }

  return (
    <EmployeeHourComponent
      usersDropDown={usersDropDown}
      reportOpenState={reportOpenState}
      setReportOpenState={setReportOpenState}
      reportData={reportData}
      currency={settingsData?.basicWebsiteConfigurations?.currency}
      employeeWorkingReportLoading={employeeWorkingReportLoading}
      handleGenerateEmployeeReport={handleGenerateEmployeeReport}
      handleReset={handleReset}
      settingsData={settingsData}
    />
  )
}

export default EmployeeHour