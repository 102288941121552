import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import Loadable from "react-loadable";
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { searchItemsVar } from '../../components/localstate/Cache';
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from '../../customhooks/usePaginationHook';
import { backendErrorDisplay } from '../../utils/backendErrorDisplay';
import { CREATE_TERMINAL } from '../mutations';
import { QUERY_PRINTER, QUERY_TERMINAL } from '../queries';

const AddTerminalComponent: any = Loadable({
  loader: () => import('../../components/Terminal/AddTerminal'),
  loading: DataLoadSpinner,
});

type Props = {}

const AddTerminal = (props: Props) => {
  const navigate = useNavigate()

  const { pageSize, offset } = usePaginationState()
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [createTerminal, { loading: createTerminalLoading }] = useMutation(CREATE_TERMINAL, {
    refetchQueries: [{
      query: QUERY_TERMINAL, variables: {
        first: pageSize,
        offset: offset,
        name_Icontains: searchItems?.searchInput
      }
    }]
  })

  //PrinterList
  const [printerTypeDropdown, setPrinterTypeDropdown] = useState([]);

  const { data: printerData } = useQuery(QUERY_PRINTER, {
    variables: {
      first: 100,
      offset: 0,
      name_Icontains: ""
    }
  })
 
   useEffect(() => {
     const dropdownFormat: any = [];
     printerData?.printers?.edges?.map((elem: any) => (
       dropdownFormat.push({
         id: elem?.node?.pk,
         title: elem?.node?.name,
       })
     ));
     setPrinterTypeDropdown(dropdownFormat);
   }, [printerData]);


  const handleAddTerminal = async (values: any, props: any) => {
    const response = await createTerminal({
      variables: {
        name: values.name,
        printer: values.printer,
        note: values.note
      }
    })
    if (response.data?.createTerminal?.success) {
      props.resetForm()
      navigate('/terminal')
      toast.success("Terminal Added Succesfully")
    }
    else {
      let errors = backendErrorDisplay(response.data?.createTerminal?.errors)
      props.setErrors(errors)
    }
  }

  return (
    <div>
      <AddTerminalComponent
        handleAddTerminal={handleAddTerminal}
        createTerminalLoading={createTerminalLoading}
        printerTypeDropdown={printerTypeDropdown}
      />
    </div>
  )
}

export default AddTerminal