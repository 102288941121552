import { useMutation } from "@apollo/client";
import { useRef, useState } from "react";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import { DOWNLOAD_HOSTNAME } from "../../apolloClient";
import DataLoadSpinner from "../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../customhooks/usePaginationHook";
import { IMPORT_PRODUCT } from "../mutations";
import { COMMIT_IMPORT_PRODUCT } from "./mutation";

const BulkImportProductComponent: any = Loadable({
  loader: () => import("../../components/Product/BulkImportProduct"),
  loading: DataLoadSpinner,
});

type Props = {};

const BulkImportProduct = (props: Props) => {


  const fileInputRef: any = useRef();
  const [validProductsList, setValidProductsList] = useState<any>([]);
  const [invalidProductsList, setinvalidProductsList] = useState<any>([]);

  const [commitButton, setCommitButton] = useState<boolean>(false);
  const { pageSize, setPageSize } = usePaginationState();

  const [commitImportProduct, { loading: commitLoading }] = useMutation(
    COMMIT_IMPORT_PRODUCT,
  );

  const downloadSample = async () => {
    await fetch(`${DOWNLOAD_HOSTNAME}product-import-sample/`)
      .then((res: any) => {
        return res.blob();
      })
      .then((data) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = "ProductSample" + ".xlsx";
        a.click();
      });
  };

  const [uploadProduct, { loading: uploadLoading }] =
    useMutation(IMPORT_PRODUCT);

  const handleSubmit = async (e: any) => {
    setinvalidProductsList([]);
    setValidProductsList([]);
    const response = await uploadProduct({
      variables: {
        excelFile: e.target.files[0],
      },
    });
    if (response?.data?.importProduct?.success) {
      toast.success(response?.data?.importProduct?.message);
      if (response?.data?.importProduct?.invalidProducts.length === 0) {
        setCommitButton(true);
      } else {
        setinvalidProductsList(response?.data?.importProduct?.invalidProducts);
        setCommitButton(false);
      }
      response?.data?.importProduct?.validProducts?.map((elem: any) =>
        setValidProductsList((validProductsList: any) => [
          ...validProductsList,
          {
            category: elem.category,
            costPrice: elem.costPrice,
            excelLineNumber: elem.excelLineNumber,
            productName: elem.productName,
            productType: elem.productType,
            sellingPrice: elem.sellingPrice,
            stockQuantity: elem.stockQuantity,
            unit: elem.unit,
            barcode: elem.barcode,
            plu: elem.plu,
            tax:elem.tax
          },
        ]),
      );
      fileInputRef.current.value = "";
    } else {
      toast.error(response?.data?.importProduct?.message);
    }
  };

  const downloadInvalidProduct = async () => {
    // CONVERT TO EXCEL FORMAT

    const excelFormat = invalidProductsList?.map((elem: any) => ({
      product_name: elem.productName,
      category: elem.category,
      cost_price: elem.costPrice,
      product_type: elem.productType,
      selling_price: elem.sellingPrice,
      stock_quantity: elem.stockQuantity,
      unit: elem.unit,
      barcode: elem.barcode,
      plu: elem.plu,
    }));

    await fetch(`${DOWNLOAD_HOSTNAME}invalid-products/`, {
      method: "POST",
      body: JSON.stringify(excelFormat),
    })
      .then((res: any) => {
        return res.blob();
      })
      .then((data) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = "InvalidProducts.xlsx";
        a.click();
      });
  };

  const handleCommit = async () => {
    const response = await commitImportProduct({
      variables: {
        products: validProductsList,
      },
    });
    if (response?.data?.commitImportProduct?.success) {
      toast.success(response?.data?.commitImportProduct?.message);
      setValidProductsList([]);
      setCommitButton(false);
    } else {
      toast.success(response?.data?.commitImportProduct?.errors?.message);
    }
  };

  return (
    <div>
      <BulkImportProductComponent
        downloadSample={downloadSample}
        fileInputRef={fileInputRef}
        handleSubmit={handleSubmit}
        invalidProductsList={invalidProductsList}
        validProductsList={validProductsList}
        downloadInvalidProduct={downloadInvalidProduct}
        handleCommit={handleCommit}
        commitButton={commitButton}
        setPageSize={setPageSize}
        pageSize={pageSize}
        uploadLoading={uploadLoading}
        commitLoading={commitLoading}
      />
    </div>
  );
};

export default BulkImportProduct;
