import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import React, { useState } from "react";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { MAKE_SUPPLIER_PAYMENT } from "../../mutations";
import { QUERY_SUPPLIER } from "../../queries";
import { searchItemsVar } from "../../../components/localstate/Cache";
import TableLoader from "../../../components/reusable/Loader/TableLoader";

const SupplierListComponent: any = Loadable({
  loader: () => import("../../../components/Account/Supplier/SupplierList"),
  loading: () => <TableLoader />,
});

type Props = {};

const SupplierList = (props: Props) => {
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const [makeSupplierPayment] = useMutation(MAKE_SUPPLIER_PAYMENT, {
    refetchQueries: [
      {
        query: QUERY_SUPPLIER,
        variables: {
          first: pageSize,
          offset: offset,
        },
      },
    ],
  });

  const { data: supplierData, loading } = useQuery(QUERY_SUPPLIER, {
    variables: {
      first: pageSize,
      offset: offset,
      search: searchItems?.searchInput,
    },
  });

  // SUPPLIER DROPDOWN FORMAT
  const supplierList: any = [];
  supplierData?.suppliers?.edges?.map((elem: any) =>
    supplierList.push({
      id: elem?.node?.pk,
      title: elem?.node?.user?.profile?.firstName,
    })
  );

  const handlePayment = async (values: any, props: any) => {
    const response = await makeSupplierPayment({
      variables: {
        supplier: values.supplier,
        paymentMethod: values.paymentMethod,
        amount: values.amount,
        note: values.note,
      },
    });
    if (response?.data?.makePayment?.success) {
      setOpenPaymentModal(false);
      toast.success(response?.data?.makePayment?.message);
    } else {
      let errors = backendErrorDisplay(response.data?.makePayment?.errors);
      props.setErrors(errors);
    }
  };

  return (
    <div>
      <SupplierListComponent
        data={supplierData?.suppliers}
        openPaymentModal={openPaymentModal}
        setOpenPaymentModal={setOpenPaymentModal}
        handlePayment={handlePayment}
        loading={loading}
        supplierList={supplierList}
        setPageSize={setPageSize}
        pageSize={pageSize}
        setOffset={setOffset}
        offset={offset}
      />
    </div>
  );
};

export default SupplierList;
