import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useEffect } from 'react'
import { ACCOUNT_TRANSACTION, POS_SCREEN_ORDERED_ITEMS, QUERY_TRANSACTIONS_VIEW } from '../../queries';
import Loadable from "react-loadable";
import DataLoadSpinner from '../../../components/reusable/Spinner/DataLoadSpinner';
import { useParams } from 'react-router';
import usePaginationHook from '../../../customhooks/usePaginationHook';

const DiscountExpensesReportComponent: any = Loadable({
  loader: () =>
    import("../../../components/ExpensesReport/DiscountExpenses/ViewDiscountExpenses"),
  loading: DataLoadSpinner,
});

const ViewDiscountExpenses = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();
  const { id } = useParams()
  const { data: orderItemsData } = useQuery(POS_SCREEN_ORDERED_ITEMS, {
    variables: {
      first: pageSize,
      offset: offset,
      id: id
    },
  });
  return (
    <div>
      <DiscountExpensesReportComponent
        orderItemsData={orderItemsData}
        setPageSize={setPageSize}
        pageSize={pageSize}
        setOffset={setOffset}
        offset={offset}
      /></div>
  )
}

export default ViewDiscountExpenses