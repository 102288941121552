import moment from "moment/moment";

const websiteOrderNetworkPrinter = (websiteOrderMessageData: any, receiptData: any) => {
  let item = websiteOrderMessageData?.data?.items?.map(
    (item: any, index: number) =>
      `<tr key=${index}>
            <td width="0.10">${index + 1}</td>
            <td width="0.45">${item?.title}</td>
            <td width="0.10">${item?.quantity}</td>
            <td width="0.15">${item?.price}</td>
            <td width="0.15">${item?.total_amount}</td>
          </tr>
          ${item?.modifiers ? item?.modifiers?.map(
        (modItem: any, index: number) =>
          ` <tr key=${index}>
            <td width="0.10"></td>
            <td width="0.45">--${modItem?.title}</td>
            <td width="0.10">${modItem?.quantity}</td>
            <td width="0.15">${modItem?.price}</td>
            <td width="0.15">${modItem?.total_amount}</td>
          </tr>`
      ).join(" ") : " "} 
      <br />
          <beep/>
          `
  );

  let body = `
        <left><p>Invoice No: ${websiteOrderMessageData?.data?.order_id ?? ""}</p></left>
        <right>
        <p>Date: ${moment().format("YYYY-MM-DD")}</p>
        <p>Time: ${moment().format("h:mm:ss a")}</p>
        </right>
              </div>
              <hr />
              <table>
              <tr>
              <td width="0.10" bold="true">S.N</td>
              <td width="0.45" colspan="2" bold="true">NAME</td>
              <td width="0.10" bold="true">QTY</td>
              <td width="0.15" bold="true">Rate</td>
              <td width="0.15" bold="true">Amount</td>
              </tr>
              <hr />
              <tbody>
              ${item?.join(" ")}
              </tbody>
             
            </table>
            <hr />`
    ;
  let calculation = `
              <right>
              <p>Sub Total: ${websiteOrderMessageData?.data?.total_amount} </p>
              <p>Discount: ${websiteOrderMessageData?.data?.discount ?? "0.00"}</p>
              <p>GST: ${websiteOrderMessageData?.data?.gst_amount ?? "0.00"}</p>
              <p>Delivery Charge: ${websiteOrderMessageData?.data?.delivery_charge}</p>
              <p>Grand Total: ${websiteOrderMessageData?.data?.total_amount}</p>
             </right>
             <left>
             <p>Note: ${websiteOrderMessageData?.data?.note}</p>
             </left>
             <hr/>
              <left>
              <p style="font-weight:bold;">Shipping Address</p>
              <p>Full Name: ${websiteOrderMessageData?.data?.full_name ?? ""} </p>
              <p>Email: ${websiteOrderMessageData?.data?.email ?? ""} </p>
              <p>Mobile: ${websiteOrderMessageData?.data?.mobile_number ?? ""}</p>
              <p>Address: ${websiteOrderMessageData?.data?.address ?? ""} </p>
              <p>Payment Type: ${websiteOrderMessageData?.data?.payment_type ?? ""}</p>
              <p>Delivery Type: ${websiteOrderMessageData?.data?.delivery_type ?? "None"}</p>
              </left>              
              <hr/>
          `;
  let title = `<doubleheight>
              <div>
                <p bold="true">Website Order</p>
              </div>
            </doubleheight>`

  return `<center>${receiptData?.receiptSetting?.header ?? ""}</center> <br />  ${title} <br /> ${body} ${calculation} <center>${receiptData?.receiptSetting?.footer ?? " "}</center>`;
};

export default websiteOrderNetworkPrinter;